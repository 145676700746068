import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { createRef } from "react";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { checkLocationPermission } from "../../../components/src/utils"
import firebase from 'firebase'
import 'firebase/auth';
import 'firebase/firestore';


import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
import jwt_decode from "jwt-decode";
declare global {
  interface Window {
    google: any; // or you can declare a more specific type
  }
}

export interface MockForGoogleLoginObject {
  family_name: string,
  azp: string,
  client_id: string,
  aud: string,
  sub: string,
  hd: string,
  email_verified: boolean,
  nbf: number,
  name: string,
  iss: string,
  given_name: string,
  email: string,
  iat: number,
  exp: number,
  jti: string,
  credential: string,
  clientId: string,
  picture: string,
}

interface DecodedToken {
  sub: string;
  // Add other fields here if needed
  email?: string;
  exp?: number;
  // etc.
}

interface AppleLoginResponse {
  error?: any;
  authorization: {
    state: string;
    code: string;
    id_token: string;
    sub: string;
  };
  user: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  fullName: any;
  userName: any
  fullNameError: any;
  email: any;
  emailError: any;
  password: string;
  pwdError: any;
  otpAuthToken: string;
  reTypePassword: string;
  reTypepasswordError: any;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: any;
  phoneError: any;
  showPassword: boolean;
  copyPassword: any;
  showPassword1: boolean;
  copyPassword1: any;
  isSelect: boolean;
  isChecked: boolean;
  selectedValue: any;
  showAlert: any;
  checkedError: any;
  alertError: any;
  otpError: any;
  valueError: any;
  successMessage: any;
  activeStep: any;
  bankAccountNumber: string;
  AccountHolderName: any;
  bankName: any;
  ifscCode: string;
  bankAccountNumberError: any;
  AccountHolderNameError: any;
  bankNameError: any;
  ifscCodeError: any;
  selectedDelivery: string;
  addressOne: string;
  addressTwo: string;
  pinCode: string;
  city: string;
  state: string;
  country: string;
  progress: any;
  gst_pan: any;
  email_business: any;
  business_Licence: any;
  gst_panError: any;
  business_LicenceError: any;

  addressOneError: string;
  pinCodeError: string;
  cityError: string;
  stateError: string;
  countryError: string;

  value: string;
  showMobileField: any;
  inputValue: string;
  selectedItem: string;
  dataSource: any;
  visibiltyOfPassword: any;
  username: any;
  isFocused: boolean;
  selectedUsername: any;
  token: any;
  otpState: any;
  time: any;
  interval: any;
  isTimerRunning: boolean;
  complete: boolean;
  intervalId: null;
  timerID: null;
  accountType: any;
  label: any;
  signupButtonText: any
  error: any
  selectBusiness: string;
  sellerSignupCheck: boolean,
  countryCode: string;
  phoneNumber: string;
  business_Name: string;
  emailSellerSignup: string;
  signupPassword: string;
  singupConfrimPassword: string;
  emyErrorMessage: string
  businessNameError: string;
  creatorCategory: string;
  yourNameError: string;
  userNameError: string;
  BusinessTypeError: string;
  mobileNumberError: string;
  emailIdError: string;
  passwordError: string;
  confirmPasswordError: string
  confirmPassword: string;
  businessTyepSelect: any;
  businessTypeArray: any;
  sellerMobileOtpVerify: any;
  sellerOtpEmailVerify: any;
  isSucessMobileVerify: boolean;
  isSucessEmailVerify: boolean;
  sellerOtpMobileValidation: any;
  sellerOtpEmailValidation: any;
  successMessages: any;
  successMessagesEmail: any
  successMessageEmail: string;
  otpMinute: any;
  otpSecond: any;
  setName: any;
  firstSpaceEntered: boolean;
  resendEmailValidation: boolean
  resendMoibileError: any;
  resendMoibleSuccessMessage: any;
  resendMobileValidation: boolean;
  resendEmailError: any;
  resendEmailSuccessMessage: any;
  uploadFile: any;
  uploadFiles: any;
  isUploaded: any;
  uploadError: any;
  businessCategories: any;
  businessSubCategories: any;
  selectCategoryError: any;
  businessCategoriesId: any;
  businessSubCategoriesId: any;
  selectSubCategoryError: any;
  isFile: any;
  sellerTermsConditionData: any,
  sellerModalOpen: boolean,
  termsConditionPrivacyPolicyData: any,
  googleSignup: boolean,
  googleSignUpError: boolean,
  errorMessageGoogleSignUp: string;
  selectGoogleSignupUser: any;
  resultState: any;
  bankDetailsFilled: boolean;
  addressDetailsFilled: boolean;
  businessDetailsFilled: boolean;
  otpModal: boolean;
  isLoading: boolean;
  selectedSubCategories:any;
  selectedCategories:any;
  storeType:string
  checkedStoreTypeError:boolean;
  slectText:boolean;
  slectTextSub:boolean;
  lat: number,
  lng: number,
  errorMessageLocation: string,
  permissionStatus: string,
  upiId: string;
  upiIdError: string;
  bankAccountType: string;
  bankAccountTypeError: string;
  gender: string;
  genderError: string;
  creatorCategoryError: string;
  isGstNumber: string;
  companyType:  string;
  companyTypeError: string;
  userType: string | null;
  dateOfBirth: string;
  dateOfBirthError: string;
  instagramUrlError: string;
  youtubeLink: string;
  youtubeLinkError: string;
  instagramUrl: string;
  creatorCreated: boolean;
  otpSuccessDialog: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  countryCodeApiCallId: any;
  signUpId: any
  availableUsernameID: any;
  categoryID: any;
  subCategoryID: any;
  getSellerProfileDetailsApiCallId: any;
  bankDetailsID: any;
  addressId: any;
  handleBusinessDetailsApiCallId: any
  mobileOTPID: any;
  emailOTPID: any;
  mobileresSedOTPID: any;
  emaillResendOTPID: any;
  ref = createRef();
  intervalId: number = 0
  timerID: any;
  apiSellerSignUp: any;
  apiSellerMobileOtpVerify: any
  apiSellerEmailOtpVerify: any
  apiEditMoibleEmail: any
  apiEditEmail: any
  timer: any;
  apiSellerTermscondition: any;
  apiSellerPrivacyPolicy: any;
  apiBuyerTermsAndCondtion: any;
  apiBuyerPrivacyPolicy: any;
  signUpGoogleCallId: any;
  googleLoginApiCallId: string = "";
  appleLoginApiCallId: string = "";
  creatorInfoApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      slectText:true,
      slectTextSub:true,
      firstName: "",
      lastName: "",
      fullName: "",
      fullNameError: "",
      email: "",
      emailError: "",
      password: "",
      pwdError: "",
      reTypePassword: "",
      reTypepasswordError: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      phoneError: "",
      showPassword: false,
      copyPassword: "",
      showPassword1: false,
      copyPassword1: "",
      isSelect: false,
      isChecked: false,
      selectedValue: {},
      showAlert: false,
      checkedError: false,
      alertError: [],
      otpError: "",
      valueError: false,
      successMessage: false,
      activeStep: 0,
      bankAccountNumber: "",
      AccountHolderName: "",
      bankName: "",
      ifscCode: "",
      bankAccountNumberError: "",
      AccountHolderNameError: "",
      bankNameError: "",
      ifscCodeError: "",
      selectedDelivery: "self_ship",
      addressOne: "",
      addressTwo: "",
      pinCode: "",
      city: "",
      state: "",
      country: "",
      addressOneError: "",
      pinCodeError: "",
      cityError: "",
      stateError: "",
      countryError: "",
      progress: 0,
      gst_pan: "",
      email_business: "",
      business_Licence: "",
      gst_panError: "",
      business_LicenceError: "",
      otpModal: false,

      value: "",
      showMobileField: false,
      inputValue: "",
      selectedItem: "",
      dataSource: [],
      visibiltyOfPassword: "",
      username: [],
      isFocused: false,
      selectedUsername: "",
      token: "",
      otpState: "",
      accountType: "",
      time: 30,
      interval: null,
      isTimerRunning: false,
      complete: false,
      intervalId: null,
      timerID: null,
      label: "Email or Mobile*",
      signupButtonText: "Signup",
      error: {},
      selectBusiness: "",
      sellerSignupCheck: false,
      countryCode: 'us',
      phoneNumber: '',
      business_Name: '',
      userName: '',
      emailSellerSignup: '',
      signupPassword: '',
      singupConfrimPassword: '',
      emyErrorMessage: '',
      businessNameError: "",
      creatorCategory: "",
      creatorCategoryError: "",
      yourNameError: "",
      userNameError: "",
      BusinessTypeError: "",
      mobileNumberError: "",
      emailIdError: "",
      passwordError: "",
      confirmPasswordError: "",
      confirmPassword: "",
      businessTyepSelect: '',
      businessTypeArray: [],
      sellerMobileOtpVerify: '',
      sellerOtpEmailVerify: '',
      isSucessMobileVerify: false,
      isSucessEmailVerify: false,
      sellerOtpMobileValidation: '',
      sellerOtpEmailValidation: '',
      successMessages: localStorage.getItem('successMessages'),
      successMessagesEmail: localStorage.getItem('successMessagesEmail'),
      successMessageEmail: '',
      otpMinute: 1,
      otpSecond: 59,
      setName: '',
      firstSpaceEntered: false,
      resendEmailValidation: false,
      resendMoibileError: '',
      resendMoibleSuccessMessage: '',
      resendMobileValidation: false,
      resendEmailError: '',
      resendEmailSuccessMessage: '',
      uploadFile: null,
      uploadFiles: [],
      isUploaded: false,
      uploadError: "",
      businessCategories: null,
      businessSubCategories: [],
      selectCategoryError: "",
      businessCategoriesId: null,
      businessSubCategoriesId: null,
      selectSubCategoryError: "",
      isFile: null,
      sellerTermsConditionData: "",
      sellerModalOpen: false,
      termsConditionPrivacyPolicyData: {
        heading: "",
        description: "",
        effectiveDate: "",
      },
      googleSignup: false,
      googleSignUpError: false,
      resultState: {
        credential: {},
        user: {},
        additionalUserInfo: {},
      },
      errorMessageGoogleSignUp: '',
      selectGoogleSignupUser: '',

      bankDetailsFilled: false,
      addressDetailsFilled: false,
      businessDetailsFilled: false,
      isLoading: false,
      selectedSubCategories: [], 
      selectedCategories:[],
      storeType:"",
      checkedStoreTypeError:false,
      lat: 0,
      lng: 0,
      errorMessageLocation: '',
      permissionStatus: 'Prompting',
      upiId: "",
      upiIdError: "",
      bankAccountType: "",
      bankAccountTypeError: "",
      gender: "",
      genderError: "",
      isGstNumber: "",
      companyType: "",
      userType: "",
      dateOfBirth: "",
      instagramUrlError: "",
      instagramUrl: "",
      youtubeLinkError: "",
      youtubeLink : "",
      dateOfBirthError: "",
      creatorCreated: false,
      companyTypeError: "",
      otpSuccessDialog: false
      // Customizable Area End
    };

    // Customizable Area Start: '
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.imgPasswordVisible = imgPasswordVisible;
    this.arrayholder = [];
    this.labelFirstName = configJSON.labelFirstName;
    this.labelHeader = configJSON.labelHeader;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.lastName = configJSON.lastName;
    this.labelPassword = configJSON.labelPassword;
    this.labelEmail = configJSON.labelEmail;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleAPIResponse(message);
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let navigationPayload = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      this.handleMoreApiResponse(message);
            switch (apiRequestCallId) {
        case this.apiSellerSignUp:
          this.handleReceive(responseJson)
          break;
        case this.signUpGoogleCallId:
          this.handleGoogleSignupResponse(responseJson)
          break;
        case this.apiSellerMobileOtpVerify:
          this.sellerOtpSuccessMessageMobile(responseJson)
          break;
        case this.mobileresSedOTPID:
          this.handleResendMobileValidate(responseJson)
          break;
        case this.apiSellerEmailOtpVerify:
          this.sellerOtpSuccessMessageEmail(responseJson)
          break;
        case this.emaillResendOTPID:
          this.handleResendEmailValidate(responseJson)
          break;
        case this.apiEditMoibleEmail:
          this.editSuccessMessageMobile(responseJson)

          break;
        case this.apiEditMoibleEmail:
          this.EmailVerifySuccessMessage(responseJson)
          break;
        case this.apiEditEmail:
          this.editSuccessMessageEmail(responseJson)
          break;
        case this.countryCodeApiCallId:
          this.setState({
            dataSource: this.countryCodesToDropDown(responseJson.data),
            selectedValue: this.countryCodesSelected(responseJson.data)[0]
          });
          break;
        case this.signUpId:
          this.handleSignUpResponse(responseJson, errorResponse);
          break;
        case this.mobileOTPID:
        case this.emailOTPID:
          this.handleNavigation(responseJson, errorResponse);
          break;
        case this.mobileresSedOTPID:
        case this.emaillResendOTPID:
          this.handleNavigation1(responseJson, errorResponse);
          break;
        case this.availableUsernameID:
          if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.fullName) && this.state.fullName !== "") {
            this.handleUsernameResponse(responseJson, errorResponse);
          }
          break;
        case this.getSellerProfileDetailsApiCallId:
          this.handleSellerProfileResponse(responseJson, errorResponse);
         
          break;
        case this.bankDetailsID:
          this.handleBankDetailsResponse(responseJson, errorResponse);
          break;
        case this.addressId:
          this.handleAddressResponse(responseJson, errorResponse);
          break;
        case this.handleBusinessDetailsApiCallId:
          this.handleBusinessDetailsResponse(responseJson, errorResponse);
          break;
        case this.categoryID:
          this.handleCategoryResponse(responseJson, errorResponse);
          break;
        case this.subCategoryID:
          this.handleSubCategoryResponse(responseJson, errorResponse);
          break;
        case this.apiSellerPrivacyPolicy:
          this.handleReciveTermsAndCondition(responseJson, "Flixoo Privacy Policy ");
          if (errorResponse) {
            this.parseApiErrorResponse(errorResponse);
            this.parseApiCatchErrorResponse(errorResponse);
          }
          break
        case this.apiSellerTermscondition:
          this.buyerHandleReciveTermsAndCondition(responseJson, "Flixoo Terms of Service ");
          if (errorResponse) {
            this.parseApiErrorResponse(errorResponse);
            this.parseApiCatchErrorResponse(errorResponse);
          }
          break

        case this.apiBuyerTermsAndCondtion:
          this.handleReciveTermsAndCondition(responseJson, "Flixoo Terms of Service ");
          break
              case this.apiBuyerPrivacyPolicy:
                this.handleReciveTermsAndCondition(responseJson, "Flixoo Privacy Policy ");
                break
              case this.googleLoginApiCallId:
                this.googleLoginApiResponce(responseJson)
                break;
              case this.appleLoginApiCallId:
                this.googleLoginApiResponce(responseJson)
            break;
        default:
          break;
      }
    }
  }
  countryCodeSelected(data: any): any {
    throw new Error("Method not implemented.");
  } 
  countryCodesSelected = (data: any) => {
    let a = data.filter((item: any) => (

      item.attributes.country_code == "91"))

    return a.map((item: any) => ({
      label: ` ${item.attributes.emoji_flag}`,
      value: `+${item.attributes.country_code}` // Added the "+" sign here
    }));
  };
  handleNavigation(responseJson: any, errorResponse: any) {

    if (responseJson.errors) {
      this.setState({ otpError: configJSON.otpError })
    } else {
      this.setState({ otpError: "", successMessage: true })
    }
  }
  formatFileSize = (bytes: any) => {
    if (bytes == 0) return '0 Bytes';
    let k = 1000,
      dm = 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  handleSignUpResponse = async(responseJson: any, errorResponse: any) => {
    
    this.setState({
      token: responseJson?.meta?.token,
    });
    this.receiveBlock();
    if (responseJson.errors) {
      this.setState({ alertError: responseJson.errors, isLoading: false })
      this.setState({ showAlert: true })
    } else {
      this.setState({
        isLoading: false
      })
      localStorage.setItem('authToken', responseJson?.meta?.token);
      setStorageData('buerLoginToken', responseJson?.meta?.login_token);
            const buyerData = await getStorageData("Buyer_Data");
      if (buyerData) {
        const newData = JSON.parse(buyerData);
        newData.account['user_name'] = this.state.selectedUsername;
              setStorageData('Buyer_Data', JSON.stringify(newData))
      } else {
        setStorageData('Buyer_Data', JSON.stringify(responseJson));
      }      
      localStorage.setItem('accontType', this.state.showMobileField ? 'sms_account' : 'email_account');
      this.props.navigation.navigate('RegistrationOTP');
    }

  }

  handleSellerProfileResponse(responseJson: any, errorResponse: any) {
    this.setState({
      bankDetailsFilled: responseJson.bank_detail !== null && responseJson.bank_detail !== undefined,
      addressDetailsFilled: responseJson.address.data !== null && responseJson.address.data !== undefined,
      businessDetailsFilled: responseJson.business_detail.data !== null && responseJson.business_detail.data !== undefined,
      bankAccountNumber: responseJson.bank_detail ? responseJson.bank_detail.bank_account_number : '',
      AccountHolderName: responseJson.bank_detail ? responseJson.bank_detail.account_holder_name : '',
      bankName: responseJson.bank_detail ? responseJson.bank_detail.bank_name : '',
      ifscCode: responseJson.bank_detail ? responseJson.bank_detail.ifsc_code : '',
      bankAccountType: responseJson.bank_detail ? responseJson.bank_detail.account_type : '',
      upiId: responseJson.bank_detail ? responseJson.bank_detail.upi_id : '',
      selectedDelivery: responseJson.address.data ? responseJson.address.data.attributes?.shipment_type : 'self_ship',
      addressOne: responseJson.address.data ? responseJson.address.data.attributes?.address_line_1 : '',
      addressTwo: responseJson.address.data ? responseJson.address.data.attributes?.address_line_2 : '',
      pinCode: responseJson.address.data ? responseJson.address.data.attributes?.pin_code.toString() : '',
      city: responseJson.address.data ? responseJson.address.data.attributes?.city : '',
      state: responseJson.address.data ? responseJson.address.data.attributes?.state : '',
      country: responseJson.address.data ? responseJson.address.data.attributes?.country : '',
      gst_pan: responseJson.business_detail.data ? responseJson.business_detail.data.attributes?.gst_pan_no : '',
      business_Licence: responseJson.business_detail.data ? responseJson.business_detail.data.attributes?.business_license_number : '',
      businessCategoriesId: responseJson.business_detail.data ? responseJson.business_detail.data.attributes?.category : null,
      businessSubCategoriesId: responseJson.business_detail.data ? responseJson.business_detail.data.attributes?.sub_category : null,
      uploadFiles: Array.isArray(responseJson.business_detail.data )? 
      responseJson.business_detail.data?.map((fileData: any) => ({
          isUploaded: fileData !== null && fileData !== undefined,
          name: fileData.attributes?.business_license_name,
          size: fileData.attributes?.business_license_size || 10800
      })) 
      : []
    }, () => {
       this.state.businessCategoriesId && this.handleSubCategory(this.state.businessCategoriesId.id)
       
    }
  );
  }

  handleBankDetailsResponse = (responseJson: any, errorResponse: any) => {

    if (responseJson.errors) {
      this.setState({ alertError: responseJson.errors })
      this.setState({ showAlert: true })
    } else {
      this.getSellerProfileDetails()
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1
      }));
    }
  }
  handleAddressResponse(responseJson: any, errorResponse: any) {

    if (responseJson.errors) {
      this.setState({ alertError: responseJson.errors })
      this.setState({ showAlert: true })
    } else {
      this.getSellerProfileDetails()
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1
      }));
    }
  }

  handleBusinessDetailsResponse(responseJson: any, errorResponse: any) {
    if (responseJson.errors) {
      this.setState({ alertError: responseJson.errors })
      this.setState({ showAlert: true })
    } else {
      this.state.bankDetailsFilled && this.state.addressDetailsFilled ? this.props.navigation.navigate("SubmittedDetails") : this.props.navigation.navigate("SellerDashboard")
    }
  }

  handleUsernameResponse(responseJson: any, errorResponse: any) {
    if (errorResponse) {
      this.parseApiErrorResponse(errorResponse);
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.setState({
      username: responseJson?.user_names,
    });
  }
  handleCategoryResponse(responseJson: any, errorResponse: any) {
   
    if (errorResponse) {
      this.parseApiErrorResponse(errorResponse);
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson.data.length > 0) {
      this.setState({ businessCategories: responseJson.data })
    }
  }
  handleSubCategoryResponse(responseJson: any, errorResponse: any) {
    if (errorResponse) {
      this.parseApiErrorResponse(errorResponse);
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson.data.length > 0) {
      this.setState((prevState) => ({
        businessSubCategories: [
            ...prevState.businessSubCategories,
            ...responseJson.data.filter((subcategory: any) =>
                !prevState.businessSubCategories.some((existingSubcategory: any) =>
                    existingSubcategory.attributes.id === subcategory.attributes.id)
            )
        ],
    }));
    }
  }
  receiveBlock = () => {

    if (this.state.showMobileField) {


      localStorage.setItem('phone', '+' + this.state.selectedValue?.value + this.state.phone);


    } else {

      localStorage.setItem('email', this.state.email);

    }
  }
  handleNavigation1(responseJson: any, errorResponse: any) {
    if (errorResponse) {
      this.parseApiErrorResponse(errorResponse);
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.setState({ otpError: "" })
    this.setState({ otpState: "" })
    this.props.navigation.navigate('RegistrationOTP');
  }
  otpModalClose = () => {
    this.setState({
      otpModal: false
    })
  }

  tick = () => {
    const { time } = this.state;

    if (time > 0) {
      this.setState((prevState) => ({
        time: prevState.time - 1,
      }));
    }
  }
  async componentDidMount() {
    let newActiveStep = await getStorageData('newStep');
    this.setState({ activeStep: Number(newActiveStep) });
    setTimeout(() => {
      removeStorageData('newStep');
    }, 10000);

    this.makeRemoteRequest();
    this.setState({ accountType: localStorage.getItem('accontType') })
    this.setState({userType: localStorage.getItem('userClickType')})
    this.startTimer();
    this.handleCategory();
    const script = document.createElement('script');
    script.onload = this.initializeGoogleSignIn;
    script.async = true;
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));

    

    if (firebase.apps.length === 0) {
      const firebaseConfig = {
        appId: "1:748958312232:web:eb5f0e3ccd09d04372d21d",
        authDomain: "flixoolive.firebaseapp.com",
        projectId: "flixoolive",
        storageBucket: "flixoolive.appspot.com",
        messagingSenderId: "748958312232",
        measurementId: "G-RHKYGJSV6N",
        apiKey: "AIzaSyDhTvxhzlBzlSDc8ZBuaLtQYZ0JQ1V4Dzg"
      };
      firebase.initializeApp(firebaseConfig);
    }
    window.location.pathname === '/BankingDetails' && this.state.userType==="seller" && this.getSellerProfileDetails()
  }

  async componentDidUpdate(prevProps:Props, prevState:S ) {
    if(prevState.activeStep != this.state.activeStep && this.state.activeStep === 1){
      checkLocationPermission(this);
    }
  }

  sellerSignUp = async() => {
    const deviceToken = await getStorageData("device_token")
    if (this.handleSellerSignupValidate()) {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
      };
      let body = {
        "data": {
          "type": "email_account",
          "attributes": {
            "full_name": this.state.fullName,
            "user_name": this.state.selectedUsername,
            "email": this.state.emailSellerSignup,
            "password": this.state.copyPassword,
            "role_id": 2,
            "phone_number": this.state.phoneNumber,
            "business_name": this.state.business_Name,
            "country_code": this.state.selectedValue.value,
            "business_type": this.state.selectBusiness,
            "device_id" : deviceToken,
            "store_type":this.state.storeType
          }
        }
      }

      let creatorBody = {
        "data": {
          "type": "email_account",
          "attributes": {
            "user_name": this.state.selectedUsername,
            "full_name": this.state.fullName,
            "password": this.state.copyPassword,
            "email": this.state.emailSellerSignup,
            "role_id": 7,
            "country_code": this.state.selectedValue.value,
            "full_phone_number": `${this.state.selectedValue.value}+${this.state.phoneNumber}`,
            "device_id" : deviceToken
          }
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerSignUpEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(this.state.userType === "creator" ? creatorBody : body)
      )
      this.apiSellerSignUp = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  }
  sellerMobileOtpVerifyBtn = () => {
    if (this.handleSellerOtpValidationsMobile()) {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem('mobileTokenSeller')
      };
      let raw = ({
        "pin": this.state.sellerMobileOtpVerify
      });
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerMobileOtpVerifyEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(raw)
      )
      this.apiSellerMobileOtpVerify = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  }

  reSendSellerOTPEmail = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    let body = {
      "data": {
        "attributes": {
          "email": localStorage.getItem('email'),
        }
      }
    } as any
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resendotpEndpointEmail}`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body
      )
    )
    this.emaillResendOTPID = requestMsg.messageId;

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return requestMsg.messageId;
  }

  sendSellerOTPEmailBtn = () => {
    if (this.handleSellerOtpValidationsEmail()) {
      const { otpState } = this.state
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        token: localStorage.getItem('EmailTokenSeller')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.otpEndpointEmail}?pin=${otpState}`
      );

      this.apiSellerEmailOtpVerify = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  }

  editMoibleVerifications = () => {
    if (this.editMobileValidations()) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        token: localStorage.getItem('singupLogin')
      };
      let raw = {
        "phone_number": this.state.phoneNumber,
        "country_code": this.state.selectedValue.value
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.editMobileEmailEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(raw)
      )
      this.apiEditMoibleEmail = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  }

  editMobileErrorResponse = () => {

  }

  editEmailVerifications = () => {
    if (this.editEmailverifcitonValidations()) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        token: localStorage.getItem('singupLogin')
      };
      let raw = {
        "email": this.state.emailSellerSignup,
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.editMobileEmailEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(raw)
      )
      this.apiEditEmail = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
  }


  async componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.timerID);
  }

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: ` ${item.attributes.emoji_flag}`,
      value: `+${item.attributes.country_code}`
    }));
  };

  reSendSellerOTPMobile = () => {
    this.setState({ time: 30, isTimerRunning: true});
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    let body = {
      "data": {

        "attributes": {
          "full_phone_number": localStorage.getItem('phoneNumber'),
        }
      }
    } as any
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resendotpEndpointMobile}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    this.mobileresSedOTPID = requestMessages.messageId;

    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }


  handleFullNameChange = (event: any) => {
    const { value } = event.target;
    this.setState({ fullName: value })
  }
  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes
    };

    this.countryCodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handlePwdChange = (event: any) => {
    const { value } = event.target;
    const { showPassword, copyPassword } = this.state;

    this.setState({ password: value });
    if (!showPassword) {
      const a = copyPassword + value.split("*").join("");
      this.setState({ copyPassword: a })
    } else {
      this.setState({ copyPassword: value })
    }

  };
  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  handleConfirmPwdChange = (event: any) => {
    const { value } = event.target;
    const { showPassword1, copyPassword1 } = this.state;

    this.setState({ reTypePassword: value });
    if (!showPassword1) {
      const a = copyPassword1 + value.split("*").join("");
      this.setState({ copyPassword1: a })
    } else {
      this.setState({ copyPassword1: value })
    }

  };

  toggleShowPassword1 = () => {
    this.setState((prevState) => ({
      showPassword1: !prevState.showPassword1,
    }));
  };



  handleChangeEmailSeller = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    this.setState({ emailSellerSignup: inputValue });
  };




  handleChange = (event: any) => {
    const inputValue = event.target.value;
    const hasCharacter = /[a-zA-Z]/.test(inputValue);
    
    if (!hasCharacter) {
      const numericValue = inputValue.replace(/\D/g, '');
      const limitedValue = numericValue.slice(0, 10);
      this.setState({ value: limitedValue, phone: limitedValue });
    } else {
      this.setState({ value: inputValue, email: inputValue });
    }
    if (inputValue === "") {
      this.setState({ showMobileField: false, valueError: true })
    } else {
      this.setState({ showMobileField: !hasCharacter, valueError: false });
    }
    this.mobileEmailLabel(inputValue, hasCharacter);
    this.signupButtonText(inputValue, hasCharacter);


  };

  handleInputChange = (event: any) => {
    this.setState({ inputValue: event.target.value });
  };
  setError = (stateKey: keyof S, errorMsg: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: errorMsg,
    }));
  };
  validateFullName = (): boolean => {
    const { fullName } = this.state;
    const numvalidate = /\d/;
    if (!fullName || fullName.trim().length <= 2 || numvalidate.test(fullName) || fullName.length >= 30 || fullName.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.fullName)) {
      this.setError("fullNameError", configJSON.fullNameError);
      return false;
    }
    this.setState({ fullNameError: "" });
    return true;
  };
  validatePhone = (): boolean => {
    const { showMobileField, phone } = this.state;
    if (showMobileField) {
      if (!phone || phone.length !== 10) {
        this.setError("phoneError", configJSON.phoneError);
        return false;
      }
      this.setState({ phoneError: "" });
    }
    return true;
  };
  validateEmail = (): boolean => {
    const { showMobileField, email } = this.state;
    if (!showMobileField) {
      if (email === "") {
        this.setState({ valueError: true })
        return false
      }
      if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/g.test(email)) {
        this.setError("emailError", configJSON.emailError);
        return false;
      }
      this.setState({ emailError: "" });
    }
    return true;
  };

  validateStoreType = (): boolean  => { 
    if(!this.state.storeType){
      this.setState({
        checkedStoreTypeError:true
      })
     
    }
    return true
  }
  validatePassword = (): boolean => {
    const { copyPassword, copyPassword1 } = this.state;
    if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(copyPassword)) {
      this.setError("pwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ pwdError: "" });

    if (copyPassword !== copyPassword1) {
      this.setError("reTypepasswordError", configJSON.confirmPasswordError);
      return false;
    }
    this.setState({ reTypepasswordError: "" });

    return true;
  };
  validateAccountHolderName = (): boolean => {
    const { AccountHolderName } = this.state;
    const numvalidate = /\d/;
    if (!AccountHolderName || AccountHolderName.trim().length <= 5 || numvalidate.test(AccountHolderName) || AccountHolderName.length >= 30 || AccountHolderName.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.AccountHolderName)) {
      this.setState({ AccountHolderNameError: "Please enter a valid Account Holder Name" });
      return false;
    }
    this.setState({ AccountHolderNameError: "" });
    return true;
  };
  validateBankAccountNumber = (): boolean => {
    const { bankAccountNumber } = this.state;

    if (bankAccountNumber === "" || bankAccountNumber.length < 9 || /[a-zA-Z]/.test(bankAccountNumber)) {
      this.setState({ bankAccountNumberError: "Account Number must be in between 9 to 18 character" });
      return false;
    }
    this.setState({ bankAccountNumberError: "" });
    return true;
  };
  validateBankName = (): boolean => {
    const { bankName } = this.state;
    const numvalidate = /\d/;
    if (!bankName || bankName.trim().length <= 2 || numvalidate.test(bankName) || bankName.length >= 30 || bankName.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.bankName)) {
      this.setState({ bankNameError: "Please enter a valid Bank Name" });
      return false; // instance.changebusinessCategory({ target: { value: { id: 1 } } });
      // instance.changebusinessSubCategory({ target: { value: 1 } });
    }
    this.setState({ bankNameError: "" });
    return true;
  };
  validateIFSCcode = (): boolean => {
    const { ifscCode } = this.state;
    if (ifscCode === "" || ifscCode.charAt(4) !== '0' || ifscCode.length !== 11) {
      this.setState({ ifscCodeError: "Please enter valid IFSC Code" });
      return false;
    }
    this.setState({ ifscCodeError: "" });
    return true;
  };
  validateAddressOne = (): boolean => {
    const { addressOne } = this.state;
    if (!addressOne || addressOne.trim().length <= 10 || addressOne.length >= 50 || addressOne.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>.?~\\]/.test(this.state.addressOne)) {
      this.setState({ addressOneError: "Please Enter a valid Address" });
      return false;
    }
    this.setState({ addressOneError: "" });
    return true;
  };

  validatePinCode = (): boolean => {
    const { pinCode } = this.state;
    if (!pinCode || pinCode.trim().length <= 5 || pinCode.charAt(0) === "0") {
      this.setState({ pinCodeError: "Pincode must be of 6 digits & 1st digit should not be 0" });
      return false;
    }
    this.setState({ pinCodeError: "" });
    return true;
  };
  validateCity = (): boolean => {
    const { city } = this.state;
    const numvalidate = /\d/;
    if (!city || city.trim().length <= 2 || numvalidate.test(city) || city.length >= 30 || city.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.city)) {
      this.setState({ cityError: "Please enter a valid City Name" });
      return false;
    }
    this.setState({ cityError: "" });
    return true;
  };
  validateState = (): boolean => {
    const { state } = this.state;
    const numvalidate = /\d/;
    if (!state || state.trim().length <= 2 || numvalidate.test(state) || state.length >= 30 || state.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.state)) {
      this.setState({ stateError: "Please enter a valid State Name" });
      return false;
    }
    this.setState({ stateError: "" });
    return true;
  };
  validateCountry = (): boolean => {
    const { country } = this.state;
    const numvalidate = /\d/;
    if (!country || country.trim().length <= 2 || numvalidate.test(country) || country.length >= 30 || country.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.country)) {
      this.setState({ countryError: "Please enter a valid Country Name" });
      return false;
    }
    this.setState({ countryError: "" });
    return true;
  };
  validateGSTPAN = (): boolean => {
    const { gst_pan, isGstNumber } = this.state;
    if(isGstNumber === "No"){
      this.setState({ gst_panError: "" });
      return true;
    }
    if (!gst_pan || gst_pan.trim().length <= 2 || gst_pan.length >= 30 || gst_pan.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.gst_pan) || !isGstNumber) {
      this.setState({ gst_panError: "Please enter a valid GST Number" });
      return false;
    }
    this.setState({ gst_panError: "" });
    return true;
  };
  validateBusinessLicence = (): boolean => {
    const { business_Licence } = this.state;
    if (!business_Licence || business_Licence.trim().length <= 2 || business_Licence.length >= 30 || business_Licence.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.business_Licence)) {
      this.setState({ business_LicenceError: "Please enter a valid Pan Number" });
      return false;
    }
    this.setState({ business_LicenceError: "" });
    return true;
  };
  validateSelectCategory = (): boolean => {
    if (this.state.selectedCategories.length === 0) {
      this.setState({ selectCategoryError: "Please Select any category" });
      return false;
    }
    this.setState({ selectCategoryError: "" });
    return true;
  };
  validateSelectSubCategory = (): boolean => {
    if (this.state.selectedSubCategories.length === 0) {
      this.setState({ selectSubCategoryError: "Please Select any category first" });
      return false;
    } else {
      if (this.state.selectedSubCategories.length === 0) {
        this.setState({ selectSubCategoryError: "Please Select any Sub category" });
        return false;
      }
      this.setState({ selectSubCategoryError: "" });
      return true;
    }

  };
  validateUplaodFile = (): boolean => {
   let length = 3;
   if (this.state.isGstNumber === "No") {
     length = 2;
   }
   const errors: string[] = [];
    if (this.state.uploadFiles.length < length) {
        errors.push("Please upload all files");
    }
    this.setState({ uploadError: errors });
    return errors.length === 0;
  };
  checkValidation = () => {
    let fullName = this.validateFullName()
    let phone = this.validatePhone()
    let email = this.validateEmail()
    let userName = this.validateUserName()
    let password = this.validatePassword()
    let confimpassword = this.validatePasswordConfirmation() 
    if (this.state.isChecked === false) {
      this.setState({ checkedError: true })
      return
    }
    if (fullName &&
      phone &&
      email &&
      userName &&
      password &&
      confimpassword && 
      this.state.isChecked     
    ) {
      this.setState({
        isLoading: true
      })
      this.handleSignup();
    }
  };
  validateBankDetails = () => {
    const { bankDetailsFilled } = this.state;

    const validationResults = [
      this.validateBankAccountNumber(),
      this.validateAccountHolderName(),
      this.validateBankName(),
      this.validateIFSCcode(),
      this.validateUpiId(),
      this.validateBankAccountType()
    ];
    const allValidationsPassed = validationResults.every(result => result);

    if (allValidationsPassed) {
      if (bankDetailsFilled) {
        this.handleUpdateBankDetailsApi();
      } else {
        this.handleBankDetailsApi();
      }
    }
  };

  validateUpiId = (): boolean => {
    const { upiId } = this.state;
    if (upiId === "") {
      this.setState({ upiIdError: "Please enter valid Upi Id" });
      return false;
    }
    this.setState({ upiIdError: "" });
    return true;
  };

  validateBankAccountType = (): boolean => {
    const { bankAccountType } = this.state;
    if (bankAccountType === "") {
      this.setState({ bankAccountTypeError: "Please enter valid bank account type" });
      return false;
    }
    this.setState({ bankAccountTypeError: "" });
    return true;
  };

  validateAddress = () => {
    const { addressDetailsFilled } = this.state;

    const validationResults = [
      this.validateAddressOne(),
      this.validatePinCode(),
      this.validateCity(),
      this.validateState(),
      this.validateCountry()
    ];

    const allValidationsPassed = validationResults.every(result => result);

    if (allValidationsPassed) {
      if (addressDetailsFilled) {
        this.handleUpdateAddressApi();
      } else {
        this.handleAddressApi();
      }
    }
  };

  validateBusinessDetails = () => {
    const { businessDetailsFilled } = this.state;

    const validationResults = [
      this.validateGSTPAN(),
      this.validateBusinessLicence(),
      this.validateSelectCategory(),
      this.validateSelectSubCategory(),
      this.validateUplaodFile(),
      this.validateCompanyType()
    ];

    const allValidationsPassed = validationResults.every(result => result);

    if (allValidationsPassed) {
      if (businessDetailsFilled) {
        this.handleUpdateBusinessDetailsApi();
      } else {
        this.handleBusinessDetailsApi();
      }
    }
  };

  validateCompanyType = (): boolean => {
    const { companyType } = this.state;
    if (companyType === "") {
      this.setState({ companyTypeError: "Please Select a valid company type" });
      return false;
    }
    this.setState({ companyTypeError: "" });
    return true;
  };

  setpasswordOfEmail = (event: any) => {
    const { value } = event.target;
    const { visibiltyOfPassword, copyPassword } = this.state;
    if (!visibiltyOfPassword) {
      const a = copyPassword + value.split("*").join("");
      this.setState({ copyPassword: a });
    } else {
      this.setState({ copyPassword: value });

    }
    this.setState({ password: value });

  };
  handleKey = (event: any) => {
    const { showPassword, copyPassword } = this.state;
    const keyCode = event.keyCode || event.which;
    if (keyCode == 8) {
      const caretPosition = event.target.selectionStart;
      if (!showPassword) {
        let a = copyPassword.split("")
        a.splice(caretPosition - 1, 1)
        this.setState({ copyPassword: a.join("") })

      }
    }
  }
  handleKey1 = (event: any) => {
    const { showPassword1, copyPassword1 } = this.state;
    const keyCode = event.keyCode || event.which;
    if (keyCode == 8) {
      const caretPosition1 = event.target.selectionStart;
      if (!showPassword1) {
        let a = copyPassword1.split("")
        a.splice(caretPosition1 - 1, 1)
        this.setState({ copyPassword1: a.join("") })

      }
    }
  }
  handleSignup = async () => {
    const { email, fullName, phone, selectedUsername, copyPassword, selectedValue } = this.state
    const deviceToken = await getStorageData("device_token")
    let body = {
      "data": {

        "attributes": {
          "full_name": fullName,
          "user_name": selectedUsername,
          "password": copyPassword,
          "device_id" : deviceToken
        }
      }
    } as any
    if (this.state.showMobileField) {

      body.data.attributes['phone_number'] = phone;
      body.data.type = "sms_account";
      body.data.attributes['country_code'] = selectedValue?.value;
    }
    else {
      body.data.attributes['email'] = email;
      body.data.type = "email_account";


    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    this.signUpId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleUsername = () => {
    const { business_Name } = this.state
    const {fullName} = this.state

    let body = {
      "data": {
        "type": "sms_account",
        "attributes": {
          "first_name": business_Name?business_Name:fullName,
          "last_name": "holand"
        }
      }
    } as any
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.availableEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    this.availableUsernameID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleCategory = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('singupLogin')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryEndpoint
    );
    this.categoryID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleSubCategory = (id: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('singupLogin')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategoryEndpoint}?category_id=${id}`
    );
    this.subCategoryID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getSellerProfileDetails = async () => {
    const header = { "Content-Type": "application/json", token: await getStorageData("singupLogin") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSellerProfileDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/get_seller_profile`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBankDetailsApi = async() => {
    const { AccountHolderName, bankAccountNumber, ifscCode, bankName, bankAccountType, upiId } = this.state
   const bankDetailToken =  await getStorageData("bankDetailToken")
     
    let body = {
      "bank_account_number": bankAccountNumber,
      "ifsc_code": ifscCode,
      "account_holder_name": AccountHolderName,
      "bank_name": bankName,
      "account_type": bankAccountType,
      "upi_id": upiId,
      bank_detail_token:bankDetailToken
    } as any
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('singupLogin')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankDetailsEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    this.bankDetailsID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleUpdateBankDetailsApi = async() => {
    const { AccountHolderName, bankName, bankAccountNumber, ifscCode, bankAccountType, upiId } = this.state
    const bankDetailToken =  await getStorageData("bankDetailToken")
    let body = {
      "ifsc_code": ifscCode,
      "bank_account_number": bankAccountNumber,
      "bank_name": bankName,
      "account_holder_name": AccountHolderName,
      "bank_detail_token":bankDetailToken,
      "account_type": bankAccountType,
      "upi_id": upiId,
    } as any

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('singupLogin')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.bankDetailsID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_form/bank_detail`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleAddressApi = () => {
    const { addressOne, addressTwo, pinCode, city, state, country, selectedDelivery } = this.state
    let body = {
      "address": {
        "address_line_1": addressOne,
        "address_line_2": addressTwo,
        "pin_code": pinCode,
        "city": city,
        "state": state,
        "country": country,
        "shipment_type": selectedDelivery,
        "latitude":this.state.lat,
        "longitude":this.state.lng
      }
    } as any
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('singupLogin')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    this.addressId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleUpdateAddressApi = () => {
    const { addressOne, pinCode, city, state, addressTwo, country, selectedDelivery } = this.state
    let body = {
      "address": {
        "address_line_2": addressTwo,
        "address_line_1": addressOne,
        "city": city,
        "pin_code": pinCode,
        "country": country,
        "state": state,
        "shipment_type": selectedDelivery,
      }
    } as any

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('singupLogin')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addressId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_address/address`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBusinessDetailsApi = async () => {
    let formdata = new FormData();
    const categoryIdsJson = JSON.stringify(this.state.selectedCategories);
    const subCategoryIdsJson = JSON.stringify(this.state.selectedSubCategories);   
    const isGstRegistered = this.state.isGstNumber === "No" ? "false" : "true";
    isGstRegistered  === "true" && formdata.append("business_detail[gst_number]", this.state.gst_pan);
    formdata.append("business_detail[pan_number]", this.state.business_Licence);
    formdata.append("business_detail[category_id]", categoryIdsJson);
    formdata.append("business_detail[sub_category_id]",subCategoryIdsJson);
    formdata.append("business_detail[company_type]",this.state.companyType);
    formdata.append("business_detail[gst_registered]",isGstRegistered);
    this.state.uploadFiles.forEach((file: File) => {
      formdata.append("business_detail[attachments][]", file);
    });
    const header = { token: await getStorageData('singupLogin') };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.handleBusinessDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_profile/business_details`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }

  handleUpdateBusinessDetailsApi = async () => {
    let formdata = new FormData(); 
    const categoryIdsJsonUpdate = JSON.stringify(this.state.selectedCategories);
    const subCategoryIdsJsonUpdate = JSON.stringify(this.state.selectedSubCategories);   
    const gstRegistered = this.state.isGstNumber === "No" ? "false" : "true";
    gstRegistered === "true" && formdata.append("business_detail[gst_number]", this.state.gst_pan);
    formdata.append("business_detail[pan_number]", this.state.business_Licence);
    formdata.append("business_detail[category_id]", categoryIdsJsonUpdate);
    formdata.append("business_detail[sub_category_id]",subCategoryIdsJsonUpdate);
    formdata.append("business_detail[company_type]",this.state.companyType);
    formdata.append("business_detail[gst_registered]",gstRegistered);
    if (this.state.uploadFiles.length > 0) {
      this.state.uploadFiles.forEach((file:any) => {
          if (file instanceof File) {
              formdata.append("business_detail[attachments][]", file);
          }
      });
  }
    const header = { token: await getStorageData('singupLogin') };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.handleBusinessDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_profile/business_details`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendOTPEmail = () => {
    const { otpState } = this.state
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.otpEndpointEmail}?pin=${otpState}`
    );

    this.emailOTPID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  sendOTPMobile = () => {
    const { otpState } = this.state
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.otpEndpointMobile}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ "pin": otpState }
      )
    )
    this.mobileOTPID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  reSendOTPMobile = () => {
    clearInterval(this.state.interval!);
    this.setState({ time: 30, isTimerRunning: true });
    this.startTimer();
    let body = {
      "data": {

        "attributes": {
          "full_phone_number": localStorage.getItem('phone'),
        }
      }
    } as any
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resendotpEndpointMobile}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    
    this.mobileresSedOTPID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  reSendOTPEmail = () => {
    clearInterval(this.state.interval!);
    this.setState({ time: 30, isTimerRunning: true });
      this.startTimer();
    let body = {
      "data": {
        "attributes": {
          "email": localStorage.getItem('email'),
        }
      }
    } as any
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resendotpEndpointEmail}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body
      )
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    this.emaillResendOTPID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleFocus = () => this.setState({ isFocused: true })
  handleBlur = () => {
    this.setState({ isFocused: false });
    if (this.state.fullNameError === "") {
      this.handleUsername()
    }
  }
  handleIsSelect = () => this.setState({ isSelect: !this.state.isSelect })
  handleSelectUsername = (e: any) => this.setState({ selectedUsername: e.target.value })
  handleIsChecked = () => {
    this.setState({ isChecked: !this.state.isChecked }, () => {
      if (this.state.isChecked) {
        this.setState({ checkedError: false })
      } else {
        this.setState({ checkedError: true })
      }
    })

  }
  selectCountryFlag = (item: any) => { this.setState({ isSelect: !this.state.isSelect, selectedValue: item }); }
  handleOTPState = (val: any) => {
    this.setState({ otpState: val })
  }
  goToLoginPage = () => {
    this.props.navigation.navigate('GetStartedLogin')
  }
  gotoBuyerLogin = () => {
    this.props.navigation.navigate('BuyerLogin')
  }
  goToBuyerSignup = () => {
    this.props.navigation.navigate('AccountRegistration')
  }
  goToSellerSignup = (userType: "seller"|"creator") => {
    setStorageData('userClickType', userType);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerSignUp");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { userType });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  mobileEmailLabel = (inputValue: any, hasCharacter: any) => {
    if (inputValue === "") {
      this.setState({ label: "Email or Mobile*" })
    } else if (!hasCharacter) {
      this.setState({ label: "Mobile Number*" })

    } else {
      this.setState({ label: "Email*" })

    }
  }
  signupButtonText = (inputValue: any, hasCharacter: any) => {
    if (inputValue === "") {
      this.setState({ signupButtonText: "Signup" })
    } else if (!hasCharacter) {
      this.setState({ signupButtonText: "Verify Mobile" })

    } else {
      this.setState({ signupButtonText: "Verify Email" })

    }
  }
  closeAlert = () => {
    this.setState({ showAlert: false })
  }
  generateErrorMessage = () => {
    if (typeof this.state.alertError === 'string' && this.state.alertError) {
      return this.state.alertError;
    } else if (typeof this.state.alertError === 'object') {
      if (Array.isArray(this.state.alertError)) {
        // Check for array with { "token" } structure
        const tokenError = this.state.alertError.find(item => item.token);
        if (tokenError) {
          return tokenError.token;
        }
      } else if (Object.entries(this.state.alertError).length > 0) {
        // Handle other object structures
        return Object.entries(this.state.alertError).map((i: any) =>
          `${i[0].split('_').join(" ")} ${i[1][0]}`
        ).join('\n');
      }
    }
    return "An unknown error occurred.";
  };
  successAlertOTP = async () => {
    const isBuyer= localStorage.getItem("Buyer_Data")
    if(isBuyer){
      this.props.navigation.navigate('LocationAccess')
    await setStorageData("ChooseCategoriesModal", JSON.stringify(true));
    }else{
      this.props.navigation.navigate('BuyerLogin')
    }
  }

  goToGetStartedSignup = () => {
    this.props.navigation.navigate('GetStartedSignup')
  }
  goToSellerDashboard = () => {
    this.props.navigation.navigate('SellerDashboard')
  }

  handlePhoneChange = (value: any) => {
    let phoneNo = value.replace(/\D/g, '').replace(' ', '');
    this.setState({ phoneNumber: phoneNo });
  };


  handleSellerSignupValidate = (): boolean => {
    const isValidateBusinessName = this.validateBusinessName()
    const isValidFullName = this.validateFullName1();
    const isValidUserName = this.validateUserName();
    const isValidBusinessType = this.validateBusinessType();
    const isValidEmail = this.validateEmail1();
    const isValidPassword = this.validatePassword1();
    const isValidPasswordConfirmation = this.validatePasswordConfirmation();
    const isValidCheckbox = this.validateCheckbox();
    const isValidMobileNumber = this.validateMobileNumber();
    const checkStore = this.validateStoreType()

 if ((this.state.userType !== "creator" &&
      isValidateBusinessName &&
      isValidFullName &&
      isValidUserName &&
      isValidBusinessType &&
      isValidEmail &&
      isValidPassword &&
      isValidPasswordConfirmation &&
      isValidCheckbox &&
      isValidMobileNumber &&
      checkStore
    )||(this.state.userType === "creator" &&
      isValidFullName &&
      isValidUserName &&
      isValidEmail &&
      isValidPassword &&
      isValidPasswordConfirmation &&
      isValidMobileNumber &&
      isValidCheckbox)) {
      this.setState({
        isLoading: true
      })
      return true;
    } else {
      return false;
    }
  };

  validateBusinessName = () => {
    const { business_Name } = this.state;
    if (business_Name === "") {
      this.setState({ businessNameError: "Business Name is required" });
      return false;
    } else if (business_Name.length > 50 || business_Name.length < 3) {
      this.setState({
        businessNameError: 'Your name should be more than 3 characters and not more than 50 characters.'
      });
      return false;
    }
    this.setState({ businessNameError: "" });
    return true;
  };

  validateFullName1 = () => {
    const { fullName } = this.state;
    const numValidate = /\d/;

    if (fullName.length === 0) {
      this.setState({ fullNameError: 'Please Enter First name & Last name.' });
      return false;
    } else if (!fullName || fullName.trim().length < 2 || fullName.trim().length > 30) {
      this.setState({ fullNameError: 'First name & last name must be between 2 to 30 characters.' });
      return false;
    } else if (numValidate.test(fullName) || fullName.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(fullName)) {
      this.setError("fullNameError", configJSON.fullNameError);
      return false;
    }

    this.setState({ fullNameError: "" });
    return true;
  };

  validateUserName = () => {
    const { selectedUsername } = this.state;
    if (selectedUsername === "") {
      this.setState({ userNameError: "UserName is required" });
      return false;
    } else if (selectedUsername.length > 29 || selectedUsername.length < 3) {
      this.setState({ userNameError: 'Username should be more than 3 characters and not more than 30 characters.' });
      return false;
    }
    this.setState({ userNameError: "" });
    return true;
  };

  validateBusinessType = () => {
    const { selectBusiness } = this.state;
    if (selectBusiness === "") {
      this.setState({ BusinessTypeError: "Business Type is required" });
      return false;
    }
    this.setState({ BusinessTypeError: "" });
    return true;
  };

  validateEmail1 = () => {
    const { emailSellerSignup } = this.state;
    if (emailSellerSignup === '') {
      this.setState({ emailError: "Email is required" });
      return false;
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(emailSellerSignup)) {
      this.setState({ emailError: "Please insert a correct email address" });
      return false;
    }
    this.setState({ emailError: "" });
    return true;
  };

  validatePassword1 = () => {
    const { copyPassword } = this.state;
    const withCapitalChar = copyPassword.match(/[A-Z]/g);
    const withSmallChar = copyPassword.match(/[a-z]/g);
    const withDigitChar = copyPassword.match(/\d/);
    const withSpecialChar = copyPassword.match(/[!@#$%^&*(),.?":{}|<>]/);
  
   
    if (copyPassword === '') {
      this.setState({ pwdError: configJSON.errorMessage });
      return false;
    } 
    if(!withCapitalChar){
      this.setState({
        pwdError: configJSON.errorCapitalLetter
      })
      return false;

    }else if(!withSmallChar){
      this.setState({
        pwdError: configJSON.errorSmallLetter
      })
      return false;
    }else if(!withDigitChar){
      this.setState({
        pwdError: configJSON.errorDigit
      })
      return false;
    }else if(!withSpecialChar){
      this.setState({
        pwdError: configJSON.errorSymbol
      })
      return false;
    }
    else if(copyPassword.trimStart().length < 8) {
      this.setState({pwdError: configJSON.errorChar});
      return false;
    }
    this.setState({ pwdError: "" });
    return true;
  };

  validatePasswordConfirmation = () => {
    const { copyPassword, copyPassword1 } = this.state;
    if (copyPassword1 === '') {
      this.setState({ reTypepasswordError: configJSON.errorMessageConfrim });
      return false;
    } else if (copyPassword !== copyPassword1) {
      this.setState({ reTypepasswordError: configJSON.unmatchedPwdError });
      return false;
    }
    this.setState({ reTypepasswordError: "" });
    return true;
  };

  validateCheckbox = () => {
    const { isChecked } = this.state;
    if (!isChecked) {
      this.setState({ checkedError: true });
      return false;
    }
    this.setState({ checkedError: false });
    return true;
  };

  validateMobileNumber = () => {
    const { phoneNumber } = this.state;
    if (phoneNumber === '') {
      this.setState({ mobileNumberError: "Mobile Number is required" });
      return false;
    } else if (phoneNumber.length !== 10) {
      this.setState({ mobileNumberError: configJSON.ValidMobileNumberErrorText });
      return false;
    }
    this.setState({ mobileNumberError: "" });
    return true;
  };

  hadleChangeBuisnessType = (event: any) => {
    const businessType = event.target.value;
    this.setState({ selectBusiness: businessType })
  }

  handleReceive = (responseJson: any) => {
    if (!!responseJson.account) {
      this.setState({
        isLoading: false
      });
      this.handleSuccessfulResponse(responseJson);
    } else if (!!responseJson.errors) {
      this.setState({
        isLoading: false
      });
      this.handleErrorResponse(responseJson);
    } else {
      // Handle the default case if needed
    }
  };

  handleSuccessfulResponse = (responseJson: any) => {
    const singupLogin = responseJson?.meta?.login_token;
    const token = responseJson?.meta?.phone_token;
    const EmailToken = responseJson?.meta?.email_token;
    const phoneNumber = this.state.selectedValue.value + this.state.phoneNumber;
    const Email = this.state.emailSellerSignup;

    this.setLocalStorageItems(singupLogin, token, EmailToken, phoneNumber, Email);

    if (this.state.userType==="creator"){
      localStorage.setItem("creatorLogin", singupLogin);
    }
    this.setState({ otpMinute: 1, otpSecond: 59 });

    if (this.state.isChecked === true) {
      localStorage.removeItem("successMessagesEmail");
      localStorage.removeItem("successMessages");
      this.props.navigation.navigate('SellerEmailMobileVarification');
    }


  };

  handleErrorResponse = (responseJson: any) => {
    const errorMessages = {
      "User name": "User has already been taken",
      "Full phone number": responseJson.errors[0]["Full phone number"],
      "Email": "Email has already been taken",
      "account": responseJson.errors[0]["account"],
    };

    let userNameError = "";
    let mobileNumberError = "";
    let emailError = "";

    for (const error of responseJson.errors) {
      for (const key in error) {
        if (errorMessages.hasOwnProperty(key)) {
          switch (key) {
            case "User name":
              userNameError = errorMessages[key];
              break;
            case "Full phone number":
              mobileNumberError = errorMessages[key];
              break;
            case "Email":
            case "account":
              emailError = errorMessages[key];
              break;
            default:
              // Handle other error types if needed
              break;
          }
        }
      }
    }

    this.setState({
      userNameError,
      mobileNumberError,
      emailError,
    });

    if (this.state.isChecked === false) {
      return;
    }
  };

  setLocalStorageItems = (singupLogin: any, token: any, EmailToken: any, phoneNumber: any, Email: any) => {
    localStorage.setItem("singupLogin", singupLogin);
    localStorage.setItem("mobileTokenSeller", token);
    localStorage.setItem("EmailTokenSeller", EmailToken);
    localStorage.setItem("phoneNumber", phoneNumber);
    localStorage.setItem("email", Email);
  };

  startTimer = () => {
    this.setState({ isTimerRunning: true }, () => {
      this.setState({
          interval: setInterval(() => {
              this.setState((prevState) => ({
                  time: prevState.time > 0 ? prevState.time - 1 : 0,
              }));
          }, 1000),
      });
  });
  }

  progressTimer = () => {
    this.timerID = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.progress === 100) {
          clearInterval(this.timerID);

          return { progress: 0 };
        }
        let randomArray = new Uint32Array(1);
        window.crypto.getRandomValues(randomArray);
        let randomNumber = randomArray[0] / 0xFFFFFFFF; // Normalize to a float between 0 and 1

        const diff = randomNumber * 10;
        return { progress: Math.min(prevState.progress + diff, 100) };
      });
    }, 100);
  }

  validateOTPMobile = () => {
    const { otpState } = this.state
    if (!otpState || otpState.length < 6) {
      this.setState({ otpError: configJSON.otpError })
    } else {
      this.sendOTPMobile()
    }
  }
  validateOTPEmail = () => {
    const { otpState } = this.state
    if (!otpState || otpState.length < 6) {
      this.setState({ otpError: configJSON.otpError })
    }
    else {
      this.sendOTPEmail()
    }
  }

  handleSellerMobileEmailOtpVerify = () => {
    this.apiSellerEmailOtpVerify()
    this.apiSellerMobileOtpVerify()
  }

  handleSellerOtpMobilechange = (event: any) => {
    this.setState({ sellerMobileOtpVerify: event });

    if (/^\d+$/.test(event) && event.length === 6) {
      this.setState({ sellerOtpMobileValidation: '' });
    } else {
      this.setState({ sellerOtpMobileValidation: 'OTP must be a 6-digit number' });
    }
  }

  handleSellerOtpEmailOnchange = (event: any) => {
    this.setState({ otpState: event })
    if (/^\d*$/.test(event) && event.length === 6) {
      this.setState({ sellerOtpEmailValidation: '' });
    } else {
      this.setState({ sellerOtpEmailValidation: 'OTP must be a 6-digit number' });
    }
  }
  EmailVerifySuccessMessage = (responseJson: any) => {
    const successMessageEmail = responseJson.meta.message
    this.setState({ isSucessEmailVerify: true, successMessageEmail })
    localStorage.setItem("successMessageEmail", JSON.stringify(responseJson.meta.message))
    this.handleNavigatetoBankDetails();
  }

  handleSellerOtpValidationsMobile = () => {
    let isValid = true;
    if (this.state.sellerMobileOtpVerify === "") {
      this.setState({ sellerOtpMobileValidation: "Mobile OTP is required" });
      isValid = false;
    }
    else if (this.state.sellerMobileOtpVerify.length < 6) {
      this.setState({ sellerOtpMobileValidation: "OTP must be a 6-digit" });
      isValid = false;
    }
    else {
      this.setState({ sellerOtpMobileValidation: "" });
    }
    return isValid;
  };


  handleSellerOtpValidationsEmail = () => {
    let isValid = true;
    if (this.state.otpState === "") {
      this.setState({ sellerOtpEmailValidation: "Email OTP is required" });
      isValid = false;
    }
    else if (this.state.otpState.length < 6) {
      this.setState({ sellerOtpEmailValidation: "OTP must be a 6-digit" });
      isValid = false;
    }
    else {
      this.setState({ sellerOtpEmailValidation: "" });
    }

    return isValid;
  };

  handleNavigatetoBankDetails = async () => {
    if (this.state.successMessages && this.state.successMessagesEmail) {
      const successMessages = await getStorageData("successMessages");
      const successMessagesEmail = await getStorageData("successMessagesEmail");
      const loginRedirectVerification = await getStorageData("loginRedirectVerification");
      if (successMessages && successMessagesEmail && loginRedirectVerification) {
        this.goToSellerDashboard()
      } else {
         this.setState({
        otpSuccessDialog: true
      }, () => {
        setTimeout(() => {
          this.props.navigation.navigate('BankingDetails')
        }, 1500);
      });
      }
    }
    else {
      // this.handleSellerOtpValidations
    }
  }

  editEmailverifcitonValidations = () => {
    let isValid = true
    if (this.state.emailSellerSignup === '') {
      this.setState({ emailError: "email is required" });
    }
    return isValid
  }
  editMobileValidations = () => {
    let isValid = true
    if (this.state.phoneNumber === "") {
      this.setState({ mobileNumberError: "mobile number is required" })
    }
    return isValid
  }

  sellerOtpSuccessMessageMobile = async (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const successMessages = responseJson.meta.message
      this.setState({ isSucessMobileVerify: true, successMessages })
      this.setState({ 
        isSucessMobileVerify: true, 
        successMessages 
    }, async () => {
        localStorage.setItem("successMessages", successMessages);
        await setStorageData('bankDetailToken', responseJson.meta.bank_detail_token);
        this.handleNavigatetoBankDetails();
    });

    }
    else if (responseJson && responseJson.errors[0]) {
      const pinError = responseJson.errors[0].pin;
      const phoneError = responseJson.errors[0].phone
      const expireToen = responseJson.errors?.[0].token
      const errorMessages = `${pinError || ''} ${phoneError || ''} ${expireToen || ''}`;
      this.setState({ sellerOtpMobileValidation: errorMessages });
    }
  }

  sellerOtpSuccessMessageEmail = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const successMessagesEmail = responseJson?.meta?.message;
      this.setState({ 
        isSucessEmailVerify: true, 
        successMessagesEmail 
    }, () => {
        localStorage.setItem("successMessagesEmail", successMessagesEmail);
        this.handleNavigatetoBankDetails();
    });
    } else if (responseJson && responseJson.errors[0]) {
      const pinError = responseJson?.errors[0]?.pin;
      const emailError = responseJson?.errors[0]?.email;
      const expireToen = responseJson?.errors[0]?.token
      const errorMessages = `${pinError || ''} ${emailError || ''} ${expireToen || ''}`;
      this.setState({ sellerOtpEmailValidation: errorMessages });
    }
  }



  editSuccessMessageEmail = (responseJson: any) => {
    if (responseJson) {
      if (!responseJson.errors) {
        const newEmailToken = responseJson.meta.email_token;
        this.setState({ emailSellerSignup: this.state.emailSellerSignup }, () => { })
        localStorage.setItem("email", this.state.emailSellerSignup);
        localStorage.setItem("EmailTokenSeller", newEmailToken);
        this.props.navigation.navigate('SellerEmailMobileVarification');
      } else if (responseJson.errors[0] && responseJson.errors[0].token) {
        const errorMessages = responseJson.errors[0].token;
        this.setState({ emailError: errorMessages });
      } else if (responseJson.errors[0].Email && responseJson.errors[0].Email[0]) {
        this.setState({ emailError: responseJson.errors[0].Email });
      }
    }
  }

  editSuccessMessageMobile = (responseJson: any) => {
    if (responseJson) {
      if (!responseJson.errors) {
        const newPhoneToken = responseJson.meta.phone_token;
        this.setState({ phoneNumber: this.state.phoneNumber }, () => { })
        localStorage.setItem("phoneNumber", this.state.phoneNumber);
        localStorage.setItem("mobileTokenSeller", newPhoneToken);
        this.props.navigation.navigate('SellerEmailMobileVarification');
      } else if (responseJson.errors && responseJson.errors[0] && responseJson.errors[0].token) {
        const errorMessages = responseJson.errors[0].token;
        this.setState({ mobileNumberError: errorMessages });
      } else if (responseJson.errors && responseJson.errors[0]["Full phone number"]) {
        this.setState({ mobileNumberError: responseJson.errors[0]["Full phone number"]});
      } else if(responseJson.errors[0]["Phone number"]){
        this.setState({mobileNumberError: responseJson.errors[0]["Phone number"]});
      }
    } else {
      this.setState({ mobileNumberError: '' });
    }
  }



  resendClick = () => {
    this.state.time === 0 && (this.state.accountType == "sms_account" ? this.reSendOTPMobile() : this.reSendOTPEmail())
  }

  handleBusinessNameError = (event: any) => {
    const inputValue = event.target.value;
    this.setState({ business_Name: inputValue });
  }

  handleNameLength = (event: any) => {
    const fullName = event.target.value;
    this.setState({ fullName });
  };

  handleNameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const key = event.key;
    

    if (
      !(
        (key >= 'a' && key <= 'z') ||
        (key >= 'A' && key <= 'Z') ||
        key === ' '
      )
    ) {
      event.preventDefault();
    }
  };

  businessOptions = [
    { value: 'product', label: 'Product' },
    { value: 'service', label: 'Service' },
  ];

  handleSelectUserMaxLength = (event: any) => {
    const inputValueUserName = event.target.value;
    this.setState({ selectedUsername: inputValueUserName });
  };

  handleResendMobileValidate = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const updateSignUpToken = responseJson.meta.token
      localStorage.setItem('mobileTokenSeller', updateSignUpToken)
      this.setState({ otpModal: true })
    } else {
      this.setState({ resendEmailValidation: false, resendMoibileError: responseJson.errors?.[0]?.account });
    }
  }

  handleResendEmailValidate = (responseJson: any) => {
    const resendEmailOtpValidate = responseJson.message?.[0]?.account;
    const resendErrorMessage = responseJson.errors?.[0]?.account;

    this.setState({ otpModal: true })
    if (resendEmailOtpValidate) {
      this.setState({ resendEmailValidation: true, resendEmailSuccessMessage: resendEmailOtpValidate });
    } else if (resendErrorMessage) {
      this.setState({ resendEmailValidation: false, resendEmailError: resendErrorMessage });
    } else {
      this.setState({ resendEmailError: '' });
    }
  }


  handleBankAccNumber = (event: any) => {

    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, '');
    const limitedValue = numericValue.slice(0, 18);

    this.setState({ bankAccountNumber: limitedValue, bankAccountNumberError: '' })

  }
  handleAccHolderName = (event: any) => {
    this.setState({ AccountHolderName: event.target.value, AccountHolderNameError: '' })

  }
  handleBankName = (event: any) => {
    this.setState({ bankName: event.target.value.toUpperCase(), bankNameError: '' })
  }
  handleIFSCcode = (event: any) => {
    this.setState({ ifscCode: event.target.value.toUpperCase(), ifscCodeError: '' })
  }
  handleAddressOne = (event: any) => {
    this.setState({ addressOne: event.target.value, addressOneError: '' })
  }
  handleAddressTwo = (event: any) => {
    this.setState({ addressTwo: event.target.value })
  }
  handlePincode = (event: any) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, '');
    const limitedValue = numericValue.slice(0, 6);
    this.setState({ pinCode: limitedValue, pinCodeError: '' })
  }
  handleCityChange = (event: any) => {
    this.setState({ city: event.target.value, cityError: '' })
  }
  handleStateChange = (event: any) => {
    this.setState({ state: event.target.value, stateError: '' })
  }
  handleCountryChange = (event: any) => {
    this.setState({ country: event.target.value.toUpperCase(), countryError: '' })
  }
  handleGSTPAN = (event: any) => {
    this.setState({ gst_pan: event.target.value.toUpperCase(), gst_panError: '' })
  }
  handleBusinessLicence = (event: any) => {
    this.setState({ business_Licence: event.target.value.toUpperCase(), business_LicenceError: '' })
  }

  handleNext = () => {
    const { activeStep } = this.state;

    if (activeStep < 2) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
    if (this.state.activeStep == 2) {
      this.goToSellerDashboard()
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleRadio_Address = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selectedDelivery: (event.target as HTMLInputElement).value,
    });
  };
  handleOpenCat=()=>{
    if(this.state.selectedCategories.length<0){
      this.setState({slectText:true})
    }else{
      this.setState({slectText:false})
    }
   
  }
  handleOpenSubCat = () => {
    if(this.state.selectedCategories.length<0){
      this.setState({slectTextSub:true})
    }else{
      this.setState({slectTextSub:false})
    }
  } 
  changebusinessCategory = (e: any) => {
    const value = e.target.value;
    this.setState((prevState) => {
        const newlySelected = typeof value === 'string' ? value.split(',') : value; 
        const updatedSubCategories = Array.isArray(prevState.selectedSubCategories) ? prevState.selectedSubCategories.filter((subCategoryId: string) => {
            const subCategory = prevState.businessSubCategories.find((subcategory: any) => subcategory.attributes.id === subCategoryId);
            return subCategory && newlySelected.includes(subCategory.attributes.category_id); 
        })
        : [];
        return {
            selectedCategories: newlySelected,
            selectedSubCategories: updatedSubCategories,
            businessSubCategoriesId: null,
        };
    }, () => {
        this.validateSelectCategory();
        this.validateSelectSubCategory(); 
        if (Array.isArray(value)) {
            value.forEach((categoryId: string) => {
                this.handleSubCategory(categoryId);
            });
        }
    });
};

  changebusinessSubCategory = (e: any) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      selectedSubCategories: typeof value === 'string' ? value.split(',') : value,
    }), () => {
      this.validateSelectSubCategory();
    });
  }
  clickUpload = () => {
    this.setState({ isUploaded: true })
  }
  clickImg = (index:number) => {
    this.setState((prevState) => ({
      uploadFiles: prevState.uploadFiles.filter((_:any, i:any) => i !== index),
  }));
  }
  sellerTermsAndCondition = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSellerTermscondition = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellerTermsAndConditonEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ sellerModalOpen: true });
  }

  sellerPrivacyPolicy = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSellerPrivacyPolicy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellerPrivacyPolicyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ sellerModalOpen: true });
  }

  buyerTermsAndCondition = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiBuyerTermsAndCondtion = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.buyerPrivacyPolicyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ sellerModalOpen: true });
  }
  buyerPrivacyPolicy = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiBuyerPrivacyPolicy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.buyerPrivacyPolicysEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ sellerModalOpen: true });
  }
  handleSellerModalClose = () => {
    this.setState({ sellerModalOpen: false })
  }

  handleReciveTermsAndCondition = (responseJson: any, heading: string) => {
    this.setState({
      termsConditionPrivacyPolicyData: {
        description: responseJson.data.attributes.description,
        heading: heading,
        effectiveDate: responseJson.data.attributes.effective_date
      },
      // isApiLoading: true
    })
  }

  buyerHandleReciveTermsAndCondition = (responseJson: any, heading: string) => {
    this.setState({
      termsConditionPrivacyPolicyData: {
        description: responseJson.data.attributes.description,
        heading: heading,
        effectiveDate: responseJson.data.attributes.effective_date
      },
      // isApiLoading: true
    })
  }


  closeErrorPopup = () => {
    this.setState({ googleSignUpError: false })
  }

  connectGoogle = () => {
    const googleResult = new firebase.auth.GoogleAuthProvider();
    googleResult.addScope("profile");
    googleResult.addScope("email");

    firebase.auth().signInWithPopup(googleResult).then((result) => {
      if (result.credential && result.user && result.additionalUserInfo) {
       
        this.setState({ googleSignup: true, resultState: result }, () => {
          this.setState({ fullName: this.state.resultState.additionalUserInfo.profile.name }, () => {
            this.handleUsername();
          })
        })
      }
    })
  };

  // on signup click
  initializeGoogleSignIn = () => {
    window.google.accounts.id.initialize({
      callback: this.handleLoginResponse,
      client_id: '703100623610-adc5i8fnsasb8bkk6cgco5ff50o8r88g.apps.googleusercontent.com', // Replace with your actual client ID
    });
  };

  handleLoginResponse = (response:MockForGoogleLoginObject) => {    
    const tokenResponse = jwt_decode(response.credential);
    this.googleLoginApi(tokenResponse as MockForGoogleLoginObject)
    // You can now use the tokenResponse to get user info or send it to your server
  };

  handleGoogleLoginClick = () => {
    window.google.accounts.id.prompt();
  };

  googleLoginApi = (tokenResponse: MockForGoogleLoginObject) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    this.googleLoginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.googleLoginApiEndPoint}?sub=${tokenResponse?.sub}&email=${tokenResponse?.email}&name=${tokenResponse?.name}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  googleLoginApiResponce = async (responseJson: { account: unknown; meta: { login_token: string; refresh_token: string }; }) => {
    if (responseJson) {
      await setStorageData('authToken', responseJson?.meta?.refresh_token);
      await setStorageData('buerLoginToken', responseJson?.meta?.login_token);
      await setStorageData('Buyer_Data', JSON.stringify(responseJson));
      await setStorageData('accontType', this.state.showMobileField ? 'sms_account' : 'email_account');
      this.handleNavigationAfterGoogleLogin()
    }
  }

  handleNavigationAfterGoogleLogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  googleSignupApi = async() => {
    const deviceToken = await getStorageData("device_token")
    if (this.state.isChecked) {
      const { resultState } = this.state
      this.setState({ fullName: `${resultState.additionalUserInfo.profile?.name}` })
      const header = {
        "Content-Type": 'application/json',
      };
      const raw = {
        "access_token": `${resultState.credential.accessToken}`,
        "email": `${resultState.additionalUserInfo?.profile?.email}`,
        "full_name": this.state.fullName,
        "user_name": this.state.selectedUsername,
        "role_id": 1,
        "device_id" : deviceToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.signUpGoogleCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.socialGoogleSignup
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(raw)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ checkedError: true })
    }
  }

  handleGoogleSignupResponse = (responseJson: any) => {
    
    if (responseJson.data) {
      this.setState({ googleSignup: true })
      setStorageData('buerLoginToken', responseJson?.meta?.login_token); 
      setStorageData("ChooseCategoriesModal", JSON.stringify(true));     
      this.props.navigation.navigate("BuyerDashboard")
    }
    else {
      this.setState({ googleSignUpError: true })
      this.setState({ errorMessageGoogleSignUp: responseJson.error })
     
    }
  }
  
  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (e.currentTarget.files) {
      const selectedFiles = Array.from(e.currentTarget.files).slice(0, 3 - this.state.uploadFiles.length);
      const existingFiles = new Set(this.state.uploadFiles.map((file: { name: any; }) => file.name));
      const newFiles = selectedFiles.filter((file: File) => !existingFiles.has(file.name));
      const duplicateFiles = selectedFiles.filter((file: File) => existingFiles.has(file.name));

      if (duplicateFiles.length > 0) {
        this.setState({ uploadError: ["Oops! It looks like you've already uploaded this file. Please choose a different one."] });
      } else {
        this.setState({ uploadError: [] });

        if (newFiles.length + this.state.uploadFiles.length <= 3) {
          this.setState((prevState) => ({
            uploadFiles: [...prevState.uploadFiles, ...newFiles],
            isUploaded: true,
          }))
        } else {
          this.setState({ uploadError: ["Oops! It looks like you've already uploaded this file. Please choose a different one."] });
        }
      }
    }
  };  

 handleStoreTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    storeType:event.target.value
  });
  if(event.target.value){
    this.setState({
      checkedStoreTypeError:false
    })
  }
};

  handleUpiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ upiId: event.target.value, upiIdError: "" });
  };

  handleBankAccountTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    this.setState({ bankAccountType: value, bankAccountTypeError: "" });
  };

  handleGenderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    this.setState({ gender: value, genderError: "" });
  };

  handleGstToogle = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ isGstNumber: event.target.value as string, gst_panError: "" });
  };

  handleCompanyTypeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ companyType: event.target.value as string, companyTypeError: "" });
  };

  handleCallback = (response:AppleLoginResponse) => {

    if (response.error) {
      console.error('Apple login error:', response.error);
      // Handle error response
      return;
    }
    
    console.log('Apple login success:', response);
    const { authorization, user } = response;

    // Decode the id_token to extract the sub
    const decodedToken = jwt_decode<DecodedToken>(authorization.id_token);
    const sub = decodedToken.sub;

    if(!user?.name?.firstName) {
      return this.appleLoginApiWithBody(sub)
    }

    // Example structure including the sub:
    const loginResponse = {
      authorization: {
        state: authorization.state,
        code: authorization.code,
        id_token: authorization.id_token,
        sub: sub,
      },
      user: {
        email: user.email,
        name: {
          firstName: user.name.firstName,
          lastName: user.name.lastName,
        },
      },
    };
    if(loginResponse) {

      this.appleLoginApi(loginResponse)
    }
    console.log('Formatted Response:', loginResponse);
  };

  appleLoginApi = (tokenResponse:AppleLoginResponse) => {
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.appleLoginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.googleLoginApiEndPoint}?sub=${tokenResponse.authorization.sub}&email=${tokenResponse.user.email}&name=${tokenResponse.user.name.firstName + tokenResponse.user.name.lastName }`
    );

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  appleLoginApiWithBody = (subId:string) => {
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.appleLoginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.googleLoginApiEndPoint}`
    );

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const body = {
      sub: subId
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getIfCondition(condition: boolean,firstElement:JSX.Element|string|boolean,secondElement:JSX.Element|string|boolean){
    if (condition){
      return firstElement;
    }
    return secondElement;
  };
handleFocusDob=()=>{
  if (this.state.dateOfBirthError) {
    this.setState({ dateOfBirthError: "" });
}
}
  handleFocusMobile = () => {
    this.setState({ mobileNumberError: "" })
  }

  handleFocusEmail = () => {
    this.setState({ emailError: "" })
  }
handleBlurDob=(event:React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>)=>{
  const selectedDate = event.target.value;
  const today = new Date();
  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - 12);
  const minCalendarDate = '1900-01-01';
  const selectedDateObj = new Date(selectedDate);
  let error = "";

  if (selectedDateObj > today) {
    error = 'Date of birth cannot be in the future.';
  } else if (selectedDateObj > minDate) {
    error = 'You must be at least 12 years old.';
  } else if (selectedDate < minCalendarDate) {
    error = 'Date of birth cannot be before 1900.';
  }
  if (error) {
    this.setState({ dateOfBirthError: error });
  } else {
    this.setState({ dateOfBirthError: "" });
  }
}
  handleDob = (event:React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => {
    this.setState({
      dateOfBirth: event.target.value,
    });
  };

  handleInstagram = (event:React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => {
    this.setState({instagramUrl: event.target.value, instagramUrlError: ""});
  };

  handleYoutubeLink = (event:React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => {
    this.setState({youtubeLink: event.target.value, youtubeLinkError: ""});
  };

  handleAddInfoSubmit = () => {
    let isError = false;
    if(this.state.dateOfBirthError!==""){
      isError=true
    }
    if (this.state.dateOfBirth===""){
      this.setState({dateOfBirthError: "Please select date of birth"});
      isError=true;
    }
    if (this.state.gender===""){
      this.setState({genderError: "Please select gender"});
      isError=true;
    }
    if (this.state.selectedCategories.length === 0) {
      this.setState({ selectCategoryError: "Please Select any category" });
      isError = true;
    }
    if (isError){
      return
    }else {
      this.postCreatorInfo()
    };
  };

  postCreatorInfo = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.creatorInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.creatorInfoEndpoint}`
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token : localStorage.getItem('creatorLogin')
    };

    const body = {
      gender: this.state.gender,
      date_of_birth: this.state.dateOfBirth,
      instagram_url: this.state.instagramUrl,
      youtube_url: this.state.youtubeLink,
      category_ids: this.state.selectedCategories
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMoreApiResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.creatorInfoApiCallId){
        if (responseJson.data){
        this.setState({creatorCreated: true});
        };
    };
  };
  renderCategoryValue = (selected: unknown) => {
    const selectedArray = selected as string[];
    return selectedArray
        .map((selectedId: string) => {
            const category = this.state.businessCategories.find(
                (category:{attributes:{id:string,name:string}}) => category.attributes.id === selectedId
            );
            return category ? category.attributes.name : '';
        })
        .join(', ');
};

handleCreatorContinue = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerDashboard");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
};

  // Customizable Area End
}
