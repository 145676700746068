import { getStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
import SwiperCore, { Navigation } from 'swiper';
const navigation = require('react-navigation');
const postBaseUrl = require("../../../framework/src/config.js").baseURL;
import SendbirdChat,{ SendbirdChatWith }  from '@sendbird/chat';
import { GroupChannel, GroupChannelModule} from "@sendbird/chat/groupChannel";
export const SENDBIRD_INFO = { appId: '901B5A29-B245-467F-B597-4D8A317B343C' };
import { loadChannels } from "../../../components/src/SendMsgOnStory.web";

interface ApiPayload{
    method: string;
    contentType?: string;
    body?: object | string;
    endPoint: string;
    type?: string;
}

interface CommentDetails {
  comment: string;
  profile_photo: string;
  like_count: number;
  sub_post_comments: CommentDetails[];
  self_like: boolean;
  replies_count: number;
  full_name: string | null;
  created_at: string;
  sub_comment_id: string;
}
interface Comment {
  type: string;
  id: string;
  attributes: CommentAttributes;
}

interface Meta {
  message: string;
}
interface CommentAttributes {
  details: CommentDetails;
}

interface CommentsResponse {
  data: Comment[];
  meta: Meta;
}

export interface PostDataPayload {
  isTagList: boolean;
  type: string;
  id: string;
  attributes: PostAttributes;
}

interface PostAttributes {
  product_detail?:	Product[]
  tag_list_details: TagDetail[];
  id: number;
  name: string | null;
  description: string | null;
  body: string;
  location: string | null;
  account_id: number;
  active_message: boolean;
  created_at: string;
  updated_at: string;
  images_and_videos: ImageOrVideo[];
  creator_details: CreatorDetails;
  like_count: number;
  is_liked: boolean;
  comment_count: number;
  is_following_seller: boolean;
  last_liked_by: LastLikedBy;
}

interface ImageOrVideo {
  id: number;
  filename: string;
  url: string;
  type: string;
}

interface PostShareDataApiResponse {
  data: PostDataPayload;
}

export interface Category {
  data: [
    {
      type: string;
      id: number;
      attributes: {
        name: string;
        id: number;
        light_icon: string
        slug: string;
        updated_at: string;
        selected: boolean
        created_at: string;
      }
    },
    {
      type: string;
      id: number;
      attributes: {
        name: string;
        id: number;
        light_icon: string
        slug: string;
        updated_at: string;
        selected: boolean
        created_at: string;
      }
    },
    {
      type: string;
      id: number;
      attributes: {
        name: string;
        id: number;
        light_icon: string
        slug: string;
        updated_at: string;
        selected: boolean
        created_at: string;
      }
    }
  ]
}

export interface CommentData {
  channel_url: string
}

interface ImgVideoPayload {
    filename: string;
    id: number;
    type: string;
    url: string;
}

interface CreatorDataPayload {
    full_name: string;
    profile_url: string | null;
    id: number;
}

interface TagDetail {
  profile_photo: string ;
  name: string;
  id: number;
  profile_url: string;
  user_name: string;
}

interface ProductImage {
  url: string;
  id: number;
}

interface ProductVariant {
  id: number;
  catalogue_variant_color_id: number;
  catalogue_id: number;
  price: string; 
  catalogue_variant_size_id: number;
  on_sale: boolean;
  stock_qty: number;
  discount_price: string | null; 
  sale_price: string; 
  breadth: number | null;
  length: number | null;
  created_at: string; 
  height: number | null;
  block_qty: number | null;
  updated_at: string;
}

interface Product {
  name: string;
  id: number;
  price: number;
  description: string;
  product_images: ProductImage[];
  variants: ProductVariant[]; 
}

interface PostAttributesPayload {
    product_detail?:	Product[]
    tag_list_details: TagDetail[];
    id: number;
    description: string | null;
    name: string | null;
    location: string | null;
    body: string;
    active_message: boolean;
    account_id: number;
    created_at: string;
    updated_at: string;
    creator_details: CreatorDataPayload;
    images_and_videos: ImgVideoPayload[];
    comment_count: number;
    is_following_seller: boolean;
    like_count: number;
    is_liked: boolean;
    last_liked_by?: LastLikedBy;
}

interface LastLikedBy {
  id: number;
  user_name: string;
  profile_url: string;
};

export interface PostDataPayloadType {
    id: string;
    isTagList: boolean;
    attributes: PostAttributesPayload;
    type: string;
}
export interface PostApiResponsePayload {
    message?: string;
    data: PostDataPayloadType[]
}

interface CommentDetails {
    other_like: boolean;
    profile_photo: string;
    comment: string;
    sub_post_comments: CommentDetails[];
    like_count: number;
    replies_count: number;
    self_like: boolean;
    created_at: string;
    full_name: string | null;
    sub_comment_id: string;
  }
  interface Comment {
      attributes: CommentAttributes;
      type: string;
      id: string;
  }

  interface Meta {
    message: string;
    current_user: CurrentUser;
  }

  interface CurrentUser {
    id: number;
    profile_photo: string;
    full_name: string;
    user_name: string;
  }
  interface CommentAttributes {
    details: CommentDetails;
  }

interface CommentsResponse {
    message: string;
    data: Comment[];
    meta: Meta;
  }


  interface LikeDataAttributes {
    likeable_id: number;
    likeable_type: string;
    like_by_id: number;
    created_at: string;
    updated_at: string;
  }
  
  interface LikeData {
    id: string;
    type: string;
    attributes: LikeDataAttributes;
  }
  
  interface LikeResponse {
    data: LikeData;
    message: string;
  } 

  interface SubPostCommentAttributes {
    id: number;
    account_id: number;
    comment_text: string;
    comment: Comment;
    created_at: string;
    updated_at: string;
  }
  
  interface SubPostCommentData {
    id: string;
    type: string;
    attributes: SubPostCommentAttributes;
  }
  
  interface SubPostCommentResponse {
    data: SubPostCommentData;
  }

export interface StoryUploaders {
  id: string;
  type: string;
  attributes: StoryAttributes;
}

interface StoryAttributes {
  id: number;
  title: string;
  description: string;
  duration: number;
  seen: boolean;
  location: string | null;
  color: string | null;
  activated: boolean;
  created_at: string;
  updated_at: string;
  creator_details: CreatorDetails;
  media: StoryMedia;
  duration_with_seconds: string;
  thumbnail?: string;
  liked: boolean;
}

interface CreatorDetails {
  id: number;
  full_name: string;
  profile_url: string | null;
}

export interface StoryMedia {
  thumbnail?: string;
  id: number;
  filename: string;
  url: string;
  type: string;
}

interface AllStoryResponse {
  data: Array<StoryUploaders>
}

SwiperCore.use([Navigation]);

interface TagListApiResponse {
  accounts: Accounts[];
}

export interface Accounts {
  x: number;
  y: number;
  id: number;
  user_name: string;
  full_name: string;
  profile_photo: string;
  role: {
    name: string;
  };
}

interface AccountSuggetion {
  id: number;
  full_name: string;
  user_name: string;
  follower_count: number;
  is_following: boolean;
  profile_url: string;
}

interface SuggestionAttributes {
  account: AccountSuggetion;
}

interface Suggestion {
  id: string;
  type: string;
  attributes: SuggestionAttributes;
}

interface SuggetionMeta {
  message: string;
  total_pages: number;
}

interface SuggetionResponse {
  data: Suggestion[];
  meta: SuggetionMeta;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: typeof navigation;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isAppLoader: boolean;
    sellerPostData: PostDataPayloadType[];
    buyerPostLoader: boolean;
    feedPageNo: number;
    perPageFeedForUpdate: number;
    isFeedLoader: boolean;
    islatestPost: boolean;
    hasMorePosts: boolean;
    isSideBar: boolean;
    showCommentBox: string;
    clickReply: number[];
    setPostCommentId: string;
    listCommentData: CommentsResponse;
    addComment: string;
    setReplyId: string;
  instaModal: boolean;
  instaModalIndex: number;
  allStoryHeader: Array<StoryUploaders>;
  userStory: Array<StoryUploaders>;
  goToSlide: string;
  storyMuted: boolean;
  storyPaused: boolean;
  currentStory: number;
  currentSlideIndex: number;
  showLeftScrollBtn: boolean;
  showRightScrollBtn: boolean;
    isSellerPostData : boolean;
    isShareModal : boolean;
    shareUrl: string;
    sharePostId : string;
    snackMessage: string;
    isSnackBarOpen: boolean;
    snackSeverity: 'info' | 'error' | 'success' | 'warning';
    profileImage: string;
    isSuggestionVisible: boolean,
    suggestionsTagList: Accounts[],
    suggestionPosition: string;
    BuyerDataId: number;
    loader: boolean;
    suggetionSeller: Suggestion[];
    currentPageSugg: number;
    suggetionTotalPage: number;
    messageText: string;
    commentStoryId: StoryAttributes;
    channelUrl: string;
    channel:  GroupChannel;
    listCmtData: Comment[];
    isPostloads: boolean;
    noMorePosts: boolean;
    textPost: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerFeedController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSellerPostDataApiCallId : string = "";
    sellerPostContainerRef: React.RefObject<HTMLDivElement> = createRef();
    postToggleLikeUnlikeApiCallID : string = "";
    postCommentOnCommentApiCallID: string = "";
    getPostCommentApiCallID: string = "";
    postCommentApiCallID: string = "";
    likeCommentApiCallID: string = "";
    likeReplyCommentApiCallID: string = "";
    allUserStoriesCallId: string = "";
    viewStoryCallId: string = "";
    likeStoryCallId: string = "";
    swiperInstance: SwiperCore | null = null;
    storyMainContainerRef : React.RefObject<HTMLDivElement> = createRef();
    getTagUserListApiCallId : string = "";
    getSuggetionSellerPostDataApiCallId : string = "";
    followSellerSideApiCallID : string = "";
    postCommentOnStoryCallId: string = "";
    sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isAppLoader: false,
            feedPageNo: 1,
            perPageFeedForUpdate: 5,
            buyerPostLoader: false,
            sellerPostData: [],
            isFeedLoader: false,
            hasMorePosts: true,
            islatestPost: false,
            isSideBar: true,
            showCommentBox: "",
            clickReply: [],
            setPostCommentId: "",
            listCommentData: {
              data: [],
              meta: {
                message: "",
                current_user: {
                  id: 0,
                  profile_photo: "",
                  full_name: "",
                  user_name: ""
                }
              },
              message: ""
            },
            addComment: "",
            instaModal: false,
            allStoryHeader: [],
            userStory : [],
            goToSlide: "",
            storyMuted: false,
            storyPaused: false,
            currentStory: 0,
            currentSlideIndex: 0,
            instaModalIndex: 0,
            showLeftScrollBtn: false,
            showRightScrollBtn: false,
            setReplyId: "",
            isSellerPostData: true,
            isShareModal: false,
            shareUrl: '',
            sharePostId: '',
            snackMessage: '',
            isSnackBarOpen: false,
            snackSeverity: 'success',
            profileImage: "",
          isSuggestionVisible: false,
          suggestionsTagList: [],
          suggestionPosition: "above",
          BuyerDataId: 0,
          loader: true,
          suggetionSeller: [],
          currentPageSugg: 1,
          suggetionTotalPage: 1,
          messageText: "",
          commentStoryId: {} as StoryAttributes,
          channelUrl: "",
          channel: {} as GroupChannel,
          listCmtData: [],
          isPostloads: false,
          noMorePosts: false,
          textPost: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJSON = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (responseJSON.status === 500) {
                this.showAlert("Error", "Internal Server Error!!");
                this.setState({ isAppLoader: false });
                return;
            }
            if (responseJSON && !responseJSON.errors) {
              if(this.getSellerPostDataApiCallId === apiRequestId){
                this.sellerPostDataSuccessCallBack(responseJSON);
              }
            }

            if (apiRequestId && responseJSON) {
                switch (apiRequestId) {
                    case this.getTagUserListApiCallId:
                        this.getTagUserListDataSuccessResponse(responseJSON);
                        break;
                    case this.getPostCommentApiCallID:
                        this.getListingDataSuccessCallback(responseJSON);
                        break;
                    case this.postCommentApiCallID:
                        this.getAddDataSuccessCallback(responseJSON);
                        break;
                    case this.postCommentOnCommentApiCallID:
                        this.postCommentOnCommentDataSuccessCallback(responseJSON);
                        break;
                    case this.likeCommentApiCallID:
                        this.likeCommentCallback(responseJSON);
                        break;
                    case this.likeReplyCommentApiCallID:
                        this.likeReplyCommentCallback(responseJSON);
                            break;
                  case this.getSuggetionSellerPostDataApiCallId:
                    this.suggetionSuccessCallback(responseJSON);
                    break;
                    case this.postCommentOnStoryCallId:
                        this.sendCommentSuccessCallBack(responseJSON);
                        break;
                    default:
                        break;
                }
            }
            this.handleMoreApiResponse(message);
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
      setTimeout(() => {
        this.setState({
            loader: false
        })
      }, 3000); 
      let profileImage = await getStorageData("profileImage")
      this.setState({ profileImage: profileImage })
      const buyerData = await getStorageData("user_id");
      this.setState({
        BuyerDataId: buyerData,
      })
        this.getTagUserListData("")
        this.getSellerPostListingData();
        this.getSuggetionsBuyerPostListingData();
        this.fetchAllUserStories();
        this.initializeSendBirdSDK();
        if (this.sellerPostContainerRef.current) {
            this.sellerPostContainerRef.current.addEventListener('scroll', this.handleScroll);
        }
    };

    handleMoreApiResponse = (message: Message) => {
        const apiRequestId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJSON = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        )  
        if (apiRequestId === this.likeStoryCallId) {
          this.likeStorySuccessCallBack(responseJSON)
        };

        if (apiRequestId === this.allUserStoriesCallId) {
          this.allUserStoriesSuccessCallBack(responseJSON)
        };
  
        if (apiRequestId === this.viewStoryCallId) {
          this.viewStorySuccessCallBack(responseJSON)
        };
    };

    handleScroll = () => {
      const container = this.sellerPostContainerRef.current;
    
      if (container) {
        const hasReachedEnd = container.scrollTop + container.clientHeight >= container.scrollHeight - 10;
        const hasMoreData = !this.state.isAppLoader && this.state.hasMorePosts && this.state.isSellerPostData;
    
        if (hasReachedEnd && hasMoreData) {
          this.setState({ isSellerPostData: false, noMorePosts:false, feedPageNo: this.state.feedPageNo+1 }, () => {
            this.getSellerPostListingData(true);
          });
        }
      }
    };

    getSellerPostListingData = async (isFromBottomPagination = false) => {
        if (isFromBottomPagination) {
            this.setState({ isFeedLoader: true });
        }
        const url = `?page=${this.state.feedPageNo}&per_page=${isFromBottomPagination ? this.state.sellerPostData.length + 5 : this.state.perPageFeedForUpdate}`;
        this.getSellerPostDataApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getPostListingDataEndPoint + url,
            body: '',
            type: ''
        });
    };

  getSuggetionsBuyerPostListingData = async () => {
    const url = `?page=${this.state.currentPageSugg}&per_page=${10}`;
    this.getSuggetionSellerPostDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.suggetionsSellerApiEndPoint + url,
      body: '',
      type: ''
    });
  };

  suggetionSuccessCallback = (responseJSON: SuggetionResponse) => {
    this.setState({
      suggetionSeller: responseJSON.data,
      suggetionTotalPage: responseJSON.meta.total_pages
    })
  }

  handleFollow = async (userId: number) => {
    const postData = [...this.state.sellerPostData];
    const updatedPostData = postData.map(post => {
      if (post.attributes.account_id === userId) {
        return {
          ...post,
          attributes: {
            ...post.attributes,
            is_following_seller: !post.attributes.is_following_seller
          }
        };
      }
      return post;
    });

    this.setState({ sellerPostData: updatedPostData });

    // Make the API call to follow/unfollow the user
    this.followSellerSideApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostAPiMethod,
      endPoint: configJSON.followSellar,
      body: {
        "data": {
          "attributes": {
            "followable_id": userId
          }
        }
      },
      type: ''
    });
    this.listingUpdate();
  };

  closeCommentSeller = () => {
    this.setState({
      showCommentBox: ""
    })
  }

  listingUpdate = () => {
    let paginationLength = Math.ceil(this.state.sellerPostData.length / 5);
    this.setState({ perPageFeedForUpdate: 5 * paginationLength }, () => {
      this.getSellerPostListingData();
      this.getSuggetionsBuyerPostListingData();
    });
  }

  getTagUserListData = async (searchParam: string) => {
    this.getTagUserListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.searchTagUserApiEndPoint + `?role=all&search=${searchParam}` + "&page=1&per_page=5",
      body: '',
      type: ''
    });
  };

  getTagUserListDataSuccessResponse = async (responseJSON: TagListApiResponse) => {
    this.setState({
      suggestionsTagList: responseJSON.accounts
    });
  }
    apiCall = async (apiRequestData: ApiPayload) => {
        const { contentType, method, endPoint, body, type } = apiRequestData;
        let token = await getStorageData("singupLogin");
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestApiMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestApiMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
        return requestApiMessage.messageId;
    };

    sellerPostDataSuccessCallBack = (responseData: PostApiResponsePayload) => {
      
        if (responseData && responseData.data) {
            if(responseData.data.length === 0 && responseData.message == "No data found"){
                this.setState({hasMorePosts: false});
                this.setState({isPostloads:true})
            } else if(responseData.message == "No more data available"){
              this.setState({ noMorePosts: true, textPost: "No more posts", hasMorePosts: false });
            }
            else {
              if (this.state.feedPageNo > 1) {
                  this.setState(prevState => ({
                      sellerPostData: [...prevState.sellerPostData, ...responseData.data],
                      isFeedLoader: false,
                      isSellerPostData: true,
                      isPostloads: false
                  }));
              }
              else {
                  this.setState(prevState => ({
                      sellerPostData: [...responseData.data, ...prevState.sellerPostData],
                      isFeedLoader: false,
                      isSellerPostData: true,
                      isPostloads: false
                  }));
              }}
          }
    };

    toggleSideBar = () => {
        this.setState({isSideBar: !this.state.isSideBar});
    };

    changeStory = (direction: "next" | "prev") => {
      let { currentStory, userStory, currentSlideIndex, allStoryHeader } = this.state;
  
      if (userStory.length === 0) return;
  
      let newIndex = currentStory;
  
      if (direction === 'next') {
        newIndex = currentStory + 1;
      } else if (direction === 'prev') {
        newIndex = currentStory - 1;
      }
      if (newIndex === userStory.length) {
        if (currentSlideIndex + 1 < allStoryHeader.length) {
          this.handleNext(currentSlideIndex, true);
        } else {
          allStoryHeader[currentSlideIndex].attributes.seen = true;
          this.handleClose();
        }
        newIndex = 0;
      } else if (newIndex < 0) {
        this.handlePrev(currentSlideIndex, true);
        newIndex = 0;
      }
  
      this.setState({ allStoryHeader, currentStory: newIndex });
    };

    handlePostLikeUnlike = (postId: string) => {
        const updatedPostData = [...this.state.sellerPostData];
        const postIndex = updatedPostData.findIndex(post => post.id === postId);

        if (postIndex !== -1) {
            const isLiked = updatedPostData[postIndex].attributes.is_liked;
            const likeCnt = updatedPostData[postIndex].attributes.like_count;
            if (isLiked === true) {
                updatedPostData[postIndex].attributes.is_liked = false;
                if (likeCnt > 0) {
                    updatedPostData[postIndex].attributes.like_count = likeCnt - 1;
                }
                this.setState({ sellerPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            } else {
                updatedPostData[postIndex].attributes.is_liked = true;
                updatedPostData[postIndex].attributes.like_count = likeCnt + 1;
                this.setState({ sellerPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            }
        }
    };

    postApiCallFn = async (postId: string) => {
        this.postToggleLikeUnlikeApiCallID = await this.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.postLikeUnlikeEndPoint,
            body: {
                "data": {
                    "attributes": {
                        "likeable_type": "BxBlockPosts::Post",
                        "likeable_id": Number(postId),
                    }
                }
            },
            type: ''
        })
        this.listingUpdate();
    };

    handleToggleComment = (postId:string,commentbox:string) => {
        this.getPostCommentApiCall(postId);
        this.setState({ setPostCommentId: postId, showCommentBox : commentbox });
    }

  clickTageListButton = (postId: string) => {
    let sellerPostData = this.state.sellerPostData
    const postIndex = sellerPostData.findIndex(post => post.id === postId);
    const postTagIndexTrue = sellerPostData.findIndex(post => post.isTagList === true);
    if(postTagIndexTrue !== -1){
      sellerPostData[postTagIndexTrue].isTagList = false
    }
    if (postIndex !== -1) {
      sellerPostData[postIndex].isTagList = !sellerPostData[postIndex].isTagList
    }
    this.setState({
      sellerPostData: sellerPostData
    })
  }

  closeTagUserList = (postId: string) => {
    let sellerPostData = this.state.sellerPostData
    const postIndex = sellerPostData.findIndex(post => post.id === postId);
    if (postIndex !== -1) {
      sellerPostData[postIndex].isTagList = false
    }
    this.setState({
      sellerPostData: sellerPostData
    })
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ addComment: value });

    const lastAtIndex = value.lastIndexOf('@');
    if (lastAtIndex !== -1) {
      const searchQuery = value.slice(lastAtIndex + 1).trim();
  
      if (searchQuery.length > 0) {
        this.getTagUserListData(searchQuery);
  
        // Calculate available space and set suggestion position
        const inputElement = event.target;
        const inputRect = inputElement.getBoundingClientRect();
        const spaceBelow = window.innerHeight - inputRect.bottom;
        const spaceAbove = inputRect.top;
  
        this.setState({
          isSuggestionVisible: true,
          suggestionPosition: spaceBelow < 200 && spaceAbove > 200 ? 'above' : 'below',
        });
      } else {
        this.setState({ isSuggestionVisible: true, suggestionPosition: 'below' });
      }
    } else if (!value.includes('@')) {
      this.setState({ isSuggestionVisible: false });
    }
  };
  

  handleTagSelection = (tag: string) => {
    const { addComment } = this.state;
    const updatedComment = addComment.replace(/@\w*$/, `@${tag} `);

    this.setState({
      addComment: updatedComment,
      isSuggestionVisible: false,
      suggestionsTagList: [],
    });
  };

    postCommentApiCall = async () => {
        this.postCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.postCommentEndPoint,
            method: configJSON.PostAPiMethod,
            body: {
                "comment": {
                    "comment": this.state.addComment,
                    "post_id": this.state.setPostCommentId
                }
            },
            type: ''
        })
    };

    getPostCommentApiCall = async (postId:string) => {
        this.getPostCommentApiCallID = await this.apiCall({
            endPoint: `${configJSON.getPostCommentListEndPoint}?post_id=${postId}`,
            method: configJSON.validationApiMethodType,
            type: '',
            contentType: configJSON.validationApiContentType,
        })
    };

    postCommentOnCommentApiCall = async () => {
        this.postCommentOnCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.postCommentOnCommentEndPoint,
            body: {
                "comment": {
                    "comment": this.state.addComment,
                    "comment_id": this.state.setReplyId,
                    "post_id": this.state.setPostCommentId,
                }
            },
            type: ''
        })
    };

    getAddDataSuccessCallback = (responseJson: CommentsResponse) => {
        if (responseJson && responseJson.data) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
            this.listingUpdate();
            this.setState({ addComment: "" });
          }
    };

    getListingDataSuccessCallback = (responseJson: CommentsResponse) => {
      if (responseJson && responseJson.data) {
        this.setState({ listCommentData: responseJson, addComment: "", suggestionsTagList: [] })
      }
      if (responseJson.message === "No Comments Found") {
        this.setState({
          listCommentData: {
            message: "",
            data: [],
            meta: responseJson.meta
          },
          addComment: "",
          suggestionsTagList: []
        })
      }
    };

    postCommentOnCommentDataSuccessCallback = (responseJson:SubPostCommentResponse) => {
        if (responseJson && responseJson.data) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
            this.listingUpdate();
            this.setState((prevState) => ({
              clickReply: [...prevState.clickReply, Number(this.state.setReplyId)], 
              addComment: "",
            }), ()=> {
              this.setState({  setReplyId : "" });
            });
        }
    }

    viewDetilsExpandable = (viewDetilsExpandableId: number) => {
        const viewDetailsExpandSet = new Set(this.state.clickReply);
        if (!viewDetailsExpandSet.has(viewDetilsExpandableId)) {
          viewDetailsExpandSet.add(viewDetilsExpandableId);
        } else {
          viewDetailsExpandSet.delete(viewDetilsExpandableId);
        }
        this.setState({ clickReply: Array.from(viewDetailsExpandSet) });
    }

    clickOnReplyForGetId = (idReply:string) => {
      if (this.state.setReplyId === idReply) {
        this.setState({ setReplyId: "" });
      } else {
        this.setState({ setReplyId: idReply });
      }
    }

    likeCommentApiCall = async (id:string) => {
        this.likeCommentApiCallID = await this.apiCall({
            endPoint: configJSON.likeCommentEndPoint,
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            body: {
                  "data": {
                    "attributes": {
                        "likeable_type": "BxBlockComments::Comment",
                      "likeable_id": id, //enter comment id
                    }
                  }
                },
            type: ''
        })
    };

    likeReplyCommentApiCall = async (id:string) => {
        this.likeReplyCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.likeCommentEndPoint,
            method: configJSON.PostAPiMethod,
            body: {
                  "data": {
                    "attributes": {
                        "likeable_type": "BxBlockComments::SubPostComment",
                      "likeable_id": id, //enter comment id
                    }
                  }
                },
            type: ''
        })
    };

    likeReplyCommentCallback = (responseJson:LikeResponse) => {
        if (responseJson) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
        }
    }

    likeCommentCallback = (responseJson:LikeResponse) => {
        if (responseJson) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
        }
    }

  handleClickOpen = (index: number) => {
    this.viewStory(index);
    this.setState({ instaModalIndex: index, currentSlideIndex: index })
  };

  handleAddStory = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CreateStory");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { navigateFrom: "SellerFeed" });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
  };

  viewStory = async (index: number) => {
    this.viewStoryCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        endPoint: `${configJSON.viewStoryEndPoint}user_id=${this.state.allStoryHeader[index].attributes.creator_details.id}&story_id=${this.state.allStoryHeader[index].attributes.id}`,
        type: "",
        method: configJSON.validationApiMethodType
      }
    );
  };

  handleClose = () => { this.setState({ instaModal: false })};

  handleNext = (userIndex: number,
    direct?: boolean,
    event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    let { allStoryHeader } = this.state;
    if (this.state.currentStory + 1 < this.state.userStory.length && !direct) {
      this.changeStory("next");
    } else {
      if (this.swiperInstance) {
        allStoryHeader[this.state.currentSlideIndex].attributes.seen = true;
        this.setState({ allStoryHeader },
          () => {
            this.viewStory(userIndex + 1);
            this.setState({ goToSlide: "next", currentSlideIndex: userIndex + 1 });
          })
      }
    }
  };

  handlePrevNext = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userIndex: number) => {
    const element = event.currentTarget;
    const parent = element.parentElement
    const classNames = parent?.classList;
    if (classNames?.contains('swiper-slide-next')) {
      this.handleNext(userIndex - 1, true, event);
    } else if (classNames?.contains('swiper-slide-prev')) {
      this.handlePrev(userIndex + 1, true, event);
    }
  };

  setSwiperRef = (swiper: SwiperCore) => {this.swiperInstance = swiper;};

  toggleMute = () => { this.setState({ storyMuted: !this.state.storyMuted });};

  calculateHours = (createdAt: string) => {
    const currentTime = Date.now();
    const createdTime = new Date(createdAt).getTime();
    const timeDifference = currentTime - createdTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    if (hours > 0) { return `${hours}h`; }
    else if (minutes > 0) { return `${minutes}m`; }
    else { return `${seconds}s`; }
  };

  likeStory = async (storyId: StoryAttributes) => {
    this.likeStoryCallId = await this.apiCall(
      {
        method: configJSON.PostAPiMethod,
        contentType: configJSON.validationApiContentType,
        body: {
          "data": {
            "attributes": {
              "likeable_type": "BxBlockPosts::Story",
              "likeable_id": storyId.id
            }
          }
        },
        endPoint: configJSON.postLikeUnlikeEndPoint,
        type: ""
      }
    )
    !storyId.liked &&
    this.postCommentOnStoryData(storyId.id);
  };

  showNextButton = (userIndex: number) => {
    const { allStoryHeader, userStory, currentStory } = this.state;
    if ((userIndex + 1 === allStoryHeader.length
      &&
      currentStory + 1 < userStory.length)
      ||
      userIndex + 1 < allStoryHeader.length) {
      return true;
    }
    return false
  };

  fetchAllUserStories = async () => {
    this.allUserStoriesCallId = await this.apiCall(
      {
        endPoint: configJSON.getAllStoriesEndPoint,
        method: configJSON.validationApiMethodType,
        type: "",
        contentType: configJSON.validationApiContentType
      }
    );
  };

  likeStorySuccessCallBack = (response: LikeResponse) => {
    let { userStory } = this.state;
    const olderStory = userStory;
    if (response.message) {
      userStory[this.state.currentStory].attributes.liked = !userStory[this.state.currentStory].attributes.liked;
    }
    if (response.data) {
      olderStory.forEach((item, index) => {
        if (item.attributes.id === response.data.attributes.likeable_id) {
          userStory[index].attributes.liked = !item.attributes.liked;
        }
      })
    };
  };

  viewStorySuccessCallBack = (response: AllStoryResponse) => {
    let { goToSlide } = this.state;
    this.setState(
      {
        instaModal: true,
        userStory: response.data,
        currentStory: 0
      },
      () => {
        if (goToSlide === "prev") { this.swiperInstance?.slidePrev(); }
        else if (goToSlide === "next") { this.swiperInstance?.slideNext(); }
        this.setState({ goToSlide: "" });
      });
  };


  showPreviousButton = (userIndex: number) => {
    const { currentStory } = this.state;
    if ((userIndex === 0 && currentStory !== 0) || userIndex > 0) {
      return true;
    }
    return false
  };

  allUserStoriesSuccessCallBack = (response: AllStoryResponse) => {
    if (response.data) {
      this.setState(
        (prevState) => ({
          allStoryHeader: [...response.data, ...prevState.allStoryHeader], 
        }),
        () => {
          if (this.state.allStoryHeader.length) {
            this.setState({ showRightScrollBtn: true });
            this.updateScrollButtons();
            this.storyMainContainerRef.current?.addEventListener(
              "scroll",
              this.handleStoryBoxScroll
            );
          }
        }
      );
    }
  };
  

  handlePrev = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (this.state.currentStory - 1 >= 0 && !direct) {this.changeStory("prev");}
    else {
      if (this.swiperInstance) {
        this.viewStory(userIndex - 1);
        this.setState({ goToSlide: "prev", currentSlideIndex: userIndex - 1 });
      }
    }
  };

  handleCreatorFollow = async (id: number) => {
    console.log(id);
    
  };

  handleStoryBoxScroll = () => {
    this.updateScrollButtons();
  };

  updateScrollButtons = () => {
    const container = this.storyMainContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      const atStart = scrollLeft === 0;
      const atEnd = scrollLeft + clientWidth >= scrollWidth;

      this.setState({
        showLeftScrollBtn: !atStart,
        showRightScrollBtn: !atEnd,
      });
    };

  };

  scrollStoryBoxLeft = () => {
    this.storyMainContainerRef.current?.scrollBy({ left: -200, behavior: 'smooth' });
  };

  scrollStoryBoxRight = () => {
    this.storyMainContainerRef.current?.scrollBy({ left: 200, behavior: 'smooth' });
  };

        
    toggleShareModal = () => {
        this.setState({ isShareModal: !this.state.isShareModal });
    };

    handlePostShareToggle = (postId: string) => {
        this.setState({ isShareModal: true, sharePostId: postId }, () => {
            this.setState({ shareUrl: `${postBaseUrl}/visit_post/${this.state.sharePostId}` })
        });
    };

    handleCopyShareLink = () => {
        const { shareUrl } = this.state;
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                this.setState({ snackMessage: 'Link Copied Succesfully!', isSnackBarOpen: true, snackSeverity: 'info' });
                setTimeout(() => {
                    this.setState({ snackMessage: '', isSnackBarOpen: false });
                }, 6000);
            })
    };

  handleKeyDown = (event: React.KeyboardEvent) => {
    if (this.state.addComment.length === 0) return;
    if (event.key === "Enter") {
      event.preventDefault();
      this.state.setReplyId === ""
        ? this.postCommentApiCall()
        : this.postCommentOnCommentApiCall();
    }
  };

  handleClickOfEachProductVarient = (itemId: number) => {
    const messageSeller = new Message(getName(MessageEnum.NavigationMessage));
    messageSeller.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDetails");
    messageSeller.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messageSeller.addData(getName(MessageEnum.NavigationScreenNameMessage), itemId);
    this.send(messageSeller)
  }
  handleSnackbarToggles = () => {
    this.setState({ isSnackBarOpen: !this.state.isSnackBarOpen })
};
  handlePageSellerChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPageSugg: page }, () => {
      this.getSuggetionsBuyerPostListingData();
    });
  };

  postCommentOnStoryData = async (storyId: number) => {
    this.postCommentOnStoryCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostAPiMethod,
      endPoint: configJSON.commentOnStory,
      body: {
        "comment": {
          "story_id": storyId ? storyId : this.state.commentStoryId.id,
          "comment": this.state.messageText ? this.state.messageText : '❤️'
        }
      },
      type: ""
    });
  };

  sendCommentSuccessCallBack = (responJson: CommentData) => {
    loadChannels(this ,responJson.channel_url || '', this.state.messageText ? this.state.messageText : '❤️', this.state.userStory[this.state.currentStory]?.attributes?.media)
  };

  handleMessage = (value: string) => {
    this.setState({
      messageText: value
    });
    setTimeout(() => {
      this.setState({
        messageText: ""
      })
    }, 3000);
  };

  handleStoryId = (storyId: StoryAttributes) => {
    this.setState({
      commentStoryId: storyId
    }, () => this.postCommentOnStoryData(storyId.id));
  };

  initializeSendBirdSDK = async () => {
    const sbUserId = await getStorageData('sbUserId');
    const sbToken = await getStorageData("sbToken");
    
    const sendbirdChat = SendbirdChat.init({
      appId: SENDBIRD_INFO.appId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()]
    });
    try {
      await sendbirdChat.connect(sbUserId, sbToken);
    } catch (error) {
    }
    await sendbirdChat.setChannelInvitationPreference(true);
    this.sendBird = sendbirdChat;
  };
    // Customizable Area End
}