import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    orderItem: any;
    recommendationData: any;
    tracking: boolean;
    activeStep: number
    orderDetails: any;
    orderId: any;
    subCategoryID: any;
    catalougeID: any;
    wishListItem: any;
    message: string;
    notifyModel: boolean;
    createdAt: string;
    expireAt: string;
    remainingTime: number;
    percentage: number;
    Otp:number;
    orderManagementId:number;
    regenerateOtp:boolean;
    deliveryType: string;
    currentSellerName:string;
    currentSellerUpi:string;
    buyerStatus: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerOrderDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getOrderDetailsApiCallId: string = '';
    getRecommendationDataApiCallId: string = '';
    removeItemFromWatchlistApiCallId: string = '';
    cancelOrderApiCallId: string = '';
    addItemInWatchlistApiCallId: string = '';
    getOtpdetails: string = "";
    intervalId: NodeJS.Timeout | null = null;
    regenerateOtpdetails: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            orderItem: [],
            recommendationData: [],
            tracking: false,
            activeStep: 1,
            orderDetails: [],
            orderId: '',
            subCategoryID: '',
            catalougeID: '',
            wishListItem: [],
            message: '',
            notifyModel: false,
            createdAt: "",
            expireAt: "",
            remainingTime: 0,
            percentage: 100,
            Otp:0,
            orderManagementId:0,
            regenerateOtp: false,
            deliveryType:"",
            currentSellerName:"",
            currentSellerUpi:"",
            buyerStatus: ""

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getOrderDetailsApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        orderDetails: responseJson.data?.attributes,
                        subCategoryID: responseJson.data?.attributes.catalogue?.attributes.sub_category_id,
                        catalougeID: responseJson.data?.attributes.catalogue?.id,
                        orderManagementId: responseJson.data?.attributes.order_management_order_id,
                        deliveryType: responseJson.data?.attributes.deliver_by,
                        buyerStatus: responseJson.data?.attributes.status
                    }, () => {
                        this.getRecommendationData();
                        this.getOtpApiCall();
                        this.setState({ loading: false });
                    });
                }
            } else if (apiRequestCallId === this.getRecommendationDataApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    this.setState({
                        recommendationData: responseJson.data,
                        wishListItem: responseJson.wishlist_items.product_ids
                    });
                }
            } else if (apiRequestCallId === this.addItemInWatchlistApiCallId) {
                this.setState({ loading: false });
                if (responseJson.message) {
                    this.getRecommendationData();
                    this.setState({
                        message: responseJson.message,
                        notifyModel: true
                    });
                } else if (responseJson.errors) {
                    this.setState({
                        message: responseJson.errors,
                        notifyModel: true
                    })
                }
            } else if (apiRequestCallId === this.removeItemFromWatchlistApiCallId) {
                this.setState({ loading: false });
                if (responseJson.message) {
                    this.getRecommendationData();
                    this.setState({
                        message: responseJson.message,
                        notifyModel: true
                    });
                } else if (responseJson.errors) {
                    this.setState({
                        message: responseJson.errors[0],
                        notifyModel: true
                    })
                }
            }

            else if (apiRequestCallId === this.cancelOrderApiCallId) {
                this.setState({ loading: false });
            } else if (apiRequestCallId === this.getOtpdetails) {
                if(responseJson.errors){
                    this.setState({regenerateOtp:true})
                }else{
                    this.setState({Otp:responseJson.otp_details.otp,
                        createdAt:responseJson.otp_details.created_at,
                        expireAt:responseJson.otp_details.expire_at,
                        currentSellerName: responseJson.seller_details.full_name, 
                        currentSellerUpi: responseJson.seller_details.upi_id
                    },()=>{this.setState({ loading: false })}
                )}
                } else if(apiRequestCallId === this.regenerateOtpdetails){
                    this.getOtpApiCall()
                    this.setState({regenerateOtp:false})
                }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const queryParameters = new URLSearchParams(window.location.search)
        const searchName = queryParameters.get("item_id")
        if (searchName !== null) {
            this.setState({
                orderId: searchName
            }, () => {
                this.getOrderDetails();
            })
        }
        
        this.calculateRemainingTimes();
        this.intervalId = setInterval(this.calculateRemainingTimes, 1000);
        // Customizable Area End
    }

    // Customizable Area Start
    navigateToSeeAll = () => {
        this.props.navigation.navigate("BuyerMarketplace");
    }

    goBackOnOrderList = () => {
        this.props.navigation.navigate("CustomisedOrderStatus");
    }

    goBackToOrderSection = () => {
        this.setState({ tracking: false });
    };

    handleTracking = () => {
        this.setState({
            tracking: true
        })
    }

    handleWatchlistItem = (event: any, id: any, items: any, type: any) => {
        event.stopPropagation();
        this.checkWatchlistStatus(items, parseInt(id)) ?
            this.removeWatchlist(items, parseInt(id)) :
            this.addItemInWatchlist(type, id)
    }

    checkWatchlistStatus = (items: any, id: any) => {
        const index = items?.findIndex((data: any) => data.product_id === id);
        if (index !== -1) {
            return true;
        } else {
            return false;
        }
    }

    removeWatchlist = (items: any, id: any) => {
        const index = items.find((data: any) => data.product_id === id).favourite_id;
        if (index) {
            this.removeItemFromWatchlist(index)
        } else {
            return false;
        }
    }

    closeNotifyModel = () => {
        this.setState({
            notifyModel: false
        })
    }

    getOrderDetails = async () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOrderDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/order_items/${this.state.orderId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getRecommendationData = async () => {
        this.setState({ loading: true });
        const { subCategoryID, catalougeID } = this.state;
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRecommendationDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/related_catalogue?sub_category_id=${subCategoryID}&catalogue_id=${catalougeID}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addItemInWatchlist = async (type: any, id: any) => {
        this.setState({ loading: true });

        const httpBody = {
            "data": {
                "favouriteable_id": id,
                "type": type === 'catalogue' ? 'product' : type
            }
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addItemInWatchlistApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_favourite/favourites');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeItemFromWatchlist = async (id: any) => {
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.removeItemFromWatchlistApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_favourite/favourites/' + id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    cancelOrderApi = async () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
        const porterId = this.state.orderDetails.porter_order_id;
        const orderId = this.state.orderDetails.id;
        const orderManagementId = this.state.orderDetails.order_management_order_id;

        const withPorter = {
            "order_id": porterId
        };

        const withoutPorter = {
            "order_item_id": orderId,
            "order_management_order_id": orderManagementId
        };
        const httpBody = porterId !== null ? withPorter : withoutPorter;

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.cancelOrderApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_order_management/orders/cancel_porter_order`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getOtpApiCall=async()=>{
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", 
            "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOtpdetails = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 
        `bx_block_order_management/orders/${this.state.orderManagementId}/get_otp_details`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), 
        JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    calculateRemainingTimes = () => {
        const { createdAt, 
            expireAt } = this.state;
        const nowDate = new Date();
        const createdDateget = 
        new Date(createdAt);
        const expireDateget = 
        new Date(expireAt);
        const totalDurationcal = 
        expireDateget.getTime() - createdDateget.getTime();
        const timePassedcal = 
        nowDate.getTime() - createdDateget.getTime();
        const remainingtime = 
        Math.max(expireDateget.getTime() - nowDate.getTime(), 0);

        this.setState({
            remainingTime: 
            Math.floor(remainingtime / 1000),
            percentage: 
            Math.max(0, ((totalDurationcal - timePassedcal) / totalDurationcal) * 100),
        });
    }; 
    formatTimes = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };
    regerateOtpApi=async()=>{
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.regenerateOtpdetails = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/orders/${this.state.orderManagementId}/regenerate_otp`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    navigateToProductDesc = (productId: string | number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
        this.send(message);
    };

    showCancelOrder = (orderStatus: string) => {
        if (orderStatus === "declined" || orderStatus === "delivered" || orderStatus === "accepted" || orderStatus === "cancelled") {
            return false;
        }

        return orderStatus ? true : false;
    };
    // Customizable Area End
}