import React from "react";
// Customizable Area Start
import SingleOrderStatusController, {
    Props,configJSON
} from "./SingleOrderStatusController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Paper, Box, Typography, Divider, Button } from '@material-ui/core';
import { hamburgerIc } from "../../landingpage/src/assets";
import { backButton, bellIcon, calendarIcon } from "../../InventoryManagement3/src/assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { imgCancelOrder, imgOnHoldClock, imgReadyTruck, imgShipedtruck, imgShippingClock ,cancelTicked} from "./assets";
import { StyledSwitch } from "../../../components/src/StyledSwitch.web";
const baseURL = require("../../../framework/src/config.js").baseURL
import Loader from "../../../components/src/Loader.web";
import OtpInput from 'react-otp-input';
import SuccessDialog from "../../../components/src/SuccesDailogMessage.web"
import { sucessImageIcon } from "./assets";
import { GetColorName } from 'hex-color-to-color-name';
import MessageModalWeb from "../../../components/src/MessageModal.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        margin: "5em 0em 0em",
        width: '100vw',
        display: 'flex',
        boxShadow: 'none'
    },
    titleBox: {
        display: "flex",
        gap: '12px',
        justifyContent: "space-between",
        alignItems: 'center',
        padding: "0em 2em",
        '@media(max-width: 656px)': {
            padding: '0em 1em'
        },
    },
    hamburgerIconStyle: {
        height: '48px',
        width: '48px',
        border: '1px solid var(--0, #FFF)',
        borderRadius: '10px',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        background: 'var(--0, #FFF)',
        alignItems: "center",
        justifyContent: "center",
        cursor: 'pointer',
        display: "flex",
        '@media(min-width: 991px)': {
            display: 'none',
        },
    },
    iconsStyle: {
        width: '48px',
        height: '48px',
        borderRadius: '10px',
        border: '1px solid var(--0, #FFF)',
        background: 'var(--0, #FFF)',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    titleTxt: {
        fontSize: '20px',
        color: 'rgba(68, 68, 68, 0.80)',
        fontWeight: 500,
    },
    switchContainer: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        padding: '0em 2em',
        justifyContent: 'flex-end',
        '@media(max-width: 656px)': {
            padding: '0em 1em'
        },
    },
    orderDetailsContainer: {
        display: 'flex',
        padding: '0em 2em',
        gap: '40px',
        flexDirection: 'column',
        '@media(max-width: 656px)': {
            padding: '0em 1em'
        },
    },
    orderItemContainer: {
        display: 'flex',
        gap: '24px',
        border: '1px solid #4444441A',
        borderRadius: '10px',
        padding: '24px',
        justifyContent: 'space-between',
        '@media(max-width: 789px)': {
            flexDirection: 'column',
            alignItems: 'center',
            padding: '12px'
        },
    },
    leftContainer: {
        display: 'flex',
        alignItems: 'start',
        gap: '16px',
        maxWidth: '500px',
        width: '100%',
        '@media(max-width: 656px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    leftContainerInner:{ 
        display: 'flex',
        flexDirection: 'column',
        gap: '16px' 
    },
    verticalDivider: {
        color: '#4444441A',
        width: '2px',
        '@media(max-width: 789px)': {
            display: 'none'
        },
    },
    horizontolDivider: {
        color: '#4444441A',
        height: '2px',
        width: '100%',
        '@media(min-width: 789px)': {
            display: 'none'
        },
    },
    statusBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '2px 4px',
        borderRadius: '4px',
        gap: '4px'
    },
    statusTxt: {
        color: '#555555B2',
        fontSize: '12px',
        fontWeight: 500,
        whiteSpace: 'nowrap'
    },
    acceptBtn: {
        maxWidth: '414px',
        width: '100%',
        height: '48px',
        background: '#F5EA16',
        borderRadius: '50px',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'none',
        color: '#555555',
        marginTop:"20px",
        "&:hover": {
            background: "#F5EA16",
        },
    },
    cancelOrderBtn: {
        maxWidth: '414px',
        width: '100%',
        height: '48px',
        border: '1px solid #444444',
        borderRadius: '50px',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'none',
        color: '#555555',
        marginTop:"15px"
    },
    keyText: {
        color: '#444444CC',
        fontSize: '14px',
        fontWeight: 500,
        maxWidth: '200px',
        width: '100%'
    },
    middleText: {
        color: '#444444CC',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 500,
        maxWidth: '50px',
        width: '100%'
    },
    valueText: {
        color: '#444444CC',
        textAlign: 'end',
        fontSize: '14px',
        fontWeight: 500,
        width: '100%',
        maxWidth: '200px',
    },
    colorBox: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'end',
        alignItems: 'center'
    },
    orderStatusMainContainer:{
        paddingTop: "40px", 
        width: '100%',
        display: 'flex',
        flexDirection: 'column', 
        gap: '24px',
        background:"light grey"
    },
    titleBoxInnerSection:{
      display: 'flex', 
      alignItems: 'center', 
      gap: '16px' 
    },
    backButton:{
         cursor: 'pointer' 
    },
    topHeaderActions:{
        display: "flex",
         gap: '12px'
    },
    switchContainerInnerSection:{
       display: 'flex', 
       gap: '8px', 
       alignItems: 'center' 
    },
    switchLabels:{
        color: '#444444',
        fontSize: '12px', 
        fontWeight: 500 
    },
    orderCanceledText:{
        display: 'inline-flex',
        padding: '8px 16px 8px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: 'var(--Basic-White, #FFF)',
        boxShadow: '0px 6px 15px -3px rgba(0, 0, 0, 0.15)',
        width:"300px"
    },
    productImage:{
        height: '100px',
        width: '100px', 
        borderRadius: '10px'
    },
    topContent:{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    bottomContent:{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px' 
    },
    topContentInner:{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        flexWrap: 'wrap' ,
    },
    productTitle:{ 
        color: '#555555', 
        fontSize: '16px',
        fontWeight: 600
    },
    orderNumber:{ 
        color: '#55555580',
        fontSize: '14px',
        fontWeight: 400 
    },
    buyersInfoTitle:{
        color: '#444444', 
        fontSize: '18px', 
        fontWeight: 500 
    },
    shippingAdress:{
      color: '#444444', 
      fontSize: '14px'
    },
    shippingAdressText:{
     fontWeight: 500 
    },
    spaceBetween:{
        display: 'flex',
        justifyContent: 'space-between' 
    },
    dividerLine:{
        color: '#444444', 
        fontSize: '14px', 
        maxWidth: '32px',
        width: '100%', 
        textAlign: 'center'
    },
    infoText:{
       color: '#444444', 
       fontSize: '14px', 
       fontWeight: 500, 
       maxWidth: '150px',
       width: '100%'
    },
    infoValue:{
        color: '#444444', 
        fontSize: '14px', 
        fontWeight: 500, 
        maxWidth: '150px',
        width: '100%'
     },
     rightBox:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        maxWidth: '500px',
        width: '100%' 
     },
     rightBoxInner:{
        display: 'flex', 
        flexDirection: 'column',
        gap: '12px',
        width: '100%'
     },
     orderDetails:{
        color: '#444444',
        fontSize: '18px',
        fontWeight: 500 
     },
     OtpInputBox: {
        marginTop: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& input': {
            width: '44px !important',
            height: '54px',
            fontSize: '20px',
            textDecoration: 'none',
            lineHeight: '30px',
            fontFamily: 'Poppins-Regular',
            color: '#000000',
            backgroundColor: '#ffffff',
            border: '1px solid #898989',
            borderRadius: '10px',
            '&:focus': {
                outline: 'none',
                border: '1px solid #898989',
            },
            '@media (max-width: 960px)': { 
                width: '40px !important',
                height: '48px',
            },
            '& + span': {
                display: 'none',
            },
            '&:not(:last-child)': {
                marginRight: '20px',
                '@media (max-width: 960px)': { 
                    marginRight: '10px',
                },
                '@media (max-width: 600px)': { 
                    marginRight: '7px',
                },
            },
        },
    },
    successTitle: {
        textAlign: 'center' as const,
        fontWeight: 400,
        color: '#555555',
        marginLeft:"10px",
        fontSize:"16px"
    },
    successImage:{
        width:"25px",
        height:"25px"
    }

    
    

    // Customizable Area End
}

export class SingleOrderStatus extends SingleOrderStatusController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderOrderShipmentType = () => {
        const { classes } = this.props;
        const { orderData } = this.state;
        return (
            <Box className={classes.spaceBetween}>
                <Typography className={classes.keyText}>Shipping method</Typography>
                <Typography className={classes.middleText}>-</Typography>
                <Typography className={classes.valueText}>{orderData.deliver_by === 'self_pickup' ? `Self-pick up` : `Delivery by flixo`}</Typography>
            </Box>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { drawerIsOpen, orderData, loading } = this.state;
        const { classes } = this.props;
        const orderStatus = orderData?.status
        const date = new Date(orderData.created_at);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
        let colorName = orderData?.catalogue_variant?.attributes.catalogue_variant_color?.name;


        return (
            <Paper className={classes.root}>
                <Loader loading={loading} />
                <div className="dashboard-outer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={undefined}
                            isOpen={drawerIsOpen}
                            activetabName="orders"
                            closeModal={this.closeSidebar}
                        />
                    </div>
                </div>
                <Box className={classes.orderStatusMainContainer} data-test-id="orderStatusMainContainer">
                {orderStatus === "declined" &&
                    <Box style={{display:"flex",justifyContent:"center",marginTop:"0.5em"}}>
                        <Typography className={classes.orderCanceledText}>
                        <img src={cancelTicked} alt="cancelTicked" width={26} height={26}/>
                        {configJSON.orderCanceledBySellerText}
                        </Typography>
                    </Box> 
                        }
                    <Box className={classes.titleBox}>
                        <Box className={classes.titleBoxInnerSection}>
                            <img src={backButton} className={classes.backButton} onClick={this.goBackOnOrderList} />
                            <Typography className={classes.titleTxt}>{configJSON.order}</Typography>
                        </Box>
                        <Box className={classes.topHeaderActions}>
                            <Box className={classes.iconsStyle}><img src={calendarIcon} /></Box>
                            <Box className={classes.iconsStyle}><img src={bellIcon} /></Box>
                            <Box className={classes.hamburgerIconStyle} onClick={this.closeSidebar}>
                                <img src={hamburgerIc} alt="hamburger" />
                            </Box>
                        </Box>
                    </Box>
                  
                    {(orderStatus && orderData) && (orderStatus !== configJSON.placed && orderStatus !== configJSON.declined && orderData.deliver_by !== "self_pickup") && 
                        <Box className={classes.switchContainer} data-test-id='switchContainer'>
                            <Box className={classes.switchContainerInnerSection}>
                                <Typography className={classes.switchLabels}>{configJSON.onHold}</Typography>
                                <StyledSwitch
                                    disabled={ orderStatus === configJSON.shipped}
                                    onChange={(event: any) => this.handleOnHoldSwitch(event)}
                                    checked={orderStatus === configJSON.on_hold}
                                    data-test-id="onHoldButton"
                                />
                            </Box>
                            <Box className={classes.switchContainerInnerSection}>
                                <Typography className={classes.switchLabels}>{configJSON.productShipped}</Typography>
                                <StyledSwitch
                                    data-test-id="shippedButton"
                                    disabled={orderStatus === configJSON.shipped}
                                    onChange={() => this.changeStatus("shipped")}
                                    checked={orderStatus === configJSON.shipped}
                                />
                            </Box>
                        </Box>
                    }
                    <Box className={classes.orderDetailsContainer} >
                        <Box className={classes.orderItemContainer}>
                            <Box className={classes.leftContainer}>
                                <img src={`${baseURL}${orderData.catalogue?.attributes.product_images[0].url}`} className={classes.productImage} />
                                <Box className={classes.leftContainerInner}>
                                    <Box className={classes.topContent}>
                                        <Box className={classes.topContentInner}>
                                            <Typography className={classes.productTitle}>{orderData.catalogue?.attributes.title}</Typography>
                                            {(orderStatus === configJSON.placed) &&
                                                <Box className={classes.statusBox} style={{ background: '#FFD6001A' }}>
                                                    <img src={imgShippingClock} />
                                                    <Typography className={classes.statusTxt}>{configJSON.pending}</Typography>
                                            </Box>}
                                            {orderStatus === configJSON.accepted &&
                                                <Box className={classes.statusBox} style={{ background: '#4BB5431A' }}>
                                                    <img src={imgReadyTruck} />
                                                    {orderData.deliver_by === "self_pickup" ?
                                                    <Typography className={classes.statusTxt}>{configJSON.readyTopickup}</Typography>:
                                                    <Typography className={classes.statusTxt}>{configJSON.readyToship}</Typography>}
                                                </Box>}
                                                {orderStatus ===  configJSON.shipped &&
                                                <Box className={classes.statusBox} style={{ background: '#0066FF1A' }}>
                                                    <img src={imgShipedtruck} />
                                                    <Typography className={classes.statusTxt}>{configJSON.shipped}</Typography>
                                                </Box>}
                                            {orderStatus ===  configJSON.on_hold  &&
                                                <Box className={classes.statusBox} style={{ background: '#9D9D9D1A' }} >
                                                    <img src={imgOnHoldClock} />
                                                    <Typography className={classes.statusTxt}>{configJSON.onhold}</Typography>
                                                </Box>}
                                           
                                            {orderStatus ===  configJSON.declined  &&
                                                <Box className={classes.statusBox} style={{ background: '#FF00001A' }}>
                                                    <img src={imgCancelOrder} />
                                                    <Typography className={classes.statusTxt}>{configJSON.cancelled}</Typography>
                                                </Box>}

                                            {orderStatus === configJSON.deliveredStatus &&
                                                <Box className={classes.statusBox} style={{ background: '#4BB5431A' }}>
                                                    <Typography className={classes.statusTxt}>{configJSON.deliveredTxt}</Typography>
                                                </Box>}
                                            
                                        </Box>
                                        <Typography className={classes.orderNumber}>{orderData.order_number}</Typography>
                                    </Box>
                                    <Box  className={classes.bottomContent}>
                                        <Typography className={classes.buyersInfoTitle}>
                                            {configJSON.buyersInfo}
                                        </Typography>
                                        <Typography className={classes.shippingAdress}>
                                            <span className={classes.shippingAdressText}>{configJSON.shippingAdress}</span> {orderData.shipment_details ? `${orderData.shipment_details?.flat_no}, ${orderData.shipment_details?.city}, ${orderData.shipment_details?.state}, ${orderData.shipment_details?.country}, ${orderData.shipment_details?.pin_code}` : ''}
                                        </Typography>
                                        <Box className={classes.spaceBetween}>
                                            <Typography className={classes.infoText}>{configJSON.name}</Typography>
                                            <Typography className={classes.dividerLine}>-</Typography>
                                            <Typography className={classes.infoValue}>{orderData.shipment_details?.name}</Typography>
                                        </Box>
                                        <Box  className={classes.spaceBetween} >
                                            <Typography className={classes.infoText}>{configJSON.phoneNumber}</Typography>
                                            <Typography className={classes.dividerLine}>-</Typography>
                                            <Typography className={classes.infoValue}>{orderData.shipment_details?.mobile}</Typography>
                                        </Box>
                                    </Box>
                                    <Box data-test-id="self-pickup-box" style={{ marginTop: "25px", display: (orderData.deliver_by === "self_pickup" && orderData.status === "accepted") ? "block" : "none" }}>
                                        <Typography className={classes.productTitle}>Verify OTP</Typography>
                                        <Typography className={classes.orderNumber}>Enter OTP provided by Buyer</Typography>
                                        <Box className={classes.OtpInputBox}>
                                            <OtpInput
                                                data-test-id="mobileOtpVerify"
                                                value={this.state.buyerOtpVerify}
                                                numInputs={4}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props}/>} 
                                                onChange={this.handleBuyerOtpChange} />
                                            {this.state.successMessages ? <p style={{ color: 'green' }}>{this.state.successMessages}</p> : <p style={{ color: 'red' }}>{this.state.buyerOtpValidation}</p>}
                                        </Box>
                                        <Button
                                            className={classes.acceptBtn}
                                            onClick={this.verifyBuyersOtp}
                                            data-test-id='acceptBtn'>
                                            Verify
                                        </Button>
                                    </Box>
                                    <SuccessDialog
                                        data-test-id="sucessMessage"
                                        open={this.state.openSuccesspopup}
                                        successTitle="Success!"
                                        successMessage="The item has been successfully picked up by the buyer. The OTP has been validated, and the order is now complete."
                                        closeFn={this.closeSuccessmessagepopup} />
                                    <MessageModalWeb
                                        data-test-id="messageModal"
                                        displayPopup={this.state.isMessageModal}
                                        closeErrorPopup={this.handleClosemessageModal}
                                        errorMessage={this.state.modalMessage}
                                    />
                                </Box>
                                
                            </Box>
                            <Divider orientation="vertical" className={classes.verticalDivider} />
                            <Divider className={classes.horizontolDivider} />
                            <Box className={classes.rightBox}>
                                <Box   className={classes.rightBoxInner}>
                                    <Typography className={classes.orderDetails}>
                                        {configJSON.orderDetails}
                                    </Typography>
                                    <Box className={classes.spaceBetween}>
                                        <Typography className={classes.keyText}>{configJSON.orderId}</Typography>
                                        <Typography className={classes.middleText}>-</Typography>
                                        <Typography className={classes.valueText}>{orderData.order_number}</Typography>
                                    </Box>
                                    <Box className={classes.spaceBetween}>
                                        <Typography className={classes.keyText}>{configJSON.orderPlacedOn}</Typography>
                                        <Typography className={classes.middleText}>-</Typography>
                                        <Typography className={classes.valueText}>{formattedDate}</Typography>
                                    </Box>
                                    <Box className={classes.spaceBetween}>
                                        <Typography className={classes.keyText}>{configJSON.productName}</Typography>
                                        <Typography className={classes.middleText}>-</Typography>
                                        <Typography className={classes.valueText}>{orderData.catalogue?.attributes.title}</Typography>
                                    </Box>
                                    <Box className={classes.spaceBetween}>
                                        <Typography className={classes.keyText}>{configJSON.color}</Typography>
                                        <Typography className={classes.middleText}>-</Typography>
                                        <Box className={classes.valueText}>
                                            <Box className={classes.colorBox}>
                                                <span>{colorName && GetColorName(colorName)}</span>
                                                <Typography style={{ backgroundColor: `${colorName}`, width: "30%", padding: "14px", borderRadius: "8px" }}></Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.spaceBetween}>
                                        <Typography className={classes.keyText}>{configJSON.quantity}</Typography>
                                        <Typography className={classes.middleText}>-</Typography>
                                        <Typography className={classes.valueText}>{orderData.quantity}</Typography>
                                    </Box>
                                    <Box className={classes.spaceBetween}>
                                        <Typography className={classes.keyText}>{configJSON.paymentStatus}</Typography>
                                        <Typography className={classes.middleText}>-</Typography>
                                        <Typography className={classes.valueText}>{configJSON.pending}</Typography>
                                    </Box>
                                    {this.renderOrderShipmentType()}
                                    <Box className={classes.spaceBetween}>
                                        <Typography className={classes.keyText}><span style={{ fontWeight: 600 }}>{configJSON.amount} </span>({orderData.quantity}x{orderData.unit_price})</Typography>
                                        <Typography className={classes.middleText}>-</Typography>
                                        <Typography className={classes.valueText} style={{ fontWeight: 600 }}>₹ {orderData.total_price}</Typography>
                                    </Box>
                                </Box>
                                {(orderStatus === configJSON.placed)&&
                                    <Button 
                                    className={classes.acceptBtn} 
                                    onClick={() => this.changeStatus("accept")}
                                    data-test-id='acceptBtn'>
                                        {configJSON.acceptOrder}
                                    </Button>}
                                {this.shouldShowButton(orderStatus, orderData) && <Button
                                    className={classes.cancelOrderBtn}
                                    data-testid='declineBtn'
                                    onClick={() => this.changeStatus("decline")}
                                    disabled={orderStatus === configJSON.shipped}>
                                    {configJSON.cancelOrder}
                                </Button>}
                                {orderStatus === "delivered" && orderData.deliver_by === "self_pickup" &&
                                <Box style={{display:"flex", marginTop:"20px"}}>
                                <img src={sucessImageIcon} alt="Success Vector" className={classes.successImage} />
                                <Typography className={classes.successTitle}>This order has been successfully picked up by the buyer.</Typography>
                            </Box>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper >
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(SingleOrderStatus);
// Customizable Area End
