import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { verifyUPI, VerifyVPA } from 'bhimupijs';
import { ChangeEvent, createRef } from 'react';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import SwiperCore, { Navigation } from 'swiper';
import { getLocation } from "../../../components/src/utils";
import { removeStorageData, setStorageData } from "framework/src/Utilities";
export const baseURL = require("../../../framework/src/config.js").baseURL;
interface NotificationAttributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string | null;
  is_read: boolean;
  read_at: string;
  notificationable_id: number;
  notificationable_type: string;
  created_at: string;
  updated_at: string;
  account_id: number;
}

interface Notification {
  id: string;
  type: string;
  attributes: NotificationAttributes;
}

interface CountryAttributes {
  name: string;
  emoji_flag: string;
  country_code: string;
}

interface CountryData {
  id: string;
  type: string;
  attributes: CountryAttributes;
}

interface ApiPayloadType{
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type?: string;
}

interface LikeDataAttributes {
  likeable_id: number;
  likeable_type: string;
  like_by_id: number;
  created_at: string;
  updated_at: string;
}

interface LikeData {
  id: string;
  type: string;
  attributes: LikeDataAttributes;
}

export interface LikeResponse {
  data: LikeData;
  message: string;
}

export interface StoryUploaders {
  type: string;
  id: string;
  attributes: StoryAttributes;
}

interface StoryAttributes {
  title: string;
  id: number;
  duration: number; 
  description: string;
  location: string | null; 
  seen: boolean;
  activated: boolean;
  color: string | null; 
  updated_at: string; 
  created_at: string; 
  media: StoryMedia;
  creator_details: CreatorDetails;
  liked: boolean;
  duration_with_seconds: string;
}

interface CreatorDetails {
  full_name: string;
  id: number;
  profile_url: string | null;
}

interface StoryMedia {
  filename: string;
  id: number;
  type: string;
  url: string;
}
interface SubPostCommentData {
  id: string;
  type: string;
  attributes: SubPostCommentAttributes;
}
interface SubPostCommentAttributes {
  id: number;
  account_id: number;
  comment_text: string;
  comment: Comment;
  created_at: string;
  updated_at: string;
}
export interface SubPostCommentResponse {
  data: SubPostCommentData;
}
export interface PostDataPayloadType {
  id: string;
  isTagList: boolean;
  attributes: PostAttributesPayload;
  type: string;
}
interface PostAttributesPayload {
  product_detail?:	Product[]
  tag_list_details: TagDetail[];
  id: number;
  description: string | null;
  name: string | null;
  location: string | null;
  body: string;
  active_message: boolean;
  account_id: number;
  created_at: string;
  updated_at: string;
  creator_details: CreatorDataPayload;
  images_and_videos: ImgVideoPayload[];
  comment_count: number;
  is_following_seller: boolean;
  like_count: number;
  is_liked: boolean;
  last_liked_by?: LastLikedBy;
}
interface LastLikedBy {
  id: number;
  user_name: string;
  profile_url: string;
};
interface ImgVideoPayload {
  filename: string;
  id: number;
  type: string;
  url: string;
}

interface CreatorDataPayload {
  full_name: string;
  profile_url: string | null;
  id: number;
}

interface TagDetail {
  profile_photo: string;
  name: string;
  id: number;
  profile_url: string;
  user_name: string;
}

interface ProductImage {
  url: string;
  id: number;
}

interface Product {
  name: string;
  id: number;
  price: number;
  description: string;
  product_images: ProductImage[];
  variants: ProductVariant[];
}
interface CommentDetails {
  other_like: boolean;
  profile_photo: string;
  comment: string;
  sub_post_comments: CommentDetails[];
  like_count: number;
  replies_count: number;
  self_like: boolean;
  created_at: string;
  full_name: string | null;
  sub_comment_id: string;
}
interface Comment {
  attributes: CommentAttributes;
  type: string;
  id: string;
}

interface ProductVariant {
  id: number;
  catalogue_variant_color_id: number;
  catalogue_id: number;
  price: string;
  catalogue_variant_size_id: number;
  on_sale: boolean;
  stock_qty: number;
  discount_price: string | null;
  sale_price: string;
  breadth: number | null;
  length: number | null;
  created_at: string;
  height: number | null;
  block_qty: number | null;
  updated_at: string;
}
export interface CommentAttributes {
  details: CommentDetails;
}
export interface CommentsResponse {
  message: string;
  data: Comment[];
  meta: Meta;
}
   interface Comment {
     attributes: CommentAttributes;
     type: string;
     id: string;
   }
   interface CommentDetails {
     other_like: boolean;
     profile_photo: string;
     comment: string;
     sub_post_comments: CommentDetails[];
     like_count: number;
     replies_count: number;
     self_like: boolean;
     created_at: string;
     full_name: string | null;
     sub_comment_id: string;
   }
   interface Meta {
     message: string;
     current_user: CurrentUser;
   }
   interface CurrentUser {
     id: number;
     profile_photo: string;
     full_name: string;
     user_name: string;
   }
   export interface Accounts {
    x: number;
    y: number;
    id: number;
    user_name: string;
    full_name: string;
    profile_photo: string;
    role: {
      name: string;
    };
  }
interface AllStoryResponse {
  data: Array<StoryUploaders>
}

export interface StoreAddress {
  id: number;
  address_line_1: string;
  address_line_2: string;
  pin_code: number;
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
  account_id: number;
  created_at: string;
  updated_at: string;
};

export interface SnackBarIn {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

SwiperCore.use([Navigation]);
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen?: boolean;
  closeModal?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpen: boolean;
  tabValue: number;
  tabValueSeller: number;
  openModalFollowing: boolean;
  openModalFollowers: boolean;
  cashModal: boolean;
  shippingModal: boolean;
  orderModal: boolean;
  itemModal: boolean;
  bargainModal: boolean;
  followersModel:boolean;
  shipProvider: string
  sameDropOff: boolean

  openTunedBox: boolean
  openModalChangeEmail: boolean
  openModalEmailOtp: boolean
  openModalMobile: boolean
  openModalMobileOtp: boolean
  openModalEnterChangePassword: boolean
  openModalPasswordSuccessfull: boolean
  openModalDisableAccout: boolean
  openModalDeleteAccount: boolean
  openModalBankingDetail: boolean
  openModalBankOtp: boolean
  openModalBankingSuccessfull: boolean
  openModalPickUpAddress: boolean
  openModalPickUpSuccessfull: boolean
  openModalDropOffAddress: boolean
  openModalDropOffSuccessfull: boolean
  openModalBusinessLegal: boolean
  openModalBusinessLegalSuccessfull: boolean
  showCurrenPassword: boolean
  showNewPassword: boolean
  showReNewPassword: boolean
  accountListOpen: boolean
  accountListAnchorEl: any
  openModalBio: boolean
  errorModal: boolean
  openLiveModal:boolean
  emailError: string
  email: string
  emailCodeAgain: string
  otpStateEmail: string
  otpError: string
  otp: string
  countrySelectedValue: any
  dataSource: CountryData[]
  mobileNumber: string
  mobileNumberCodeAgain: string
  phoneError: string
  otpStateMobile: string
  currentPassword: string
  currentPasswordCopy: string
  currentPwdError: string
  newPassword: string
  newPasswordCopy: string
  reNewPassword: string
  newPwdError: string
  reNewPasswordError: string
  password: string
  pwdError: string
  
  bankAccountNumber: string
  bankAccountNumberNew: string
  bankAccNumError: string
  bankAccountHolderName: string
  bankAccountHolderNameNew: string
  bankAccHoldNameError: string
  bankName: string
  bankNameNew: string
  bankNameError: string
  bankAccountIfscCode: string
  bankAccountIfscCodeNew: string
  bankAccountIfscCodeError: string
  otpStateBankAccount: string
  pickUpaddressline1: string
  pickUpaddressline1Error: string
  pickUpaddressline2: string
  pickUpaddressline2Error: string
  pickUppincode: string
  pickUppincodeError: string
  pickUpcity: string
  pickUpcityError: string
  pickUpstate: any
  pickUpstateError: string
  pickUpcountry: string
  pickUpcountryError: string
  dropOffaddressline1: string
  dropOffaddressline1Error: string
  dropOffaddressline2: string
  dropOffaddressline2Error: string
  dropOffpincode: string
  dropOffpincodeError: string
  dropOffcity: string
  dropOffcityError: string
  dropOffstate: any
  dropOffstateError: string
  dropOffcountry: string
  dropOffcountryError: string
  businessLegalDocumentFileName: string;
  businessLegalDocumentFileSize: string;
  businessLegalDocumentFile: any;
  businessLegalDocumentError: string;
  businessLegalGstPan: string
  businessLegalGstPanError: string
  businessLegalEmail: string
  businessLegalEmailError: string
  businessLegalBusinessLicence: string
  businessLegalBusinessLicenceError: string
  profileBio: string
  profileBioError: string
  businessDocuments: any[];
  sellerProfileData: any
  sellerAddress: any
  sellerDropOffAddress: any
  sellerBankDetail: any
  sellerBusinessDetail: any
  sellerLiveStreamData: any
  sellerTopProduct: any
  bankList: any
  
  updateEmailToken: string
  updateMobileToken: string
  sellerProfileImgFile:any
  sellerBackgroundImgFile: any
  pickUpAddressId: number
  dropoffAddressId: number
  imageError: string
  
  countryList: any;
  pickUpStateList: any;
  pickUpCityList: any;
  dropOffStateList: any;
  dropOffCityList: any;
  bankVerificationToken:string
  isNotificationBoxOpen: boolean,
  allNotifications: Notification[],
  selectedTab:number,
  openAddUPIModal:boolean
  upiId:string,
  upiError:string,
  openprofileQRgenerationModal:boolean,
  tooltipCopyUrl:boolean
  instaModalIndex: number;
  instaModal: boolean;
  userStory : Array<StoryUploaders>;
  allStoryHeader: Array<StoryUploaders>;
  storyMuted: boolean;
  goToSlide: string;
  currentSlide: number;
  currentStoryIndex: number;
  storyPaused: boolean;
  sellerPostsData: any;
  listCommentData: CommentsResponse;
  clickReply: number[];
  setReplyId: string;
  isSuggestionVisible: boolean,
  suggestionsTagList: Accounts[],
  suggestionPosition: string;
  showCommentBox: string;
  setPostCommentId: string;
  addComment: string;
  profileImage: string;
  storeTypedata: string | null;
  feedPageNo: number;
  sellerFollowersData:any
  BuyerDataId: number;
  shitchProfile: boolean;
  buyerData: {
    account: {
      user_name: string
    }
  }
  isOtpSent: boolean;
  isEmailUpdate: boolean;
  isMobileUpdate: boolean;
  latitude: string;
  longitude: string;
  errorMessage: string | null;
  loading: boolean;
  permissionStatus: string;
  storeAddress: StoreAddress[];
  snackBarStates: SnackBarIn;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableSellerProfilesControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBuyerProfileApiId: string = ""
  countryCodeApiCallId: string = ""
  sellerProfileDataApiId: string = ""
  updateSellerBankingDataApiId: string= ""
  updateSellerBusinessDataApiId: string= ""
  liveStreamApiId: string = ""
  topProducApiId: string = ""
  updateEmail: string= ""
  updateEmailTokenVerify: string= ""
  updateMobile: string= ""
  resendBankOtpId:string=""
  updateMobileTokenVerify: string= ""
  updateBankVerificationToken: string= ""
  updateSellerProfileImage: string= ""
  updateSellerBakgroundImage: string= ""
  updateProfileBio: string= ""
  updateBankDetail: string= ""
  updateSellerPassword: string= ""
  updateSellerProfileAddressApiId: string = ""
  updateSellerProfileDropOffAddressApiId: string = ""
  updateShipProviderApiId: string = ""
  updateSameDropOffApiId: string= ""
  bankListApiId: string = ""
  getCountryListApiCallId: string = ''
  getPickUpStateListApiCallId: string = ''
  getPickUpCityListApiCallId: string = ''
  getDropOffStateListApiCallId: string = ''
  getDropOffCityListApiCallId: string = ''
  getAllNotificationId: string = ''
  readAllNotificationId:string = ''
  postUPIcallId:string =''
  postProfileQrId:string =''
  upiIdText: React.RefObject<HTMLSpanElement>;
  qrRef: any;
  likeStoryCallId:string = "";
  allUserStoriesCallId: string = "";
  viewStoryCallId: string = "";
  swiperInstance: SwiperCore | null = null;
  sellerpostsId: string = "";
  postToggleLikeUnlikeApiCallID : string = "";
  likeCommentApiCallID: string = "";
  likeReplyCommentApiCallID: string = "";
  getPostCommentApiCallID: string = "";
  getTagUserListApiCallId: string = "";
  postCommentApiCallID: string = "";
  postCommentOnCommentApiCallID: string = "";
  getSellerPostDataApiCallId: string = "";
  getSellerFollowersApiID: string = ""
  removeSellerFollowersApiID: string = ""
  allStoreApiID: string = "";
  addStoreAddressApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpen: true,
      tabValue: 0,
      tabValueSeller: 0,
      openModalFollowing: false,
      openModalFollowers: false,
      cashModal: false,
      shippingModal: false,
      orderModal: false,
      itemModal: false,
      bargainModal: false,
      followersModel: false,
      shipProvider: '',
      sameDropOff: false,
      openLiveModal: false,
      openTunedBox: false,
      openModalChangeEmail: false,
      openModalEmailOtp: false,
      openModalMobile: false,
      openModalMobileOtp: false,
      openModalEnterChangePassword: false,
      openModalPasswordSuccessfull: false,
      openModalDisableAccout: false,
      openModalDeleteAccount: false,
      openModalBankingDetail: false,
      openModalBankOtp: false,
      openModalBankingSuccessfull: false,
      openModalPickUpAddress: false,
      openModalPickUpSuccessfull: false,
      openModalDropOffAddress: false,
      openModalDropOffSuccessfull: false,
      openModalBusinessLegal: false,
      openModalBusinessLegalSuccessfull: false,
      showCurrenPassword: false,
      showNewPassword: false,
      showReNewPassword: false,
      accountListOpen: false,
      accountListAnchorEl: null,
      openModalBio: false,
      errorModal: false,
      businessDocuments: [],
      emailError: '',
      email: '',
      emailCodeAgain: '',
      otpStateEmail: '',
      otpError: '',
      otp: '',
      countrySelectedValue: {},
      dataSource: [],
      mobileNumber: '',
      mobileNumberCodeAgain: '',
      phoneError: '',
      otpStateMobile: '',
      currentPassword: '',
      currentPasswordCopy: '',
      currentPwdError: '',
      newPassword: '',
      newPasswordCopy: '',
      reNewPassword: '',
      newPwdError: '',
      reNewPasswordError: '',
      password: '',
      pwdError: '',
      
      bankAccountNumber: '',
      bankAccountNumberNew: '',
      bankAccNumError: '',
      bankAccountHolderName: '',
      bankAccountHolderNameNew: '',
      bankAccHoldNameError: '',
      bankName: '',
      bankNameNew: '',
      bankNameError: '',
      bankAccountIfscCode: '',
      bankAccountIfscCodeNew: '',
      bankAccountIfscCodeError: '',
      otpStateBankAccount: '',
      pickUpaddressline1: '',
      pickUpaddressline1Error: '',
      pickUpaddressline2: '',
      pickUpaddressline2Error: '',
      pickUppincode: '',
      pickUppincodeError: '',
      pickUpcity: '',
      pickUpcityError: '',
      pickUpstate: {},
      pickUpstateError: '',
      pickUpcountry: '',
      pickUpcountryError: '',
      dropOffaddressline1: '',
      dropOffaddressline1Error: '',
      dropOffaddressline2: '',
      dropOffaddressline2Error: '',
      dropOffpincode: '',
      dropOffpincodeError: '',
      dropOffcity: '',
      dropOffcityError: '',
      dropOffstate: {},
      dropOffstateError: '',
      dropOffcountry: '',
      dropOffcountryError: '',
      businessLegalDocumentFileName: '',
      businessLegalDocumentFileSize: '',
      businessLegalDocumentFile: null,
      businessLegalDocumentError: '',
      businessLegalGstPan: '',
      businessLegalGstPanError: '',
      businessLegalEmail: '',
      businessLegalEmailError: '',
      businessLegalBusinessLicence: '',
      businessLegalBusinessLicenceError: '',
      profileBio: '',
      profileBioError: '',
      sellerProfileData: null,
      sellerAddress: null,
      sellerDropOffAddress: null,
      sellerBankDetail: null,
      sellerBusinessDetail: null,
      sellerLiveStreamData: [],
      sellerTopProduct: [],
      bankList: [],
      updateEmailToken: '',
      updateMobileToken: '',
      sellerProfileImgFile:null,
      sellerBackgroundImgFile: null,
      pickUpAddressId: 0,
      dropoffAddressId: 0,
      imageError: '',
      
      countryList: [],
      pickUpStateList: [],
      pickUpCityList: [],
      dropOffStateList: [],
      dropOffCityList: [],
      bankVerificationToken:"",
      isNotificationBoxOpen: false,
      allNotifications: [],
      selectedTab:1,
      openAddUPIModal:false,
      upiId:"",
      upiError:"",
      openprofileQRgenerationModal:false,
      allStoryHeader: [],
      instaModal: false,
      goToSlide: "",
      userStory : [],
      storyPaused: false,
      storyMuted: false,
      currentSlide: 0,
      currentStoryIndex: 0,
      instaModalIndex: 0,
      tooltipCopyUrl:false,
      sellerPostsData: [],
      listCommentData: {
        data: [],
        meta: {
          message: "",
          current_user: {
            id: 0,
            profile_photo: "",
            full_name: "",
            user_name: ""
          }
        },
        message: ""
      },
      clickReply: [],
      setReplyId:"",
      isSuggestionVisible: false,
      suggestionsTagList: [],
      suggestionPosition: "",
      showCommentBox: '',
      setPostCommentId:'',
      addComment: '',
      profileImage: '',
      storeTypedata:'',
      feedPageNo:1,
      sellerFollowersData:[],
      BuyerDataId: 0,
      shitchProfile: false,
      buyerData: {
        account: {
          user_name: ""
        }

      },
      isOtpSent: false,
      isEmailUpdate: false,
      isMobileUpdate: false,
      latitude: '',
      longitude: '',
      errorMessage: null,
      loading: false,
      permissionStatus: '',
      storeAddress: [],
      snackBarStates: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    this.upiIdText = createRef();
    this.qrRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId){

        case this.countryCodeApiCallId:
          this.setState({
            dataSource: responseJson.data,
            countrySelectedValue: this.countryCodesSelected(responseJson.data)[0]
          });
          break;
        case this.sellerProfileDataApiId:
          this.setSellerProfileData(responseJson)
          this.getSellerFollowers()
          break;
        case this.updateSellerBusinessDataApiId:
          this.handleUpdateSellerBusinessDataApiId(responseJson)
          break;
        case this.liveStreamApiId:

          this.setLiveStreamData(responseJson);
          break;
        case this.topProducApiId:
          this.setTopProductData(responseJson);
          break;
        case this.updateEmail:
          this.setUpdateEmailData(responseJson);
          break;
        case this.updateEmailTokenVerify:
          this.checkEmailOtpVerify(responseJson);
          break;
        case this.updateMobile:
          this.setUpdateMobiledata(responseJson);
          break;
        case this.updateMobileTokenVerify:
          this.checkMobileOtpVerify(responseJson);
          break;
        case this.updateBankVerificationToken:
            this.checkBankOtpVerify(responseJson);
          break;
        case this.updateSellerProfileImage:
          this.checkProfileUpdate(responseJson);
          break;
        case this.updateSellerBakgroundImage:
          this.checkProfileUpdate(responseJson);
          break;
        case this.updateProfileBio:
          this.checkProfileUpdate(responseJson);
          break;
        case this.updateBankDetail:
          this.checkProfileBankUpdate(responseJson);
          break;
        case this.updateSellerPassword:
          this.checkPasswordUpdate(responseJson);
          break
        case this.updateSellerProfileAddressApiId:
          this.checkUpdatePickupAddress(responseJson);
          break;
        case this.updateSellerProfileDropOffAddressApiId:
          this.checkUpdateDropoffAddress(responseJson);
          break;
        case this.updateShipProviderApiId:
          this.checkUpdateShipmentType(responseJson);
          break;
        case this.updateSameDropOffApiId:
          this.checkUpdateSameDropOff(responseJson);
          break;
        case this.bankListApiId:
          this.setBankList(responseJson);
          break;
        case this.getCountryListApiCallId:
          this.setCountryList(responseJson);
          break;
        case this.getPickUpStateListApiCallId:
          this.setPickUpStateList(responseJson);
          this.getPickUpCityList();
          break;
        case this.getPickUpCityListApiCallId:
          this.setPickUpCityList(responseJson);
          break;
        case this.getDropOffStateListApiCallId:
          this.setDropOffStateList(responseJson);
          this.getDropOffCityList();
          break;
        case this.getDropOffCityListApiCallId:
          this.setDropOffCityList(responseJson);
          break;
          case this.postUPIcallId:
            this.handlePostUPIcallId(responseJson);
            break;
        case this.sellerpostsId:
          this.setPostsData(responseJson);
          break;
        case this.getPostCommentApiCallID:
          this.getListingDataSuccessCallback(responseJson);
          this.getSellerpostData()
          break;
        case this.postCommentApiCallID:
          this.getAddDataSuccessCallback(responseJson);
          break;
        case this.postCommentOnCommentApiCallID:
          this.postCommentOnCommentDataSuccessCallback(responseJson);
          break;
        case this.likeCommentApiCallID:
          this.likeCommentCall(responseJson);
          break;
        case this.likeReplyCommentApiCallID:
          this.likeReplyCommentCall(responseJson);
          break;
        case this.getSellerFollowersApiID:
          this.setSellerFollwersdata(responseJson);
          break;
        case this.removeSellerFollowersApiID:
          this.getSellerFollowers();
          break;
        case this.postToggleLikeUnlikeApiCallID :
          this.getSellerpostData();
          break;
        case this.getAllNotificationId:
            this.setState({
                allNotifications: responseJson.data
            });
            break;
          case this.postProfileQrId:
          this.getSellerProfileData()
            break;
          case this.allStoreApiID:
          this.getAllStoreSuccessCall(responseJson);
          break;
          case this.addStoreAddressApiID:
          this.getUpdateStoreSuccessCall(responseJson)
          break;
        default:
          break;
      }
      this.handleOtherApis(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.makeRemoteRequest();
    this.getSellerProfileData();
    this.getSellerLiveStreamData();
    this.getSellerTopProductData();
    this.getSellerBankListData();
    this.getCountryList();
    this.getAllNotification();
    this.getSellerpostData()
    this.getAllStore();
    const storeType = localStorage.getItem("storeType")
    let profileImage = await getStorageData("profileImage")
    this.setState({ profileImage: profileImage, storeTypedata: storeType })
    this.getTagUserListData("") 
    this.postUPIdetails()
    const buyerData = await getStorageData("user_id");
    this.setState({
      BuyerDataId: JSON.parse(buyerData),
    })
    
    const buyerToken = await getStorageData("buerLoginToken");
    const buyerLoginData = await getStorageData("Buyer_Data");
    const authToken = await getStorageData("authToken");

    buyerToken && buyerLoginData && authToken && this.setState({
      buyerData: JSON.parse(buyerLoginData)
    })
  }

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: ` ${item.id}`,
      value: item.attributes.country_code
    }));
  };

  countryCodesSelected = (data: any) => {
    let a = data.filter((item: any) => (

      item.attributes.country_code == "91"))

    return a.map((item: any) => ({
      label: ` ${item.id}`,
      value: item.attributes.country_code
    }));
  };

  toggleDrawer = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  OpenAccountList = (event: any) => {
    this.setState({
      accountListAnchorEl: event.currentTarget,
      accountListOpen: !this.state.accountListOpen
    })
  }

  CloseAccountList = () => {
    this.setState({ 
      accountListAnchorEl: null,
      accountListOpen: !this.state.accountListOpen
     });
  };

  handleChange = (event: any, newValue: any) => {
    this.setState({ tabValue: newValue })
    if (newValue === 3) {
      this.openStayTunedBox();
      this.setState({ tabValue: 1 })
    }
    if(newValue === 1){
      this.getSellerLiveStreamData()
    }
  }

  handleChangeSeller = (event: any, newValue: any) => {
    this.setState({ tabValueSeller: newValue })
  }

  handleOpenModalFollowing: any = () => {
    this.setState({ openModalFollowing: true });
  };

  handleCloseModalFollowing: any = () => {
    this.setState({ openModalFollowing: false });
  };

  handleOpenModalFollowers: any = () => {
    this.setState({ openModalFollowers: true });
  };

  handleCloseModalFollowers: any = () => {
    this.setState({ openModalFollowers: false });
  };

  handleOpenModalCash: any = () => {
    this.setState({ cashModal: true });
  };

  handleCloseModalCash: any = () => {
    this.setState({ cashModal: false });
  };

  handleOpenModalShipping: any = () => {
    this.setState({ shippingModal: true });
  };

  handleCloseModalShipping: any = () => {
    this.setState({ shippingModal: false });
  };

  handleOpenModalOrder: any = () => {
    this.setState({ orderModal: true });
  };

  handleCloseModalOrder: any = () => {
    this.setState({ orderModal: false });
  };

  handleOpenModalItem: any = () => {
    this.setState({ itemModal: true });
  };

  handleCloseModalItem: any = () => {
    this.setState({ itemModal: false });
  };

  handleOpenModalBargain: any = () => {
    this.setState({ bargainModal: true });
  };

  handleCloseModalBargain: any = () => {
    this.setState({ bargainModal: false });
  };

  handleOpenModalDisableAccount: any = () => {
    this.setState({ openModalDisableAccout: true });
  }

  handleCloseModalDisableAccount: any = () => {
    this.setState({ openModalDisableAccout: false });
  }

  openStayTunedBox = () => {
    this.setState({
      openTunedBox: true
    });
  }
  closeTunedBox = () => {
    this.setState({
      openTunedBox: false
    })
  }

  handleViewAsBuyer = () => {
    this.setState({shitchProfile: !this.state.shitchProfile})
  };
  
  handleAddAccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountRegistration");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  switchToBuyer = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleOpenModalBankingDetail: any = () => {
    if (this.state.sellerBankDetail) {
      this.setState({
        openModalBankingDetail: true,
        bankAccountNumber: this.state.sellerBankDetail.bank_account_number,
        bankAccountHolderName: this.state.sellerBankDetail.account_holder_name,
        bankName: this.state.sellerBankDetail.bank_name,
        bankAccountIfscCode: this.state.sellerBankDetail.ifsc_code
      });
    } else {
      removeStorageData('newStep');
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "BankingDetails");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }

  handleCloseModalBankingDetail: any = () => {
    this.setState({
      openModalBankingDetail: false,
      bankAccountNumber: '',
      bankAccNumError: '',
      bankAccountHolderName: '',
      bankAccHoldNameError: '',
      bankName: '',
      bankNameError: '',
      bankAccountIfscCode: '',
      bankAccountIfscCodeError: '',

    });
  }

  handleOpenModalBankOtp: any = () => {
    this.setState({ openModalBankOtp: true });
  }

  handleCloseModalBankOtp: any = () => {
    this.setState({
      openModalBankOtp: false,
      otpStateBankAccount: '',
      otpError: ''
    });
  }

  handleOpenModalBio: any = () => {
    this.setState({ openModalBio: true });
  }

  handleCloseModalBio: any = () => {
    this.setState({
      openModalBio: false,
    });
  }

  errorModalClose = () => {
    this.setState({ errorModal: !this.state.errorModal })
  }

  handleOpenModalBankingSuccessfull: any = () => {
    this.setState({ openModalBankingSuccessfull: true });
  }

  handleCloseModalBankingSuccessfull: any = () => {
    this.setState({ openModalBankingSuccessfull: false });
  }

  handleMenuItemClick = (event: any) => {
    this.setState({
      bankName: event.target.value,
      bankNameNew: event.target.value
    });
  }

  handleOpenModalPickUpAddress: any = () => {
    this.setState({
      openModalPickUpAddress: true,
    })
    if (this.state.sellerAddress) {
      this.setState({
        pickUpaddressline1: this.state.sellerAddress.attributes.address_line_1,
        pickUpaddressline2: this.state.sellerAddress.attributes.address_line_2,
        pickUppincode: this.state.sellerAddress.attributes.pin_code,
        pickUpcity: this.state.sellerAddress.attributes.city,
        pickUpstate: this.state.sellerAddress.attributes.state,
        pickUpcountry: this.state.sellerAddress.attributes.country,
        pickUpAddressId: this.state.sellerAddress.id
      }, () => this.getPickUpStateList());
    }
  }

  handleCloseModalPickUpAddress: any = () => {
    this.setState({
      openModalPickUpAddress: false,
      pickUpaddressline1: '',
      pickUpaddressline2: '',
      pickUppincode: '',
      pickUpcity: '',
      pickUpstate: '',
      pickUpcountry: '',
      pickUpaddressline1Error: '',
      pickUpaddressline2Error: '',
      pickUppincodeError: '',
      pickUpcityError: '',
      pickUpstateError: '',
      pickUpcountryError: ''
    });
  }

  handleOpenModalPickUpSuccessfull: any = () => {
    this.setState({ openModalPickUpSuccessfull: true });
  }

  handleCloseModalPickUpSuccessfull: any = () => {
    this.setState({ openModalPickUpSuccessfull: false });
    if (!this.state.sameDropOff) {
      this.handleOpenModalDropOffAddress();
    }
  }

  handleOpenModalDropOffAddress: any = () => {
    this.setState({
      openModalDropOffAddress: true,
    })
    if (this.state.sellerDropOffAddress) {
      this.setState({
        dropOffaddressline1: this.state.sellerDropOffAddress.attributes.address_line_1,
        dropOffaddressline2: this.state.sellerDropOffAddress.attributes.address_line_2,
        dropOffpincode: this.state.sellerDropOffAddress.attributes.pin_code,
        dropOffcity: this.state.sellerDropOffAddress.attributes.city,
        dropOffstate: this.state.sellerDropOffAddress.attributes.state,
        dropOffcountry: this.state.sellerDropOffAddress.attributes.country,
        dropoffAddressId: this.state.sellerDropOffAddress.id
      }, () => this.getDropOffStateList());
    }
  }

  handleCloseModalDropOffAddress: any = () => {
    this.setState({
      openModalDropOffAddress: false,
      dropOffaddressline1: '',
      dropOffaddressline2: '',
      dropOffpincode: '',
      dropOffcity: '',
      dropOffstate: '',
      dropOffcountry: '',
      dropOffaddressline1Error: '',
      dropOffaddressline2Error: '',
      dropOffpincodeError: '',
      dropOffcityError: '',
      dropOffstateError: '',
      dropOffcountryError: ''
    });
  }

  handleOpenModalDropOffSuccessfull: any = () => {
    this.setState({ openModalDropOffSuccessfull: true });
  }

  handleCloseModalDropOffSuccessfull: any = () => {
    this.setState({ openModalDropOffSuccessfull: false });
  }

  handleShipProvider: any = (event: any) => {
    this.setState({ shipProvider: event.target.value });
    this.updateShipProvider();
  };

  handleDroppOffSwitch: any = (event: any) => {
    this.setState({ sameDropOff: event.target.checked });
    this.updateDropOffSwitch();
  };

  setError = (stateKey: keyof S, errorMsg: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: errorMsg,
    }));
  };

  handleOpenModalChangeEmail: any = () => {
    this.setState({ openModalChangeEmail: true });
  }

  handleCloseModalChangeEmail: any = () => {
    this.setState({
      openModalChangeEmail: false,
      email: '',
      emailError: ''
    });
  }

  handleOpenModalEmailOtp: any = () => {
    this.handleCloseModalChangeEmail();
    this.setState({ openModalEmailOtp: true });
  }

  handleCloseModalEmailOtp: any = () => {
    this.setState({
      openModalEmailOtp: false,
      otpStateEmail: '',
      otpError: ''
    });
  }

  handleOpenModalMobile: any = () => {
    this.setState({ openModalMobile: true });
  }

  handleCloseModalMobile: any = () => {
    this.setState({
      openModalMobile: false,
      mobileNumber: '',
      phoneError: ''
    });
  }

  closeEmailSuccessModal = () => {
    this.setState({
      isEmailUpdate: !this.state.isEmailUpdate,
      mobileNumber: '',
      phoneError: ''
    });
  };

  closeMobileSuccessModal = () => {
    this.setState({
      isMobileUpdate: !this.state.isMobileUpdate,
      mobileNumber: '',
      phoneError: ''
    });
  };

  handleOpenModalMobileOtp: any = () => {
    this.handleCloseModalMobile();
    this.setState({ openModalMobileOtp: true });
  }

  handleCloseModalMobileOtp: any = () => {
    this.setState({
      openModalMobileOtp: false,
      otpStateMobile: '',
      otpError: ''
    });
  }

  handleOpenModalEnterChangePassword: any = () => {
    this.setState({ openModalEnterChangePassword: true });
  }

  handleCloseModalEnterChangePassword: any = () => {
    this.setState({
      openModalEnterChangePassword: false,
      currentPassword: '',
      currentPwdError: '',
      showCurrenPassword: false,
      newPassword: '',
      newPwdError: '',
      showNewPassword: false,
      reNewPassword: '',
      reNewPasswordError: '',
      showReNewPassword: false
    });
  }

  handleCurrenPasswordShow: any = () => {
    this.setState({ showCurrenPassword: !this.state.showCurrenPassword })
  }

  handleNewPasswordShow: any = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  handleReNewPasswordShow: any = () => {
    this.setState({ showReNewPassword: !this.state.showReNewPassword })
  }

  handleOpenModalPasswordSuccessfull: any = () => {
    this.setState({ openModalPasswordSuccessfull: true });
  }

  handleCloseModalPasswordSuccessfull: any = () => {
    this.setState({ openModalPasswordSuccessfull: false });
  }

  handleOpenModalDeleteAccount: any = () => {
    this.setState({ openModalDeleteAccount: true });
  }

  handleCloseModalDeleteAccount: any = () => {
    this.setState({
      openModalDeleteAccount: false,
      password: '',
      pwdError: ''
    });
  }

  handleOpenModalBusinessLegal: any = () => {
    if (this.state.sellerBusinessDetail) {
      this.setState({
        openModalBusinessLegal: true,
        businessLegalGstPan: this.state.sellerBusinessDetail.attributes.gst_pan_no,
        businessLegalBusinessLicence: this.state.sellerBusinessDetail.attributes.business_license_number,
        businessLegalEmail: this.state.sellerProfileData.attributes.email
      });
    } else{
      setStorageData('newStep', 2 );
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "BankingDetails");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }

  handleCloseModalBusinessLegal: any = () => {
    this.setState({
      openModalBusinessLegal: false,
      businessLegalGstPan: '',
      businessLegalGstPanError: '',
      businessLegalEmail: '',
      businessLegalEmailError: '',
      businessLegalBusinessLicence: '',
      businessLegalBusinessLicenceError: '',
      businessLegalDocumentError: '',
      businessLegalDocumentFileSize: ""
    });
  }

  handleOpenModalBusinessLegalSuccessfull: any = () => {
    this.setState({ openModalBusinessLegalSuccessfull: true });
  }

  handleCloseModalBusinessLegalSuccessfull: any = () => {
    this.setState({ openModalBusinessLegalSuccessfull: false });
  }

  handleChangeEmail = (event: any) => {
    const { value } = event.target;
    this.setState({
      email: value,
      emailCodeAgain: value
    })
    this.setState({ email: value })
  };

  handleChangeCountry = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    this.setState({ countrySelectedValue: { value: event.target.value as string } });
  }

  handleOTPStateEmail = (val: any) => {
    this.setState({ otpStateEmail: val })
    this.setState({ otp: val.trim() })
  }

  handleMobileNumberChange = (event: any) => {
    const { value } = event.target;
    const limitedValue = value.slice(0, 10);
    this.setState({ 
      mobileNumber: limitedValue.trim(),
      mobileNumberCodeAgain: limitedValue.trim(),
    })
  }

  handleOTPStateMobile = (val: any) => {
    this.setState({ otpStateMobile: val })
    this.setState({ otp: val.trim() })
  }

  handleCurrentPasswordChangePassword = (event: any) => {
    const { value } = event.target;
    this.setState({
      currentPassword: value,
      currentPasswordCopy: value
    })
    this.setState({ currentPassword: value })
  }

  handleNewPasswordChangePassword = (event: any) => {
    const { value } = event.target;
    this.setState({
      newPassword: value,
      newPasswordCopy: value
    })
    this.setState({ newPassword: value })
  }

  handleReEnterNewPasswordChangePassword = (event: any) => {
    const { value } = event.target;
    this.setState({ reNewPassword: value })
    this.setState({ reNewPassword: value })
  }

  handlePasswordDeleteAccount = (event: any) => {
    const { value } = event.target;
    this.setState({ password: value })
    this.setState({ password: value })
  }

  handleChangeBankAccountNumber = (event: any) => {
    const { value } = event.target;
    this.setState({ 
      bankAccountNumber: value.trim() ,
      bankAccountNumberNew: value.trim() 
    })
  }

  handleChangeBankAccountHolderName = (event: any) => {
    const { value } = event.target;
    const regex = /^[A-Za-z ]*$/;
    if (regex.test(value)) {
    this.setState({ 
      bankAccountHolderName: value,
      bankAccountHolderNameNew: value
    })
  }
  }

  handleChangeBankAccountIfscCode = (event: any) => {
    const { value } = event.target;
    this.setState({
      bankAccountIfscCode: value.trim(),
      bankAccountIfscCodeNew: value.trim()
    })
  }

  handleOTPStateBankAccount = (val: any) => {
    this.setState({ otpStateBankAccount: val })
    this.setState({ otp: val.toString().trim() })
  }

  handleChangePickUpAddressline1 = (event: any) => {
    const { value } = event.target;
    this.setState({ pickUpaddressline1: value.trim() })
  };

  handleChangePickUpAddressline2 = (event: any) => {
    const { value } = event.target;
    this.setState({ pickUpaddressline2: value.trim() })
  };

  handleChangePickUpPincode = (event: any) => {
    const { value } = event.target;
    this.setState({ pickUppincode: value })
  };

  handleChangeDropOffAddressline1 = (event: any) => {
    const { value } = event.target;
    this.setState({ dropOffaddressline1: value.trim() })
  };

  handleChangeDropOffAddressline2 = (event: any) => {
    const { value } = event.target;
    this.setState({ dropOffaddressline2: value.trim() })
  };

  handleChangeDropOffPincode = (event: any) => {
    const { value } = event.target;
    this.setState({ dropOffpincode: value })
  };

  handleChangeProfileBio = (event: any) => {
    const { value } = event.target;
    this.setState({ profileBio: value.trim() })
  };

  handleBusinessLegalFile = (event: any) => {
    const selectedFile: File = event.target.files[0];

    const validImageTypes = ['image/jpeg', 'image/png','image/jpg'];

  if (!validImageTypes.includes(selectedFile.type)) {
    this.setState({
      businessLegalDocumentError: configJSON.validImages
    });
    return;
  }

    if (selectedFile instanceof Blob) {
      const reader = new FileReader();
      event.persist();
      reader.onload = () => {
        this.setState({
          businessLegalDocumentFile: selectedFile,
          businessLegalDocumentFileName: event.target.files[0].name,
          businessLegalDocumentFileSize: event.target.files[0].size,
          businessLegalDocumentError: ''
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  handleBusinessLegalFileClear: any = (event: any) => {
    this.setState({
      businessLegalDocumentFileName: "",
      businessLegalDocumentFileSize: ""
    })
    this.setError("businessLegalDocumentError", configJSON.businessLegalDocumentError);
    return false;
  }

  handleChangeBusinessLegalGstPan = (event: any) => {
    const { value } = event.target;
    this.setState({ businessLegalGstPan: value.trim() })
  };

  handleChangeBusinessLegalEmail = (event: any) => {
    const { value } = event.target;
    this.setState({ businessLegalEmail: value })
  };

  handleChangeBusinessLegalBusinessLicence = (event: any) => {
    const { value } = event.target;
    this.setState({ businessLegalBusinessLicence: value.trim() })
  };

  handlePickUpCountryChange = (event: any, newValue: any) => {
    this.setState({
      pickUpcountry: newValue,
      pickUpstate: '',
      pickUpStateList: [],
      pickUpcity: '',
      pickUpCityList: [],
    }, () => {
      newValue && this.getPickUpStateList();
    });
    this.setState({ pickUpcountryError: "" });
  }

  handlePickUpStateChange = (event: any, newValue: any) => {
    this.setState({
      pickUpstate: newValue,
      pickUpcity: '',
      pickUpCityList: []
    }, () => {
      newValue && this.getPickUpCityList();
    });
    this.setState({ pickUpstateError: "" });
  }

  handlePickUpCityChange = (event: any, newValue: any) => {
    this.setState({
      pickUpcity: newValue
    });
    this.setState({ pickUpcityError: "" });
  }

  handleDropOffCountryChange = (event: any, newValue: any) => {
    this.setState({
      dropOffcountry: newValue,
      dropOffstate: '',
      dropOffStateList: [],
      dropOffcity: '',
      dropOffCityList: [],
    }, () => {
      newValue && this.getDropOffStateList();
    });
    this.setState({ pickUpcountryError: "" });
  }

  handleDropOffStateChange = (event: any, newValue: any) => {
    this.setState({
      dropOffstate: newValue,
      dropOffcity: '',
      dropOffCityList: []
    }, () => {
      newValue && this.getDropOffCityList();
    });
    this.setState({ dropOffstateError: "" });
  }

  handleDropOffCityChange = (event: any, newValue: any) => {
    this.setState({
      dropOffcity: newValue
    });
    this.setState({ dropOffcityError: "" });
  }

  validateEmail = (): boolean => {
    const { email } = this.state;
    if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      this.setError("emailError", configJSON.emailError);
      return false;
    }
    this.setState({ emailError: "" });
    return true;
  }

  validateOtp = (): boolean => {
    const { otp } = this.state;
    const otp_new = otp;
    if (!otp_new || otp_new.length !== 6) {
      this.setError("otpError", configJSON.otpError);
      return false;
    }
    this.setState({ otpError: "" });
    return true;
  }

  validatePhone = (): boolean => {
    const { mobileNumber } = this.state;
    if (!mobileNumber || mobileNumber.length !== 10) {
      this.setError("phoneError", configJSON.phoneError);
      return false;
    }
    this.setState({ phoneError: "" });
    return true;
  };

  validateCurrentPassword = (): boolean => {
    const { currentPassword } = this.state;
    if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(currentPassword)) {
      this.setError("currentPwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ currentPwdError: "" });
    return true;
  };

  validateNewPassword = (): boolean => {
    const { newPassword, reNewPassword } = this.state;
    if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(newPassword)) {
      this.setError("newPwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ newPwdError: "" });

    if (newPassword !== reNewPassword) {
      this.setError("reNewPasswordError", configJSON.confirmPwdError);
      return false;
    }
    this.setState({ reNewPasswordError: "" });

    return true;
  };

  validatePassword = (): boolean => {
    const { password } = this.state;
    if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(password)) {
      this.setError("pwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ pwdError: "" });
    return true;
  };

  validateBankAccountNumber = (): boolean => {
    const { bankAccountNumber } = this.state;
    if (!bankAccountNumber || bankAccountNumber.length < 9) {
      this.setError("bankAccNumError", configJSON.bankAccNumError);
      return false;
    }
    this.setState({ bankAccNumError: "" });
    return true;
  }

  validateBankAccountHolderName = (): boolean => {
    const { bankAccountHolderName } = this.state;
    if (!bankAccountHolderName.trim() || bankAccountHolderName.trim().length < 3) {
      this.setError("bankAccHoldNameError", configJSON.bankAccNumError);
      return false;
    }
    this.setState({ bankAccHoldNameError: "" });
    return true;
  }

  validateBankName = (): boolean => {
    const { bankName } = this.state;
    if (!bankName) {
      this.setError("bankNameError", configJSON.bankNameError);
      return false;
    }
    this.setState({ bankNameError: "" });
    return true;
  }

  validateBankAccountIfscCode = (): boolean => {
    const { bankAccountIfscCode } = this.state;
    if (!bankAccountIfscCode || bankAccountIfscCode.length < 3) {
      this.setError("bankAccountIfscCodeError", configJSON.bankAccountIfscCodeError);
      return false;
    }
    this.setState({ bankAccountIfscCodeError: "" });
    return true;
  }

  validatePickUpAddressline1 = (): boolean => {
    const { pickUpaddressline1 } = this.state;
    if (!pickUpaddressline1 || pickUpaddressline1.length < 3) {
      this.setError("pickUpaddressline1Error", configJSON.pickUpaddressline1Error);
      return false;
    }
    this.setState({ pickUpaddressline1Error: "" });
    return true;
  }

  validatePickUpAddressline2 = (): boolean => {
    const { pickUpaddressline2 } = this.state;
    if (!pickUpaddressline2 || pickUpaddressline2.length < 3) {
      this.setError("pickUpaddressline2Error", configJSON.pickUpaddressline2Error);
      return false;
    }
    this.setState({ pickUpaddressline2Error: "" });
    return true;
  }

  validatePickUpPincode = (): boolean => {
    const { pickUppincode } = this.state;
    if (!pickUppincode || pickUppincode.length < 5) {
      this.setError("pickUppincodeError", configJSON.pickUppincodeError);
      return false;
    }
    this.setState({ pickUppincodeError: "" });
    return true;
  }

  validateDropOffAddressline1 = (): boolean => {
    const { dropOffaddressline1 } = this.state;
    if (!dropOffaddressline1 || dropOffaddressline1.length < 3) {
      this.setError("dropOffaddressline1Error", configJSON.dropOffaddressline1Error);
      return false;
    }
    this.setState({ dropOffaddressline1Error: "" });
    return true;
  }

  validateDropOffAddressline2 = (): boolean => {
    const { dropOffaddressline2 } = this.state;
    if (!dropOffaddressline2 || dropOffaddressline2.length < 3) {
      this.setError("dropOffaddressline2Error", configJSON.dropOffaddressline2Error);
      return false;
    }
    this.setState({ dropOffaddressline2Error: "" });
    return true;
  }

  validateDropOffPincode = (): boolean => {
    const { dropOffpincode } = this.state;
    if (!dropOffpincode || dropOffpincode.length < 5) {
      this.setError("dropOffpincodeError", configJSON.dropOffpincodeError);
      return false;
    }
    this.setState({ dropOffpincodeError: "" });
    return true;
  }

  validateBusinessLegalGstPan = (): boolean => {
    const { businessLegalGstPan } = this.state;
    if (!businessLegalGstPan || businessLegalGstPan.length < 3) {
      this.setError("businessLegalGstPanError", configJSON.businessLegalGstPanError);
      return false;
    }
    this.setState({ businessLegalGstPanError: "" });
    return true;
  }

  validateBusinessLegalEmail = (): boolean => {
    const { businessLegalEmail } = this.state;
    if (!businessLegalEmail || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(businessLegalEmail)) {
      this.setError("businessLegalEmailError", configJSON.businessLegalEmailError);
      return false;
    }
    this.setState({ businessLegalEmailError: "" });
    return true;
  }

  validateBusinessLegalBusinessLicence = (): boolean => {
    const { businessLegalBusinessLicence } = this.state;
    if (!businessLegalBusinessLicence || businessLegalBusinessLicence.length < 3) {
      this.setError("businessLegalBusinessLicenceError", configJSON.businessLegalBusinessLicenceError);
      return false;
    }
    this.setState({ businessLegalBusinessLicenceError: "" });
    return true;
  }

  validateProfileBio = (): boolean => {
    const { profileBio } = this.state;
    if (!profileBio || profileBio.length < 3) {
      this.setError("profileBioError", configJSON.profileBioError);
      return false;
    }
    this.setState({ profileBioError: "" });
    return true;
  }

  ValidatePickUpCountry = () => {
    const { pickUpcountry } = this.state;
    if (!pickUpcountry) {
      this.setError("pickUpcountryError", configJSON.pickUpcountryError);
      return false;
    } else {
      this.setState({ pickUpcountryError: "" });
      return true;
    }
  }

  ValidatePickUpState = () => {
    const { pickUpstate } = this.state;
    if (!pickUpstate) {
      this.setError("pickUpstateError", configJSON.pickUpstateError);
      return false;
    } else {
      this.setState({ pickUpstateError: "" });
      return true;
    }
  }

  ValidatePickUpCity = () => {
    const { pickUpcity } = this.state;
    if (!pickUpcity) {
      this.setError("pickUpcityError", configJSON.pickUpcityError);
      return false;
    } else {
      this.setState({ pickUpcityError: "" });
      return true;
    }
  }

  ValidateDropOffCountry = () => {
    const { dropOffcountry } = this.state;
    if (!dropOffcountry) {
      this.setError("dropOffcountryError", configJSON.dropOffcountryError);
      return false;
    } else {
      this.setState({ dropOffcountryError: "" });
      return true;
    }
  }

  ValidateDropOffState = () => {
    const { dropOffstate } = this.state;
    if (!dropOffstate) {
      this.setError("dropOffstateError", configJSON.dropOffstateError);
      return false;
    } else {
      this.setState({ dropOffstateError: "" });
      return true;
    }
  }

  ValidateDropOffCity = () => {
    const { dropOffcity } = this.state;
    if (!dropOffcity) {
      this.setError("dropOffcityError", configJSON.dropOffcityError);
      return false;
    } else {
      this.setState({ dropOffcityError: "" });
      return true;
    }
  }

  checkValidationModalEmail = () => {
    let email = this.validateEmail();
    if (email) {
      this.updateSellerEmail()
    }
  }

  checkValidationOtpEmail = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.sellerUpdateEmailTokenVerify()
    }
  }

  checkValidationModalMobile = () => {
    let phone = this.validatePhone();
    if (phone) {
      this.updateSellerMobile();
    }
  }

  checkValidationOtpMobile = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.sellerUpdateMobileTokenVerify()
    }
  }

  checkValidationPasswordChange = () => {
    let currentPassword = this.validateCurrentPassword();
    let newPassword = this.validateNewPassword();
    if (currentPassword && newPassword) {
      this.updateSellerChangePassword();
    }
  }

  checkValidationOtpBank = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.handleOtpverifyForBankDetails()
    }
  }
  handleOtpverifyForBankDetails = () => {
    const header = {
      "token": this.state.bankVerificationToken,
      "Content-Type": "application/json"
    };

    const pinData = {
      "pin": this.state.otpStateBankAccount,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateBankVerificationToken = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(pinData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyBankDetailsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}
  checkValidationBankDetails = () => {
    let bankAccountNumber = this.validateBankAccountNumber();
    let bankAccountHolderName = this.validateBankAccountHolderName();
    let bankName = this.validateBankName();
    let bankAccountIfscCode = this.validateBankAccountIfscCode();
    if (bankAccountNumber && bankAccountHolderName && bankName && bankAccountIfscCode) {
      this.updateSellerBankDetails();
    }
  }

  checkValidationPickUpAddressChange = () => {
    let addressline1 = this.validatePickUpAddressline1();
    let addressline2 = this.validatePickUpAddressline2();
    let pincode = this.validatePickUpPincode();
    let city = this.ValidatePickUpCity();
    let state = this.ValidatePickUpState();
    let country = this.ValidatePickUpCountry();
    if (addressline1 && addressline2 && pincode && city && state && country) {
      this.updateSellerProfileAddressData();
    }
  }

  checkValidationDropOffAddressChange = () => {
    let addressline1 = this.validateDropOffAddressline1();
    let addressline2 = this.validateDropOffAddressline2();
    let pincode = this.validateDropOffPincode();
    let city = this.ValidateDropOffCity();
    let state = this.ValidateDropOffState();
    let country = this.ValidateDropOffCountry();
    if (addressline1 && addressline2 && pincode && city && state && country) {
      this.updateSellerProfileDropOffAddressData();
    }
  }

  checkValidationBusinessLegalChange = () => {
    let businessLegalGstPan = this.validateBusinessLegalGstPan();
    let businessLegalEmail = this.validateBusinessLegalEmail();
    let businessLegalBusinessLicence = this.validateBusinessLegalBusinessLicence();
    if (this.state.businessLegalDocumentFileName === '' && this.state.businessLegalDocumentFileSize === '') {
      this.setError("businessLegalDocumentError", configJSON.businessLegalDocumentError);
      return false;
    }
    if (businessLegalGstPan && businessLegalEmail && businessLegalBusinessLicence && this.state.businessLegalDocumentFileName !== '' && this.state.businessLegalDocumentFileSize !== '') {
      this.updateSellerBusinessLegalDetails();
    }
  }

  checkValidationBioChange = () => {
    let profileBio = this.validateProfileBio();
    if (this.state.profileBio === '') {
      this.setError("profileBioError", configJSON.profileBioError);
      return false;
    }
    if (profileBio) {
      this.updateSellerProfileBio()
    }
  }

  setSellerProfileData = (responseJson: any) => {
    if (responseJson && responseJson.account && responseJson.account.data) {
      this.setState({
        sellerProfileData: responseJson.account.data,
        businessLegalEmail: responseJson.account.data.attributes.email,
        profileBio: responseJson.account.data.attributes.my_bio,
        sameDropOff: responseJson.account.data.attributes.same_drop_off,
        shipProvider: responseJson.account.data.attributes.shipment_type,
      },
    ()=>{
      this.fetchAllUserStories();
    })
    }
    if (responseJson && responseJson.address && responseJson.address.data) {
      this.setState({
        sellerAddress: responseJson.address.data,
        pickUpaddressline1: responseJson.address.data.attributes.address_line_1,
        pickUpaddressline2: responseJson.address.data.attributes.address_line_2,
        pickUppincode: responseJson.address.data.attributes.pin_code,
        pickUpcity: responseJson.address.data.attributes.city,
        pickUpstate: responseJson.address.data.attributes.state,
        pickUpcountry: responseJson.address.data.attributes.country,
        shipProvider: responseJson.address.data.attributes.shipment_type,
      })
    }
    if (responseJson && responseJson.drop_off_address && responseJson.drop_off_address.data) {
      this.setState({
        sellerDropOffAddress: responseJson.drop_off_address.data,
        dropOffaddressline1: responseJson.drop_off_address.data.attributes.address_line_1,
        dropOffaddressline2: responseJson.drop_off_address.data.attributes.address_line_2,
        dropOffpincode: responseJson.drop_off_address.data.attributes.pin_code,
        dropOffcity: responseJson.drop_off_address.data.attributes.city,
        dropOffstate: responseJson.drop_off_address.data.attributes.state,
        dropOffcountry: responseJson.drop_off_address.data.attributes.country,
      });
    }
    if (responseJson && responseJson.bank_detail) {
      this.setState({
        sellerBankDetail: responseJson.bank_detail,
        bankAccountNumber: responseJson.bank_detail.bank_account_number,
        bankAccountHolderName: responseJson.bank_detail.account_holder_name,
        bankName: responseJson.bank_detail.bank_name,
        bankAccountIfscCode: responseJson.bank_detail.ifsc_code,
        bankAccountNumberNew: responseJson.bank_detail.bank_account_number,
        bankAccountHolderNameNew: responseJson.bank_detail.account_holder_name,
        bankNameNew: responseJson.bank_detail.bank_name,
        bankAccountIfscCodeNew: responseJson.bank_detail.ifsc_code
      })
    }
    if (responseJson && responseJson.business_detail && responseJson.business_detail.data) {      
      this.setState({
        sellerBusinessDetail: responseJson.business_detail.data,
        businessLegalGstPan: responseJson.business_detail.data.attributes.gst_pan_no,
        businessLegalBusinessLicence: responseJson.business_detail.data.attributes.business_license_number,
        businessLegalDocumentFileName: responseJson.business_detail.data.attributes.business_license_name,
        businessDocuments: responseJson.business_detail.data.attributes.attachments
      
      })
    }
  }

  setLiveStreamData = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        sellerLiveStreamData: responseJson.data
      })
    }    
  }

  setPostsData = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        sellerPostsData: responseJson.data
      })
    }
  }

  setTopProductData = (responseJson: any) => {
    if (responseJson && responseJson.catalogues && responseJson.catalogues.data) {
      this.setState({
        sellerTopProduct: responseJson.catalogues.data
      })
    }
  }
  setSellerFollwersdata= (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        sellerFollowersData: responseJson.data
      })
    }
  }
  setUpdateEmailData = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("emailError", configJSON.updateEmailError);
    } 
    if (responseJson && responseJson.meta) {
      this.setState({ updateEmailToken: responseJson.meta.token, isOtpSent: true });
      this.handleCloseModalChangeEmail();
      this.handleOpenModalEmailOtp();
      setTimeout(() => {
        this.setState({ isOtpSent: false });
      }, 4000);
    } else {
      this.setError("emailError", configJSON.updateEmailError);
    }
  }

  checkEmailOtpVerify = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("otpError", configJSON.updateOTPError);
    }
    if (responseJson && responseJson.meta) {
      if (responseJson.meta.token) {
        this.setState({ isEmailUpdate: true });
        this.handleCloseModalEmailOtp();
        this.getSellerProfileData();
      }
    }
  }

  setUpdateMobiledata = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("phoneError", configJSON.updateMobileError);
    }
    if (responseJson && responseJson.meta) {
      this.setState({ updateMobileToken: responseJson.meta.token, isOtpSent: true });
      this.handleCloseModalMobile();
      this.handleOpenModalMobileOtp();
      setTimeout(() => {
        this.setState({ isOtpSent: false });
      }, 4000);
    } else {
      this.setError("phoneError", "Mobile number has already been taken");
    }
  }

  checkMobileOtpVerify = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("otpError", configJSON.updateOTPError);
    }
    if (responseJson && responseJson.meta) {
      if (responseJson.meta.token) {
        this.setState({isMobileUpdate: true})
        this.handleCloseModalMobileOtp();
        this.getSellerProfileData();
      }
    }
  }
  checkBankOtpVerify= (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("otpError", configJSON.updateOTPError);
    }
    if (responseJson && responseJson.meta) {
      if (responseJson.meta.token) {
        this.handleCloseModalBankOtp();
        this.handleOpenModalBankingSuccessfull();
        this.getSellerProfileData();
      }
    }
  }

  checkProfileUpdate = (responseJson: any) => {
    if (responseJson && responseJson.data) {
        this.getSellerProfileData();
        this.handleCloseModalBio();
    } else {
      if (responseJson.errors[0]['Profile photo']) {
        this.setState({
          imageError: responseJson.errors[0]['Profile photo'],
          errorModal: true
        })
      } else {
        this.setState({
          imageError: responseJson.errors[0]['Background photo'],
          errorModal: true
        })
      }
    }
  }

  checkProfileBankUpdate = (responseJson: any) => {
    if (responseJson && responseJson.bank_detail) {
      this.setState({ bankVerificationToken: responseJson.meta.token});
        this.handleOpenModalBankOtp()
        this.handleCloseModalBankingDetail()
    }
    if (responseJson?.errors && responseJson?.errors?.ifsc_code) {
      this.setState({ bankAccountIfscCodeError: responseJson?.errors.ifsc_code[0] });
    }
  }

  checkPasswordUpdate = (responseJson: any) => {
    if (responseJson.message == "Password changed successfully") {
      this.handleCloseModalEnterChangePassword();
      this.handleOpenModalPasswordSuccessfull();
    } else {
      this.setState({ reNewPasswordError: responseJson.message })
    }
  }

  checkUpdatePickupAddress = (responseJson: any) => {
    if (responseJson?.meta?.message == "Address Updated Successfully" || responseJson?.meta?.message == "Address Created Successfully") {
      this.handleCloseModalPickUpAddress();
      this.handleOpenModalPickUpSuccessfull();
      this.getSellerProfileData();
    }
    if (responseJson && responseJson.errors) {
      this.setState({
        pickUpcountryError: configJSON.AddressError
      })
    }
  }

  setBankList = (responseJson: any) => {
    if (responseJson && responseJson.banks) {
      this.setState({
        bankList: responseJson.banks
      })
    }
  }

  setCountryList = (responseJson: any) => {
    if (responseJson.countries) {
        this.setState({
            countryList: Object.entries(responseJson.countries).map(([slug, name]) => ({ slug, name })),
        });
    }
  }

  setPickUpStateList = (responseJson: any) => {
    if (responseJson.states) {
      this.setState({
        pickUpStateList: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })),
        pickUpstate: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })).find((state: any) => state.name === this.state.pickUpstate)?.name
      });
    }
  }

  setPickUpCityList = (responseJson: any) => {
    if (responseJson.cities) {
      this.setState({
        pickUpCityList: responseJson.cities,
        pickUpcity: this.state.pickUpcity
      });
    }
  }

  setDropOffStateList = (responseJson: any) => {
    if (responseJson.states) {
      this.setState({
        dropOffStateList: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })),
        dropOffstate: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })).find((state: any) => state.name === this.state.dropOffstate)?.name
      });
    }
  }

  setDropOffCityList = (responseJson: any) => {
    if (responseJson.cities) {
      this.setState({
        dropOffCityList: responseJson.cities,
        dropOffcity: this.state.dropOffcity
      }, ()=>{console.log('tttt', this.state.dropOffcity)});
    }
  }

  checkUpdateDropoffAddress = (responseJson: any) => {
    if (responseJson?.meta?.message == "Successfully Created Pickup Address") {
      this.handleCloseModalDropOffAddress();
      this.handleOpenModalDropOffSuccessfull();
      this.getSellerProfileData();
    } else {
      this.setState({
        pickUpcountryError: configJSON.AddressError
      });
    }
  }

  checkUpdateShipmentType = (responseJson: any) => {
    if (responseJson.message == "Shipment type updated successfully.") {
      this.getSellerProfileData();
    }
  }

  checkUpdateSameDropOff = (responseJson: any) => {
    if (responseJson.meta.message == "Successfully updated the same drop off address value.") {
      this.getSellerProfileData();
    }
  }

  handleUpdateSellerBusinessDataApiId = (responseJson: any) => {
    if (responseJson && responseJson.business_detail) {
      this.handleCloseModalBusinessLegal();
      this.handleOpenModalBusinessLegalSuccessfull();
      this.getSellerProfileData();
    }
  }

  handleSellerProfileImage = (event: any) => {
    const selectedFile: File = event.target.files[0];
    if (selectedFile) {
      this.setState({
        sellerProfileImgFile: selectedFile
      }, () => {
        this.updateSellerProfileImg()
      });
    }
  }

  handleSellerBackgroundImage = (event: any) => {
    const selectedFile: File = event.target.files[0];
    if (selectedFile) {
      this.setState({
        sellerBackgroundImgFile: selectedFile
      }, () => {
        this.updateSellerBackgroundImg()
      });
    }
  }

  makeRemoteRequest: any = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerProfileData: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.sellerProfileDataApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerProfileDataAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateSellerBusinessLegalDetails: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };
      const formData = new FormData()
      formData.append("business_detail[gst_pan_no]", this.state.businessLegalGstPan)
      formData.append("business_detail[business_license_number]", this.state.businessLegalBusinessLicence)
      formData.append("business_detail[business_license]", this.state.businessLegalDocumentFile)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateSellerBusinessDataApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerUpdateBusinessDataAPIEndpoint
      );

      if (this.state.sellerBusinessDetail) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putApiMothod
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
      }

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  
  getSellerLiveStreamData: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.liveStreamApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerLiveStreamAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }    
  }

  getSellerTopProductData: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.topProducApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerTopProductAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateSellerEmail: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };
      const formData = new FormData()
      formData.append("updated_email", this.state.emailCodeAgain)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateEmail = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerUpdateProfileAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  sellerUpdateEmailTokenVerify: any = () => {
    const header = {
      "token": this.state.updateEmailToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEmailTokenVerify = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateEmailAPIEndPoint + `?pin=${this.state.otpStateEmail}&update=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateSellerMobile: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };
      const formData = new FormData()
      formData.append("updated_mobile_number", this.state.countrySelectedValue.value+this.state.mobileNumberCodeAgain)
      formData.append("country_code", this.state.countrySelectedValue.value)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateMobile = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerUpdateProfileAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  resendBankOtp = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };
    

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.resendBankOtpId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resendBankOtp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  sellerUpdateMobileTokenVerify: any = () => {
      const header = {
        "token": this.state.updateMobileToken,
        "Content-Type": "application/json"
      };

      const pinData = {
        "pin": this.state.otpStateMobile,
        "update": true
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateMobileTokenVerify = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(pinData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateMobileAPIndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateSellerProfileImg: any = async () => {
    if (this.state.sellerProfileImgFile) {
      const sellertoken = await getStorageData("singupLogin");

      const header = {
        "token": sellertoken,
      };

      const formData = new FormData()
      formData.append("profile_photo", this.state.sellerProfileImgFile)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateSellerProfileImage = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerUpdateProfileAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }

  updateSellerBackgroundImg: any = async () => {
    if (this.state.sellerBackgroundImgFile) {
      const sellertoken = await getStorageData("singupLogin");

      const header = {
        "token": sellertoken,
      };

      const formData = new FormData()
      formData.append("background_photo", this.state.sellerBackgroundImgFile)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateSellerBakgroundImage = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerUpdateProfileAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateSellerProfileBio: any = async () => {
    const sellertoken = await getStorageData("singupLogin");

    const header = {
      "token": sellertoken,
    };

    const formData = new FormData()
    formData.append("my_bio", this.state.profileBio)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileBio = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellerUpdateProfileAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateSellerBankDetails: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
        "Content-Type": 'application/json'
      };
      const bankData = {
        "bank_account_number": this.state.bankAccountNumberNew,
        "ifsc_code": this.state.bankAccountIfscCodeNew,
        "account_holder_name": this.state.bankAccountHolderNameNew,
        "bank_name": this.state.bankNameNew
      }
      const apiMethod = this.state.sellerBankDetail===null ? "POST":"PUT"
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateBankDetail = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bankData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerUpdateBankDataAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        apiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateSellerChangePassword: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
        "Content-Type": 'application/json'
      };
      const passwordData = {
        "current_password": this.state.currentPasswordCopy,
        "new_password": this.state.newPasswordCopy
    }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateSellerPassword = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(passwordData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePasswordAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateSellerProfileAddressData: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "Content-Type": 'application/json',
        "token": sellertoken,
      };
      const address = {
        "id": this.state.pickUpAddressId,
        "address": {
          "address_line_1": this.state.pickUpaddressline1,
          "address_line_2": this.state.pickUpaddressline2,
          "pin_code": this.state.pickUppincode,
          "city": this.state.pickUpcity,
          "state": this.state.pickUpstate,
          "country": this.state.pickUpcountry
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateSellerProfileAddressApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(address)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.buyerProfileAddressAPIEndpoint
      );

      if (this.state.sellerAddress) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putApiMothod
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
      }

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateSellerProfileDropOffAddressData: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "Content-Type": 'application/json',
        "token": sellertoken,
      };
      const address = {
        "id": this.state.dropoffAddressId,
        "address": {
          "address_line_1": this.state.dropOffaddressline1,
          "address_line_2": this.state.dropOffaddressline2,
          "pin_code": this.state.dropOffpincode,
          "city": this.state.dropOffcity,
          "state": this.state.dropOffstate,
          "country": this.state.dropOffcountry
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateSellerProfileDropOffAddressApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(address)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateDropOffAddressAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateShipProvider: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "Content-Type": 'application/json',
        "token": sellertoken,
      };
      const shipProvider = {
          "shipment_type": this.state.shipProvider
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateShipProviderApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(shipProvider)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateShipmentTypeAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateDropOffSwitch: any = async () => {
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "Content-Type": 'application/json',
        "token": sellertoken,
      };
      const sameDropOffAddress = {
          "same_drop_off": this.state.sameDropOff
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateSameDropOffApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(sameDropOffAddress)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateSameDroppOffSwitchAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getSellerBankListData: any = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bankListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryList = () => {
    const header = { "Content-Type": "application/json"
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryList);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPickUpStateList = () => {
    const { pickUpcountry, countryList } = this.state;
    const header = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPickUpStateListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.stateList}?country_code=${countryList.find((item: any) => item.name === pickUpcountry)?.slug}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPickUpCityList = () => {
    const { pickUpcountry, countryList, pickUpStateList, pickUpstate } = this.state;

    const countrySlug = countryList.find((item: any) =>
      item.name === pickUpcountry)?.slug;
    const stateSlug = pickUpStateList.find((item: any) =>
      item.name === pickUpstate)?.slug;

    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPickUpCityListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.cityList}?country_code=${countrySlug}&state_code=${stateSlug}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(
      MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDropOffStateList = () => {
    const { dropOffcountry, countryList } = this.state;
    const header = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDropOffStateListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.stateList}?country_code=${countryList.find((item: any) => item.name === dropOffcountry)?.slug}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDropOffCityList = () => {
    const { dropOffcountry, countryList, dropOffStateList, dropOffstate } = this.state;
    const countrySlug = countryList.find((item: any) =>
      item.name === dropOffcountry)?.slug;
    const stateSlug = dropOffStateList.find((item: any) =>
      item.name === dropOffstate)?.slug;

    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDropOffCityListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.cityList}?country_code=${countrySlug}&state_code=${stateSlug}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(
      MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNotificationBoxToggle = () => {
    this.setState((prevState) => ({ isNotificationBoxOpen: !prevState.isNotificationBoxOpen }));
    this.getAllNotification();
    this.markAllNotificationsAsRead();
  };
  markAllNotificationsAsRead = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: await getStorageData('singupLogin')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readAllNotificationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.readAllNotifications}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.receive = this.receive.bind(this);
  }
  getAllNotification = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: await getStorageData('singupLogin')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotificationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllNotifications}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.receive = this.receive.bind(this);
  }
  handleTabChange = (event:React.ChangeEvent<{}>,newValue: number) => {
    if (newValue === 0) {
      return; // As of NOW we are not doing any functionality on the social tab
    }
    this.setState({
      selectedTab: newValue,
    });

  };

  handleAddUPIModal=()=>{
    this.setState({openAddUPIModal:true})
  }
  handleCloseUPIModal=()=>{
    this.setState({openAddUPIModal:false})
  }
  handleupiId=( event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({upiId:event.target.value})
  }
  handleVerifyAndSaveButton=async()=>{
    const upiId = this.state.upiId;
    try {
        const response: VerifyVPA = await verifyUPI(upiId);
       if(response.isQueryPatternValid){
        this.setState({upiError:""})
        this.postUPIdetails()
       }else{
        this.setState({upiError:configJSON.pleaseEnterValid})
       }
    } catch (error: any) {
      this.setState({imageError:error.message,errorModal: true})
    }
  }

  postUPIdetails=async()=>{
    
    const sellertoken = await getStorageData("singupLogin");
    const header = { "Content-Type": "application/json", "token": sellertoken };
    const upiId = this.state.sellerBankDetail?.upi_id
    const endPoint =`${configJSON.addUPIEndPOint}${upiId}`

    const addApiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postUPIcallId = addApiRequestMessage.messageId;
    addApiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    addApiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    addApiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    runEngine.sendMessage(addApiRequestMessage.id, addApiRequestMessage);
  }

  handlePostUPIcallId=(responseJson:unknown)=>{
    this.setState({openAddUPIModal:false})
    this.getSellerProfileData()
  }
  handleCopyUPI = () => {
    let currentNode = this.upiIdText.current;
    if(currentNode) {
      navigator.clipboard.writeText(currentNode.innerHTML || '');
    }
  }
  handleClickDocument = (url:any) => {
    window.open(url, '_blank');
  };
  
  handleCopyURL = (value :string) => {
      navigator.clipboard.writeText(value);
      this.setState({tooltipCopyUrl:true})
      setTimeout(() => {
        this.setState({tooltipCopyUrl:false})
      }, 2000);
  }
  
  dataURLtoBlob=(dataURI:string)=> {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

  handleProfileQRGenerationMOdal = (): void => {
   this.setState({ openprofileQRgenerationModal: true },
    ()=>{
      setTimeout(()=>{
        htmlToImage.toPng(this.qrRef.current as HTMLElement)
        .then((dataUrl: string) => {
           this.postQrcodeInfo(dataUrl)
        })
        .catch((error: unknown) => {
        });
      },500)
    }
   );
    
  }

  postQrcodeInfo=async(dataUrl:string)=>{
    const sellertoken = await getStorageData("singupLogin");
    const header = {"token": sellertoken };
    const blob = this.dataURLtoBlob(dataUrl);
    const formData = new FormData();
    formData.append('qrcode', blob, 'image.png');

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.postProfileQrId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.profileQrEndpoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formData);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
  runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  handleCloseProfileQRgenerationModal=()=>{
    this.setState({openprofileQRgenerationModal:false})
  }
  handledownloadButton=()=>{
    htmlToImage.toPng(this.qrRef.current)
      .then((dataUrl: string | File | Blob | Uint8Array) => {
        download(dataUrl, 'flixoo-qr-code.png');
      })
      .catch((error:unknown) => {
        this.setState({
          imageError: configJSON.someThingWentWrong,
          errorModal: true
        })
      });

  }

  viewStory = async () => {
    this.viewStoryCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.viewStoryEndPoint+`user_id=${this.state.sellerProfileData.id}`,
        type: ""
      }
    );
  };

  handleClickOpen = (index: number) => {
        this.viewStory();
    this.setState({ instaModalIndex: index, currentSlide: 0, currentStoryIndex: index })
  };

  handleClose = () => {
    this.setState({ instaModal: false })
  };

  handleNext = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (this.state.currentStoryIndex + 1 < this.state.userStory.length && !direct && !isNaN(userIndex)) {
      this.changeStory("next");
    } 
  };

  setSwiperRef = (swiper: SwiperCore) => {
    this.swiperInstance = swiper;
  };

  handlePrev = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (this.state.currentStoryIndex - 1 >= 0 && !direct && !isNaN(userIndex)) {
      this.changeStory("prev");
    }
  };

  fetchAllUserStories = async () => {
    this.allUserStoriesCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.viewStoryEndPoint+`user_id=${this.state.sellerProfileData.id}`,
      type: ""
    });
  };

  allUserStoriesSuccessCallBack = (response: AllStoryResponse) => {
    if (response.data) {
      this.setState({
        allStoryHeader: [response.data[0]],
        userStory: response.data
      })
    }
  };

  viewStorySuccessCallBack = (response: AllStoryResponse) => {
    let { goToSlide } = this.state;
    this.setState({ instaModal: true, userStory : response.data }, () => {
      if (goToSlide === "next") {
        this.swiperInstance?.slideNext();
      } else if (goToSlide === "prev") {
        this.swiperInstance?.slidePrev();
      }
      this.setState({ goToSlide: "" });
    });
  };

  calculateHours = (createdAt: string) => {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  toggleMute = () => {
    this.setState({ storyMuted: !this.state.storyMuted });
  };

  likeStory = async (storyDetails: StoryAttributes) => {
    this.likeStoryCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postLikeUnlikeEndPoint,
      body: {
        "data": {
          "attributes": {
            "likeable_id": storyDetails.id,
            "likeable_type": "BxBlockPosts::Story"
          }
        }
      },
      type: ""
    })
  };

  likeStorySuccessCallBack = (response: LikeResponse) => {
    let { userStory } = this.state;
    const oldStory = userStory;
    if (response.data) {
      oldStory.forEach((item, index) => {
        if (item.attributes.id === response.data.attributes.likeable_id) {
          userStory[index].attributes.liked = !item.attributes.liked;
        }
      })
    };
    if (response.message) {
      userStory[this.state.currentStoryIndex].attributes.liked = !userStory[this.state.currentStoryIndex].attributes.liked;
    }
    this.setState({ userStory });
  };

  showNextButton = (userIndex: number) => {
    const { allStoryHeader, userStory, currentStoryIndex } = this.state;
    if (userIndex + 1 < allStoryHeader.length || (userIndex + 1 === allStoryHeader.length && currentStoryIndex + 1 < userStory.length)) {
      return true;
    }
    return false
  };

  showPrevButton = (userIndex: number) => {
    const { currentStoryIndex } = this.state;
    if (userIndex > 0 || (userIndex === 0 && currentStoryIndex !== 0)) {
      return true;
    }
    return false
  };

  changeStory = (direction: "next" | "prev") => {
    let { currentStoryIndex, userStory } = this.state;
    if (userStory.length === 0) return;
    let newIndex = currentStoryIndex;
    if (direction === 'next') {
      newIndex = currentStoryIndex + 1;
    } else if (direction === 'prev') {
      newIndex = currentStoryIndex - 1;
    }
    if (newIndex === userStory.length || newIndex < 0) {
      this.handleClose();
      newIndex = 0;
    }

    this.setState({ currentStoryIndex: newIndex });
  };

  apiCall = async (apiReqData: ApiPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    let token = await getStorageData("singupLogin");
    const header = {
        "Content-Type": contentType,
        token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    body && type != 'formData' ?
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};

handleOtherApis = (message: Message) => {
  const apiRequestId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  let responseJSON = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  )
  if (apiRequestId === this.allUserStoriesCallId) {
    this.allUserStoriesSuccessCallBack(responseJSON)
  };

  if (apiRequestId === this.likeStoryCallId) {
    this.likeStorySuccessCallBack(responseJSON)
  };

  if (apiRequestId === this.viewStoryCallId) {
    this.viewStorySuccessCallBack(responseJSON)
  };
};

createStoryNav = () => {
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "CreateStory" );
  msg.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );
  this.send(msg);
};
getSellerpostData = async () => {
  const sellertoken = await getStorageData("singupLogin");
  if (sellertoken) {
    const header = {
      "token": sellertoken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sellerpostsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellerpostapiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
handleToggleComment = (postId: string, commentbox: string) => {
  this.getPostCommentApiCall(postId);
  this.setState({ setPostCommentId: postId, showCommentBox: commentbox });
};
getPostCommentApiCall = async (postId:string) => {
  this.getPostCommentApiCallID = await this.apiCall({
      endPoint: `${configJSON.getPostCommentListEndPoint}?post_id=${postId}`,
      method: configJSON.validationApiMethodType,
      type: '',
      contentType: configJSON.validationApiContentType,
  })
};
handlePostLikeUnlike = (postId: string) => {
  const updatedPostData = [...this.state.sellerPostsData];
  const postIndex = updatedPostData.findIndex((post) => post.id === postId);

  if (postIndex !== -1) {
    const isLiked = updatedPostData[postIndex].attributes.is_liked;
    const likeCnt = updatedPostData[postIndex].attributes.like_count;
    if (isLiked === true) {
      updatedPostData[postIndex].attributes.is_liked = false;
      if (likeCnt > 0) {
        updatedPostData[postIndex].attributes.like_count = likeCnt - 1;
      }
      this.setState({ sellerPostsData: updatedPostData }, () => {
        this.postApiCallFn(postId);
      });
    } else {
      updatedPostData[postIndex].attributes.is_liked = true;
      updatedPostData[postIndex].attributes.like_count = likeCnt + 1;
      this.setState({ sellerPostsData: updatedPostData }, () => {
        this.postApiCallFn(postId);
      });
    }
  }
};
postApiCallFn = async (postId: string) => {
  this.postToggleLikeUnlikeApiCallID = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.postLikeUnlikeEndPoint,
      body: {
          "data": {
              "attributes": {
                  "likeable_type": "BxBlockPosts::Post",
                  "likeable_id": Number(postId),
              }
          }
      },
      type: ''
  })
};

likeCommentApiCall = async (id:string) => {
  this.likeCommentApiCallID = await this.apiCall({
      endPoint: configJSON.likeCommentEndPoint,
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      body: {
            "data": {
              "attributes": {
                  "likeable_type": "BxBlockComments::Comment",
                "likeable_id": id, 
              }
            }
          },
      type: ''
  })
};
clickOnReplyForGetId = (idReply:string) => {
  if (this.state.setReplyId === idReply) {
    this.setState({ setReplyId: "" });
  } else {
    this.setState({ setReplyId: idReply });
  }
}
viewDetilsExpandable = (viewDetilsExpandableId: number) => {
  const viewDetailsExpandSet = new Set(this.state.clickReply);
  if (!viewDetailsExpandSet.has(viewDetilsExpandableId)) {
    viewDetailsExpandSet.add(viewDetilsExpandableId);
  } else {
    viewDetailsExpandSet.delete(viewDetilsExpandableId);
  }
  this.setState({ clickReply: Array.from(viewDetailsExpandSet) });
}
likeReplyCommentApiCall = async (id:string) => {
  this.likeReplyCommentApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.likeCommentEndPoint,
      method: configJSON.exampleAPiMethod,
      body: {
            "data": {
              "attributes": {
                  "likeable_type": "BxBlockComments::SubPostComment",
                "likeable_id": id, //enter comment id
              }
            }
          },
      type: ''
  })
};
getListingDataSuccessCallback = (responseJson: CommentsResponse) => {
  if (responseJson && responseJson.data) {
    this.setState({
      listCommentData: responseJson,
      addComment: "",
      suggestionsTagList: [],
    });
  }
  if (responseJson.message === "No Comments Found") {
    this.setState({
      listCommentData: {
        message: "",
        data: [],
        meta: responseJson.meta,
      },
      addComment: "",
      suggestionsTagList: [],
    });
  }
};
handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState({ addComment: value });

  const lastAtIndex = value.lastIndexOf('@');
  if (lastAtIndex !== -1) {
    const searchQuery = value.slice(lastAtIndex + 1).trim();

    if (searchQuery.length > 0) {
      this.getTagUserListData(searchQuery);

      // Calculate available space and set suggestion position
      const inputElement = event.target;
      const inputRect = inputElement.getBoundingClientRect();
      const spaceBelow = window.innerHeight - inputRect.bottom;
      const spaceAbove = inputRect.top;

      this.setState({
        isSuggestionVisible: true,
        suggestionPosition: spaceBelow < 200 && spaceAbove > 200 ? 'above' : 'below',
      });
    } else {
      this.setState({ isSuggestionVisible: true, suggestionPosition: 'below' });
    }
  } else if (!value.includes('@')) {
    this.setState({ isSuggestionVisible: false });
  }
};
handleTagSelection = (tag: string) => {
  const { addComment } = this.state;
  const updatedComment = addComment.replace(/@\w*$/, `@${tag} `);

  this.setState({
    addComment: updatedComment,
    isSuggestionVisible: false,
    suggestionsTagList: [],
  });
};
getTagUserListData = async (searchParam: string) => {
  this.getTagUserListApiCallId = await this.apiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.searchTagUserApiEndPoint + `?role=all&search=${searchParam}` + "&page=1&per_page=5",
    body: '',
    type: ''
  });
};
handleKeyDown = (event: React.KeyboardEvent) => {
  if (this.state.addComment.length === 0) return;
  if (event.key === "Enter") {
    event.preventDefault();
    this.state.setReplyId === ""
      ? this.postCommentApiCall()
      : this.postCommentOnCommentApiCall();
  }
};
postCommentApiCall = async () => {
  this.postCommentApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.postCommentEndPoint,
      method: configJSON.exampleAPiMethod,
      body: {
          "comment": {
              "comment": this.state.addComment,
              "post_id": this.state.setPostCommentId
          }
      },
      type: ''
  })
};
postCommentOnCommentApiCall = async () => {
  this.postCommentOnCommentApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postCommentOnCommentEndPoint,
      body: {
          "comment": {
              "comment": this.state.addComment,
              "comment_id": this.state.setReplyId,
              "post_id": this.state.setPostCommentId,
          }
      },
      type: ''
  })
};

postCommentOnCommentDataSuccessCallback = (responseJson:SubPostCommentResponse) => {
  if (responseJson && responseJson.data) {
    this.getPostCommentApiCall(this.state.setPostCommentId);
    this.getSellerPostListingData();
    this.setState({ addComment: "", setReplyId: "" });
  }
}
getSellerPostListingData = async (isFromBottomPagination = false) => {

  this.getSellerPostDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getPostListingDataEndPoint + `?page=${this.state.feedPageNo}&per_page=5`,
      body: '',
      type: ''
  });
};
getAddDataSuccessCallback = (responseJson: CommentsResponse) => {
  if (responseJson && responseJson.data) {
    this.getPostCommentApiCall(this.state.setPostCommentId);
    this.getSellerPostListingData();
    this.setState({ addComment: "" });
  }
};

getSellerFollowers = async () => {

  this.getSellerFollowersApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.sellerFollowers,
      body: '',
      type: ''
  });
};
removeSellerFollowers = async (id:number) => {

  this.removeSellerFollowersApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.delete,
      endPoint: `${configJSON.followSellar}/${id}/remove_follower`,
      body: '',
      type: ''
  });
};
handleCommentBoxClose = ()=>{
  this.setState({showCommentBox: ''})
}

likeReplyCommentCall = (responseJson:LikeResponse) => {
  if (responseJson) {
      this.getPostCommentApiCall(this.state.setPostCommentId);
  }
}

likeCommentCall = (responseJson:LikeResponse) => {
  if (responseJson) {
      this.getPostCommentApiCall(this.state.setPostCommentId);
  }
}
topProductClick = (id:number)=>{
   window.location.href = `/ProductDetails/${id}`
}
openLiveModal= ()=>{
  this.setState({openLiveModal:true})
}
closeLiveModal= ()=>{
  this.setState({openLiveModal:false})
}

  getUserGpsLatitude = () => {
    getLocation(this);
  };


  handleChangeLat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let numberRegex = configJSON.validationNumberRegex;
    if (numberRegex.test(value) || value === "") {
      this.setState({ latitude: value });
    }
  };

  handleChangeLong = (event: React.ChangeEvent<HTMLInputElement>) => {
    const Longvalue = event.target.value;
    let regex = configJSON.validationNumberRegex;
    if (regex.test(Longvalue) || Longvalue === "") {
      this.setState({ longitude: Longvalue });
    }
  };

  checkLatLongValuesSubmit = () => {
    this.updateStoreLocation();
  };


  getAllStore = async () => {
    this.allStoreApiID = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAllStoreEndPoint
    });
  };

  updateStoreLocation = async () => {
    const { storeAddress } = this.state;
    if (storeAddress) {
      let body = {
        address: {
          address_line_1: storeAddress[0].address_line_1,
          address_line_2: storeAddress[0].address_line_2,
          pin_code: storeAddress[0].pin_code,
          city: storeAddress[0].city,
          state: storeAddress[0].state,
          country: storeAddress[0].country,
          latitude: Number(this.state.latitude) || "",
          longitude: Number(this.state.longitude) || "",
        }
      }
      if (this.state.storeAddress.length > 0) {
        this.addStoreAddressApiID = await this.handleApi({
          method: configJSON.putApiMothod,
          endpoint: configJSON.getAllStoreEndPoint + `/${this.state.storeAddress[0].id}`,
          body
        });
      } else {
        this.setState({
          snackBarStates: {
              isOpen: true,
              isSeverity: 'info',
              isMessage: "Store address is required !"
          }
      });
      } 
    }
  };

  getAllStoreSuccessCall = (responseJSON: any) => {
    if (responseJSON) {
      this.setState({
        latitude: responseJSON?.store_addresses[0]?.latitude.toString() || '',
        longitude: responseJSON?.store_addresses[0]?.longitude.toString() || '',
        storeAddress: responseJSON?.store_addresses,
      })
    }
  };

  getUpdateStoreSuccessCall = (response: { meta?: { message: string }, message?: string }) => {
    if (response && response.meta) {
      let message = 'Longitude & Latitude have been updated successfully.'
      this.setState({
        snackBarStates: {
          isOpen: true,
          isSeverity: 'success',
          isMessage: message
        }
      });
    }else {
      this.setState({
        snackBarStates: {
            isOpen: true,
            isSeverity: 'error',
            isMessage: "Something went wrong please try again later."
        }
    });
    }
  };

  handleApi = async (data: { method: string; endpoint: string; body?: {} }) => {
    const { method, endpoint, body } = data;
    const token = await getStorageData("singupLogin");;
    const header = {
      "Content-Type": "application/json",
      token
    };
    const apiReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiReqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    apiReqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiReqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && apiReqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(apiReqMessage.id, apiReqMessage);
    return apiReqMessage.messageId
  };

  handleCloseSnack = () => {
    this.setState({
      snackBarStates: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    });
  };
  // Customizable Area End
}